import { grey, green } from '@material-ui/core/colors';

const styles = (theme) => ({
  searchContainer: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(62),
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  isVaccinatedTableRow: {
    backgroundColor: green[100],
    '&:nth-of-type(odd)': {
      backgroundColor: green[50],
    },
  },
  smallCell: {
    width: 80,
  },
  isVaccinated: {
    color: theme.palette.success.main,
  },
  noStart: {
    color: grey[500],
  },
});

export default styles;
