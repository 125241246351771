import React, { useState, useEffect } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  GetApp as DownloadIcon,
} from '@material-ui/icons';

import PatientsUploadTable from './components/PatientsUploadTable';

import { capitalise } from '../../../../../utils/stringUtils';

import styles from './styles';
import { useCallback } from 'react';

const validGenders = ['male', 'female', 'other', 'prefernottosay'];
const validGendersPropper = ['Male', 'Female', 'Other', 'PreferNotToSay'];

const PatientsCsvUploadDialog = ({ classes, session, handleClose, handleUpload }) => {
  const [errors, setErrors] = useState({});
  const [tableErrors, setTableErrors] = useState(false);
  const [step, setStep] = useState(0);
  const [dateFormat, setDateFormat] = useState('');
  const [importedCsv, setImportedCsv] = useState([]);

  const steps = ['Date format', 'Upload CSV'];

  const m = gql`
    mutation uploadPatients($patients: [patients_insert_input!]!) {
      insert_patients(objects: $patients) {
        returning {
          id
        }
      }
    }
  `;

  const [uploadPatients, { data, loading }] = useMutation(m);

  const m2 = gql`
    mutation createPatientSessionAssignment($assignments: [session_patient_assignment_insert_input!]!) {
      insert_session_patient_assignment(objects: $assignments) {
        returning {
          id
        }
      }
    }
  `;

  const [createPatientSessionAssignment, { data: sessionAssignmentData, loading: sessionAssignmentLoading }] = useMutation(m2);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      createPatientSessionAssignment({
        variables: {
          assignments: data.insert_patients.returning.map(i => ({
            patient_id: i.id,
            session_id: session.id,
            client_id: session.client_id,
            clinic_id: session.clinic_id,
            name: session.name,
          })),
        },
      });
    }
    return () => { mounted = false; };
  }, [data, session, createPatientSessionAssignment]);

  useEffect(() => {
    let mounted = true;
    if (mounted && sessionAssignmentData) {
      handleUpload();
    }
    return () => { mounted = false; };
  }, [sessionAssignmentData,  handleUpload]);



  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };


  const handleBackStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleFileChange = (event) => {
    const { files } = event.target;
    setErrors({});
    const optionalFields = ['address_line_2'];
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (r) => {
        const data = r.target.result;
        try {
          const lines = data.split('\n');
          if (lines.length <= 1) {
            setErrors((e) => ({ ...e, csv: 'No patients found' }));
          } else {
            const headers = lines[0].replace(/ /g, '').replace(/\r/g, '').split(',').filter(i => i);
            const rows = lines.slice(1).filter(i => i);
            const patients = rows.map((o, pIndex) => {
              const row = o.replace(/\r/g, '').split(',');
              const subject = {};
              row.forEach((value, index) => {
                const header = headers[index]
                if ((value && header)) {
                  if (header === 'dob') {
                    if (dateFormat === 'DD/MM/YYYY') {
                      const d = value.split('/');
                      const df = `${d[2]}/${d[1]}/${d[0]}`;
                      subject[header] = df;
                    }
                    if (dateFormat === 'MM/DD/YYYY') {
                      const d = value.split('/');
                      const df = `${d[2]}/${d[0]}/${d[1]}`;
                      subject[header] = df;
                    }
                    if (dateFormat === 'YYYY/MM/DD') {
                      const d = value.split('/');
                      const df = `${d[0]}/${d[1]}/${d[2]}`;
                      subject[header] = df;
                    }
                  } else {
                    subject[header] = value;
                  }
                } else if (!optionalFields.includes(header)) {
                  const error = `Column: ${header}, Row: ${pIndex + 1}, is missing`;
                  setErrors((err) => ({ ...err, table: err.table ? [...err.table, error] : [error] }));
                }
              });
              return subject;
            });
            setImportedCsv(patients.map(i => ({
              ...i,
              first_name: i.first_name ? capitalise(i.first_name) : null,
              last_name: i.last_name ? capitalise(i.last_name) : null,
              email: i.email ? i.email.toLowerCase() : null,
              address_line_1: i.address_line_1 ? capitalise(i.address_line_1) : null,
              address_line_2: i.address_line_2 ? capitalise(i.address_line_2) : null,
              address_city: i.address_city ? capitalise(i.address_city) : null,
              address_postcode: i.address_postcode ? i.address_postcode.toUpperCase() : null,
              dob: i.dob ? i.dob : null,
              gender: i.gender ? `${i.gender.replaceAll(' ', '')}` : 'PreferNotToSay',
            })));
          }
        } catch(e) {
          console.error(e);
          setErrors((err) => ({ ...err, csv: 'Invalid csv file' }));
        }
      };
      reader.readAsText(file);
    }
  };

  const getCsvDate = useCallback(() => {
    if (dateFormat === 'DD/MM/YYYY') {
      return '21/04/1970';
    }
    if (dateFormat === 'MM/DD/YYYY') {
      return '04/21/1970';
    }
    return '1970/04/21';
  }, [dateFormat]);

  const csvHeaders = ['first_name', 'last_name', 'gender', 'dob', 'email', 'address_line_1', 'address_line_2', 'address_city', 'address_postcode'];
  const createCsvDemoLines = () => [
    ['EXAMPLE Name', 'Last', 'Male', dateFormat, 'all.lower.case@email.com', '1 Road', 'Optional', 'City', 'AB1 C23'],
    ['EXAMPLE Name', 'Last', 'Female', dateFormat, 'all.lower.case@email.com', '1 Road', 'Optional', 'City', 'AB1 C23'],
    ['EXAMPLE Name', 'Last', 'PreferNotToSay', getCsvDate(), 'all.lower.case@email.com', '1 Road', 'Optional', 'City', 'AB1 C23'],
    ['EXAMPLE Name', 'Last', 'Other', getCsvDate(), 'all.lower.case@email.com', '1 Road', 'Optional', 'City', 'AB1 C23']
  ];
  const fileName = 'NeedleSmart-Patients-Upload-CSV-Template.csv';

  const handleDownloadTemplate = () => {
    const element = document.createElement('a');
    const csvDemoLines = createCsvDemoLines();
    const file = new Blob([csvHeaders.join(), '\n', csvDemoLines.map(i => i.join()).join('\n')], { type: 'text/csv;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  };

  const handleUploadPatients = () => {
    uploadPatients({
      variables: {
        patients: importedCsv,
      },
    });
  };

  const handleBackToUploadCsv = () => {
    setTableErrors(false);
    setImportedCsv([]);
  }

  const handleDateFormat = (event) => {
    console.log(event.target.value);
    setDateFormat(event.target.value);
  }

  const getStepContent = () => {
    switch (step) {
      case 0:
        return (
          <div className={classes.stepperContent}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="date-formatselect-label">Select date format for upload</InputLabel>
              <Select
                labelId="date-formatselect-label"
                id="date-formatselect"
                value={dateFormat}
                onChange={handleDateFormat} >
                <MenuItem value={"DD/MM/YYYY"}>UK (dd/MM/yyyy)</MenuItem>
                <MenuItem value={"MM/DD/YYYY"}>US (MM/dd/yyyy)</MenuItem>
                <MenuItem value={"YYYY/MM/DD"}>Standard (yyyy/MM/dd)</MenuItem>
              </Select>
            </FormControl>
          </div>
      );
      case 1:
        return (
          <div className={classes.stepperContent}>
            <input type="file" name="csvFile" accept=".csv" onChange={handleFileChange} />
            {errors.csv ? <Typography className={classes.error}>{errors.csv}</Typography> : null}
          </div>
        );
      default:
        return 'Unknown step';
    }
  }

  return (
    <Dialog open onClose={handleClose} disableBackdropClick maxWidth={importedCsv.length > 0 ? 'xl' : 'xs'} fullWidth>
      <DialogTitle>Upload patients CSV</DialogTitle>
      <DialogContent>
        {importedCsv.length === 0 && (
          <>
            <Stepper activeStep={step} alternativeLabel>
              {steps.map((label) => (
                <Step key={label} al>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              <Typography className={classes.instructions}>{getStepContent(step)}</Typography>
              <div className={classes.buttons}>
                {step === 1 && <Button variant="contained" color="primary" onClick={handleBackStep}>Back</Button>}
                {step === 0 && <Button variant="contained" color="primary" disabled={dateFormat === ''} onClick={handleNextStep}>Next</Button>}
              </div>
            </div>
          </>
        )}
        {importedCsv.length > 0 && (
          <>
            <PatientsUploadTable data={importedCsv} setTableErrors={setTableErrors} />
          </>
        )}
        {tableErrors && (
          <div className={classes.tableError}>
            <Typography>{`Genders must conform to the ${validGenders.length} case sensitive allowed values: ${validGendersPropper.join(', ')}`}</Typography>
            <Typography>Email addresses must be valid</Typography>
            <Typography>Dates must be in the {dateFormat} format</Typography>
            {errors.table && (
              errors.table.map(i => <Typography className={classes.error}>{i}</Typography>)
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {(loading || sessionAssignmentLoading) && (
          <CircularProgress />
        )}
        {!loading && !sessionAssignmentLoading && (
          <>
            {importedCsv.length === 0 && (
              <Button className={classes.button} disabled={dateFormat === ''} onClick={handleDownloadTemplate} startIcon={<DownloadIcon />}>Download CSV Template</Button>
            )}
            <Button onClick={handleClose} color="primary">Cancel</Button>
            {importedCsv.length > 0 && !tableErrors && (
              <Button onClick={handleUploadPatients} color="primary">Upload</Button>
            )}
            {tableErrors && (
              <Button onClick={handleBackToUploadCsv} color="primary">Try again</Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PatientsCsvUploadDialog);
