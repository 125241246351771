import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import ButtonContainer from '../../../../components/ButtonContainer';
import { validateEmailAddress, validatePassword } from '../../../../utils/validators';

import styles from './styles';

const SignUpName = ({ classes, history }) => {
  const { patient } = history.location.state || {};
  const [firstName, setFirstName] = useState(patient ? patient.firstName : '');
  const [lastName, setLastName] = useState(patient ? patient.lastName : '');
  const [emailAddress, setEmailAddress] = useState(patient ? patient.emailAddress : '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState({});

  const handleSignUp = () => {
    let error = false;
    setErrors({});
    if (!firstName) {
      setErrors((e) => ({ ...e, firstName: 'Please provide a first name' }));
      error = true;
    }
    if (!lastName) {
      setErrors((e) => ({ ...e, lastName: 'Please provide a last name' }));
      error = true;
    }
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!password) {
      setErrors((e) => ({ ...e, password: 'Please provide a password' }));
      error = true;
    } else if (!validatePassword(password)) {
      setErrors((e) => ({ ...e, password: 'Password does not conform to the passowrd criteria' }));
      error = true;
    }
    if (!passwordConfirm) {
      setErrors((e) => ({ ...e, passwordConfirm: 'Please confirm password' }));
      error = true;
    } else if (password !== passwordConfirm) {
      setErrors((e) => ({ ...e, passwordConfirm: 'Passwords must match' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = () => {
    history.push({
      pathname: '/patient/create-patient',
      state: {
        ...history.location.state,
        patient: {
          firstName: `${firstName[0].toUpperCase()}${firstName.slice(1).toLowerCase()}`,
          lastName: `${lastName[0].toUpperCase()}${lastName.slice(1).toLowerCase()}`,
          emailAddress: emailAddress.toLowerCase(),
          password: btoa(password),
        },
      },
    });
  };

  return (
    <PatientLayout toolbarTitle="Sign up" canGoBack>
      <div className={classes.form} autoComplete="off">
        <TextField
          data-qa="first-name-input"
          required
          label="First Name"
          error={errors.firstName}
          helperText={errors.firstName}
          value={firstName}
          onChange={(event) => setFirstName(event.target.value || null)} />
        <TextField
          data-qa="last-name-input"
          required
          label="Last Name"
          error={errors.lastName}
          helperText={errors.lastName}
          value={lastName}
          onChange={(event) => setLastName(event.target.value || null)} />
        <TextField
          data-qa="email-input"
          required
          label="Email Address"
          type="email"
          error={errors.emailAddress}
          helperText={errors.emailAddress}
          value={emailAddress}
          onChange={(event) => setEmailAddress(event.target.value || null)} />
        <Typography style={{ marginBottom: '16px' }}>
          Passwords must be
          at least 8 characters long 
          and contain at least 1 lower case letter, 
          1 upper case letter, 
          and a number
        </Typography>
        <TextField
          required
          label="Password"
          type="password"
          error={errors.password}
          helperText={errors.password}
          value={password}
          onChange={(event) => setPassword(event.target.value || null)}
        />
        <TextField
          required
          label="Confirm Password"
          type="password"
          error={errors.passwordConfirm}
          helperText={errors.passwordConfirm}
          value={passwordConfirm}
          onChange={(event) => setPasswordConfirm(event.target.value || null)}
        />
      </div>

      <ButtonContainer>
        <Button data-qa="confirm-button" variant="contained" color="primary" onClick={handleSignUp}>CONFIRM</Button>
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpName));
