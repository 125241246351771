import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Edit as EditIcon,
} from '@material-ui/icons';

import { AuthContext } from '../../../components/AuthProvider';
import Dashboard from '../../../layouts/Dashboard';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import {
  EditStaffGroupDialog,
} from './components';

import StaffMembersTable from '../Clinic/components/StaffMembers/components/StaffMembersTable';

import styles from './styles';

const Team = ({ classes }) => {
  const { teamUUID } = useParams();
  const { user } = useContext(AuthContext);
  const { client_id: clientUUID, clinic_id: clinicUUID } = user ? user : {};

  const [clinic, setClinic] = useState(null);
  const [team, setTeam] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);
  const [teamStaffMembers, setTeamStaffMembers] = useState([]);
  const [teamStaffAssignments, setTeamStaffAssignments] = useState([]);
  const [isEditStaffGroupOpen, setIsEditStaffGroupOpen] = useState(false);

  const crumbs = [
    { label: clinic ? clinic.name : '', to: '/manager/clinic' },
    { label: 'Teams', to: '/manager/clinic#teams' },
    { label: team ? team.name : '' },
  ];

  const count = { aggregate: { count: types.number }};
  const q = {
    teams_by_pk: params(
      { id: rawString(teamUUID) },
      [{
        id: types.string,
        name: types.string,
        clinic: {
          id: types.string,
          name: types.string,
        },
        team_staff_assignments: {
          id: types.string,
          client_staff: {
            id: types.string,
            full_name: types.string,
            email: types.string,
            staff_type: types.string,
          },
        },
        team_staff_assignments_aggregate: count
      }],
    ),
    client_staff: params(
      {where: {clinic_id: { _eq: rawString(clinicUUID) }}},
      [{
        id: types.string,
        full_name: types.string,
        email: types.string,
        staff_type: types.string,
      }],
    ),
  };

  const { data, refetch } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const tem = data.teams_by_pk;
      if (tem) {
        setTeam(tem);
        setClinic(tem.clinic);
        setTeamStaffAssignments(tem.team_staff_assignments.map(i => ({ id: i.id, staff_id: i.client_staff.id })));
        setTeamStaffMembers(tem.team_staff_assignments.reduce((a, b) => [...a, b.client_staff], []));
        setStaffMembers(data.client_staff);
      }
    }
    return () => { mounted = false; };
  }, [data]);

  const handleStartEditStaffGroup = () => {
    handleOpenEditStaffGroup();
  };

  const handleOpenEditStaffGroup = () => {
    setIsEditStaffGroupOpen(true);
  };

  const handleCloseEditStaffGroup = () => {
    refetch();
    setIsEditStaffGroupOpen(false);
  };

  return (
    <>
      <Dashboard title={`Team - ${team ? team.name : ''}`}>
        <NavigationBreadcrumbs crumbs={crumbs} />
        {team && (
          <div className={classes.table}>
            <div className={classes.tableHeader}>
              <Typography className={classes.tableTitle} variant="h2">Team</Typography>
              <Button data-qa="team-edit-button" variant="outlined" color="primary" startIcon={<EditIcon />} onClick={() => handleStartEditStaffGroup()}>Edit Team</Button>
            </div>
            <StaffMembersTable data={teamStaffMembers} />
          </div>
        )}
      </Dashboard>
      {isEditStaffGroupOpen && <EditStaffGroupDialog staffMembers={staffMembers} teamStaffMembers={teamStaffMembers} teamStaffAssignments={teamStaffAssignments} clientUUID={clientUUID} clinicUUID={clinicUUID} handleClose={handleCloseEditStaffGroup} />}
    </>
  );
};

export default withStyles(styles)(Team);
