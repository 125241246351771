import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  loader: {
    textAlign: 'center',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  outcome: {
    marginRight: theme.spacing(1),
  },
  outcomePass: {
    marginRight: theme.spacing(1),
    borderColor: theme.palette.common.green,
    backgroundColor: theme.palette.common.green,
    color: theme.palette.common.green,
  },
  outcomeFail: {
    marginRight: theme.spacing(1),
    borderColor: theme.palette.common.red,
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.red,
  },
  outcomeLabel: {
    color: theme.palette.common.white,
    minWidth: 24,
    width: 'auto !important',
    borderRadius: 12,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

export default styles;
