import { WithStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, ReactElement } from 'react';
import QRCode from 'qrcode.react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
    code: string;
    name: string;
    handleClose: () => void;
}

const UserIdDialog = ({ classes, code = '', name = '', handleClose }: Props): ReactElement => {
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    setUserId(code);
    setUserName(name);
  }, [code, name]);

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>{userName}</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <QRCode value={userId} size={300} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(UserIdDialog);
