import React, { PropsWithChildren } from 'react';
import { WithStyles } from '@material-ui/core/styles';

import {
  Typography,
  withStyles,
} from '@material-ui/core';
import { EmptyObject } from '../../react-app-env';

import {
  Topbar,
} from './components';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, PropsWithChildren<EmptyObject> {
  title: string;
  toolbarTitle: string;
  canGoBack: boolean;
  noToolbar: boolean;
}

const PatientLayout = ({ classes, title, toolbarTitle, canGoBack, noToolbar, children }: Props) => (
  <>
    {!noToolbar && (
      <Topbar className={classes.topbar} title={toolbarTitle} canGoBack={canGoBack} />
    )}
    <div className={`${classes.container} ${noToolbar ? classes.noToolbar : ''}`}>
      <Typography className={classes.title} variant="h4" style={{ fontWeight: 700 }}>{title}</Typography>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  </>
);

export default withStyles(styles)(PatientLayout);
