import React, { ReactElement } from 'react';
import { Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client/react';
import ApolloClient from '../../utils/apolloClient';

import { createBrowserHistory } from 'history';
import { createMuiTheme, ThemeProvider, WithStyles } from '@material-ui/core/styles';

import {
  withStyles,
} from '@material-ui/core';

import {
  green,
} from '@material-ui/core/colors';

import Routes from '../../Routes';
import AuthProvider from '../AuthProvider';
import StateProvider from '../StateProvider';

import styles from './styles';

const mainTheme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#00A2AD',
    },
    secondary: {
      main: green[500],
    },
  },
  props: {
    MuiAppBar: {
      elevation: 0,
    },
    MuiButton: {
      style: {
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
      },
    },
    MuiTextField: {
      style: {
        marginBottom: '2rem',
      },
    },
  },
});

const browserHistory = createBrowserHistory();

interface Props extends WithStyles<typeof styles> {}

const App = ({ classes }: Props): ReactElement => (
  <AuthProvider>
    <ApolloProvider client={ApolloClient}>
      <ThemeProvider theme={mainTheme}>
          <StateProvider>
            <div className={classes.root}>
              <Router history={browserHistory}>
                <Routes />
              </Router>
            </div>
          </StateProvider>
      </ThemeProvider>  
    </ApolloProvider>
  </AuthProvider>
);

export default withStyles(styles)(App);
