const styles = (theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 400,
    height: 300,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listTitle: {
    textAlign: 'center',
  },
  error: {
    color: theme.palette.error.main,
  },
});

export default styles;
