import { WithStyles } from '@material-ui/core/styles';
import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  Breadcrumbs,
  Link,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
    crumbs: {
        label: string;
        to: string;
    }[];
}

const NavigationBreadcrumbs = ({ classes, crumbs = [] }: Props) => (
  <div className={classes.root}>
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.slice(0, crumbs.length - 1).map((i) => <Link key={`bcLink-${i.label.replace(/ /g, '')}`} color="inherit" to={i.to} component={NavLink}>{i.label}</Link>)}
      <Typography color="primary">{crumbs.slice(crumbs.length - 1)[0].label}</Typography>
    </Breadcrumbs>
  </div>
);

export default withStyles(styles)(NavigationBreadcrumbs);
