import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';

import ButtonContainer from '../../../../../components/ButtonContainer';

import PatientLayout from '../../../../../layouts/Patient';

import styles from './styles';

const PreQualification = ({ history, classes, questions = [], handleComplete }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState(Array(questions.length).fill(null));
  const [answer, setAnswer] = useState(null);

  const handleContinue = () => {
    setError(null);
    if (answers[questionIndex] === null) {
      return setError('Please answer the question');
    }
    if (questionIndex + 1 < questions.length) {
      setAnswer(null);
      return setQuestionIndex((i) => i + 1);
    }
    handleComplete(answers);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setAnswer(value);
    setAnswers((a) => {
      const ans = a;
      ans[questionIndex] = value;
      return ans;
    });
  };

  return (
    <PatientLayout title={`Pre-Qualification ${questionIndex + 1}`}>
      <div className={classes.root}>
        <FormControl className={classes.form} component="fieldset" error={error !== null}>
          <FormLabel className={classes.question} component="legend">{questions[questionIndex].question}</FormLabel>
          <RadioGroup className={classes.answers} name="question" color="primary" value={answer} onChange={handleChange}>
            <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      </div>
      <ButtonContainer split>
        <Button variant="contained" color="primary" onClick={handleContinue}>{questionIndex + 1 === questions.length ? 'Finish' : 'Next'}</Button>
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(PreQualification));
