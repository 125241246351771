import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import TableTitle from '../../../components/TableTitle';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import StaffTable from './components/StaffTable';

import styles from './styles';

const Session = ({ classes }) => {
  const { clientUUID, clinicUUID, sessionUUID } = useParams();
  const [client, setClient] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [session, setSession] = useState(null);
  const [leadMedics, setLeadMedics] = useState([]);
  const [medics, setMedics] = useState([]);

  const crumbs = [
    { label: 'Clients', to: '/admin/clients' },
    { label: client ? client.name : '', to: `/admin/clients/${clientUUID}` },
    { label: clinic ? clinic.name : '', to: `/admin/clients/${clientUUID}/clinic/${clinicUUID}` },
    { label: session ? session.name : '', to: `/admin/clients/${clientUUID}/clinic/${clinicUUID}/sessions/${sessionUUID}` },
  ];

  const q = {
    clients_by_pk: params(
      { id: rawString(clientUUID) },
      {
        id: types.string,
        name: types.string,
      },
    ),
    clinics_by_pk: params(
      { id: rawString(clinicUUID) },
      {
        id: types.string,
        name: types.string,
      },
    ),
    sessions_by_pk: params(
      { id: rawString(sessionUUID) },
      {
        team_id: types.string,
        client_id: types.string,
        clinic_id: types.string,
        treatment_type_id: types.string,
        medication_type_id: types.string,
        name: types.string,
        start_date: types.string,
        end_date: types.string,
      },
    ),
    client_staff: params(
      { where: { clinic_id: { _eq: rawString(clinicUUID) } } },
      [{
        full_name: types.string,
        staff_type: types.string,
        email: types.string,
      }],
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClient(data.clients_by_pk);
      setClinic(data.clinics_by_pk);
      setSession(data.sessions_by_pk);
      setLeadMedics(data.client_staff.filter(i => i.staff_type === 'MANAGER'));
      setMedics(data.client_staff.filter(i => i.staff_type === 'MEDIC'));
    }
    return () => { mounted = false; };
  }, [data]);

  return (
    <>
      <Dashboard title={`Session - ${client ? client.name : ''} - ${clinic ? clinic.name : ''}`}>
        <NavigationBreadcrumbs crumbs={crumbs} />
        {session && (
          <>
            <div className={classes.table}>
              <TableTitle title="Lead Medics" />
              <StaffTable type="managers" data={leadMedics} />
            </div>
            <div className={classes.table}>
              <TableTitle title="Medics" />
              <StaffTable type="medics" data={medics} />
            </div>
          </>
        )}
      </Dashboard>
    </>
  );
};

export default withStyles(styles)(Session);
