import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, useMutation, gql } from '@apollo/client';

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../../components/StateProvider';
import MedicLayout from '../../../../../layouts/Medic';
import ButtonContainer from '../../../components/ButtonContainer';

import styles from './styles';

const PatientPreQualification = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const [questions, setQuestions] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [error, setError] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [finalAnswers, setFinalAnswers] = useState(null);
  const [sessionAssignment, setSessionAssignment] = useState(null);

  const where = {
    session_id: { _eq: rawString(history.location.state.session.id) },
    patient_id: { _eq: rawString(history.location.state.patient.id) },
  };
  const q = {
    session_patient_assignment: params(
      {where},
      [{
        id: types.string,
        client_id: types.string,
        clinic_id: types.string,
        session_id: types.string,
        qualification: types.string,
      }],
    ),
    medication_types: params(
      { where: { id: { _eq: rawString(history.location.state.session.medication_type.id) } } },
      [{
        id: types.string,
        qualification: types.string,
      }],
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  const m = gql`
    mutation updatePatientQualification($id: uuid!, $set: session_patient_assignment_set_input!) {
      update_session_patient_assignment(where: {id: {_eq: $id}}, _set: $set) {
        affected_rows
      }
    }
  `;

  const [updatePatientQualification, { data: mutationData }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const spa = data.session_patient_assignment[0];
      if (spa) {
        setSessionAssignment(spa);
        const medType = data.medication_types[0];
        if (medType) {
          const qstns = medType.qualification || [];
          const anws = Array(qstns.length).fill(null);
          if (spa.qualification) {
            qstns.forEach((question, index) => {
              const { key } = question;
              const ans = spa.qualification[key];
              if (ans !== undefined) {
                anws[index] = ans ? 'YES' : 'NO';
              }
            });
          }
          setAnswers(anws);
          setQuestions(qstns);
        } else {
          history.replace({
            pathname: `/medic/session/${sessionUUID}/patient/vaccine`,
            state: {
              ...history.location.state,
              qualification: null,
            }
          });
        }
      }
    }
    return () => { mounted = false; };
  }, [data, sessionUUID, history]);

  const handleAbort = () => {
    nsProDevice.stop();
    history.replace(`/medic/session/${sessionUUID}`);
  };

  const handleContinue = async () => {
    setError(null);
    if (answers[questionIndex] === null) {
      return setError('Please answer the question');
    }
    if (questionIndex + 1 < questions.length) {
      setAnswer(answers[questionIndex + 1]);
      return setQuestionIndex((i) => i + 1);
    }
    const answrs = {};
    answers.forEach((a, index) => {
      const { key } = questions[index];
      answrs[key] = a === 'YES';
    });
    setFinalAnswers(answrs);
  };

  useEffect(() => {
    if (finalAnswers) {
      updatePatientQualification({
        variables: {
          id: sessionAssignment.id,
          set: {
            qualification: finalAnswers,
          },
        },
      });
    }
  }, [finalAnswers, sessionAssignment, updatePatientQualification]);

  useEffect(() => {
    if (mutationData) {
      history.replace({
        pathname: `/medic/session/${sessionUUID}/patient/vaccine`,
        state: {
          ...history.location.state,
          qualification: finalAnswers,
        }
      });
    }
  }, [mutationData, finalAnswers, sessionUUID, history]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    setAnswer(value);
    setAnswers((a) => {
      const ans = a;
      ans[questionIndex] = value;
      return ans;
    });
  }, [questionIndex]);

  const hasPatientProvidedAnswer = () => sessionAssignment && sessionAssignment.qualification && sessionAssignment.qualification[questions[questionIndex].key] !== undefined;

  return (
    <MedicLayout title={`Pre-Qualification ${questionIndex + 1}`}>
      <div className={classes.root}>
        {questions.length > 0 && (
          <FormControl className={classes.form} component="fieldset" error={error !== null}>
            <FormLabel component="legend">{questions[questionIndex].question}</FormLabel>
            <RadioGroup data-qa="form-control" name="question" color="primary" value={answer} onChange={handleChange}>
              <FormControlLabel value="YES" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="NO" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
            <FormHelperText>{error}</FormHelperText>
            <FormHelperText>{hasPatientProvidedAnswer() ? 'answer provided by the patient' : null}</FormHelperText>
          </FormControl>
        )}
      </div>
      <ButtonContainer split>
        <Button data-qa="abort-button" variant="contained" onClick={handleAbort}>Abort</Button>
        <Button data-qa="next-button" variant="contained" color="primary" onClick={handleContinue}>Next</Button>
      </ButtonContainer>
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(PatientPreQualification));
