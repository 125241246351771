import React from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const ConfirmDeleteStaffMember = ({ handleCloseDelete, handleDelete, deleting = false }) => (
  <Dialog data-qa="confirm-delete-dialog" open onClose={handleCloseDelete} maxWidth="xs" fullWidth>
    <DialogTitle>Delete staff member</DialogTitle>
    <DialogContent>
      <Typography varient="body1">Are you sure you want to permanently delete this user?</Typography>
    </DialogContent>
    <DialogActions>
      {!deleting && (
        <>
          <Button data-qa="cancel-button" onClick={handleCloseDelete} color="primary">Cancel</Button>
          <Button data-qa="delete-button" onClick={handleDelete} color="primary">Delete</Button>
        </>
      )}
      {deleting && (
        <CircularProgress />
      )}
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteStaffMember;
