const styles = (theme) => ({
  imageContainer: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80%',
    '& > svg': {
      height: '100%',
    },
  },
  scannID: {
    opacity: 0,
  },
});

export default styles;
