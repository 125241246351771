import { Snackbar } from '@material-ui/core';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import React, { ReactElement, useState } from 'react';

interface Props {
  message: string;
  open: boolean;
  severity: Color;
  onClose?: () => void;
}

const Alert = ({ message, open = true, severity = 'success', onClose }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(open);

  const handleOnClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleOnClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <MuiAlert onClose={handleOnClose} severity={severity} elevation={6} variant="filled">{message}</MuiAlert>
    </Snackbar>
  );
};

export default Alert;
