import { Variant } from '@material-ui/core/styles/createTypography';
import { PropTypes } from '@material-ui/core/index';

import React, { PropsWithChildren, ReactElement } from 'react';

import {
  Typography, WithStyles,
  withStyles,
} from '@material-ui/core';
import { EmptyObject } from '../../react-app-env';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, PropsWithChildren<EmptyObject> {
  text: string;
  align: PropTypes.Alignment;
  full: boolean;
  varient: Variant;
}

const BodyText = ({ classes, text, varient = 'body1', full = false, align = 'left', children }: Props): ReactElement => (
  <div className={`${classes.root} ${full ? classes.full : ''}`}>
    <Typography variant={varient} align={align}>{text || children}</Typography>
  </div>
);

export default withStyles(styles)(BodyText);
