import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  withStyles,
  Typography,
} from '@material-ui/core';

import {
  ChevronLeft as MoveLeftIcon,
  ChevronRight as MoveRightIcon,
  FirstPage as MoveAllLeftIcon,
  LastPage as MoveAllRightIcon,
} from '@material-ui/icons';

import styles from './styles';

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

const EditStaffGroupDialog = ({ classes, clientUUID, staffMembers, teamStaffMembers, teamStaffAssignments, handleClose }) => {
  const { clinicUUID, teamUUID } = useParams();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(staffMembers.filter((i) => !teamStaffMembers.map((e) => e.id).includes(i.id)));
  const [right, setRight] = useState(teamStaffMembers);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [errors, setErrors] = useState({});
  
  const m = gql`
    mutation editTeam($deleteIds: [uuid!]!, $objects: [team_staff_assignment_insert_input!]!) {
      delete_team_staff_assignment(where: {id: {_in: $deleteIds}}) {
        affected_rows
      }
      insert_team_staff_assignment(objects: $objects) {
        returning {
          id
          team_id
        }
      }
    }
  `;

  const [editTeam, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleClose();
    }
    return () => { mounted = false; };
  }, [data, handleClose]);

  const handleConfirmEdit = () => {
    let error = false;
    clearErrors();
    if (right.length === 0) {
      setErrors((e) => ({ ...e, text: 'Please provide at lease one staff member' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const clearErrors = () => {
    setErrors({});
  };

  const handleContinue = () => {
    const outIds = left.map(i => i.id);
    const inIds = right.map(i => i.id);
    editTeam({
      variables: {
        deleteIds: teamStaffAssignments.filter(i => outIds.includes(i.staff_id)).map(i => i.id),
        objects: staffMembers.filter(i => inIds.includes(i.id) && teamStaffMembers.every(a => a.id !== i.id)).map(i => ({
          team_id: teamUUID,
          client_id: clientUUID,
          clinic_id: clinicUUID,
          client_staff_id: i.id,
          staff_type: i.staff_type,
        })),
      },
    });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    clearErrors();
    setRight((i) => [...i, ...left]);
    setLeft([]);
    setChecked([]);
  };

  const handleCheckedRight = () => {
    clearErrors();
    setRight((i) => [...i, ...leftChecked]);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    clearErrors();
    setLeft((i) => [...i, ...rightChecked]);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    clearErrors();
    setLeft((i) => [...i, ...right]);
    setRight([]);
    setChecked([]);
  };

  const customList = (title, items) => (
    <Paper className={classes.paper} elevation={0}>
      <Typography className={classes.listTitle} variant="h6">{title}</Typography>
      <Divider />
      <List dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-item-${item.id}-label`;
          return (
            <ListItem key={item.id} role="listitem" button onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox data-qa="staff-name" checked={checked.indexOf(item) !== -1} color="primary" tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.full_name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Dialog data-qa="edit-team-dialog" open onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Medics</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
          <Grid item>{customList('Out of team', left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button data-qa="movel-all-right-button" variant="outlined" size="small" color="primary" className={classes.button} onClick={handleAllRight} disabled={left.length === 0} aria-label="move all right"><MoveAllRightIcon /></Button>
              <Button data-qa="move-selected-right-button" variant="outlined" size="small" color="primary" className={classes.button} onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label="move selected right"><MoveRightIcon /></Button>
              <Button data-qa="move-selected-left-button" variant="outlined" size="small" color="primary" className={classes.button} onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label="move selected left"><MoveLeftIcon /></Button>
              <Button data-qa="move-all-left-button" variant="outlined" size="small" color="primary" className={classes.button} onClick={handleAllLeft} disabled={right.length === 0} aria-label="move all left"><MoveAllLeftIcon /></Button>
            </Grid>
          </Grid>
          <Grid item>{customList('In team', right)}</Grid>
        </Grid>
        {errors.text && <Typography className={classes.error} variant="subtitle2">{errors.text}</Typography>}
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button data-qa="circular-progress-button" color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="cancel-button" onClick={handleClose} color="primary">Cancel</Button>
              <Button data-qa="create-button" onClick={handleConfirmEdit} color="primary">Confirm</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(EditStaffGroupDialog);
