import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  topbar: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 'auto',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  container: {
    // marginTop: theme.spacing(8),
    height: `calc(100% - ${theme.spacing(10)}px - 2px)`,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  noToolbar: {
    paddingTop: theme.spacing(2),
    height: `calc(100% - ${theme.spacing(4)}px - 2px)`,
  },
  title: {
    fontSize: '36px',
    fontWeight: 900,
    fontFamily: 'Roboto',
  },
  content: {
    backgroundColor: '#ffffff',
    flex: '1 1 auto',
    overflow: 'auto',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > form': {
      padding: theme.spacing(2),
      paddingTop: 0,
      display: 'flex',
      flexDirection: 'column',
    },
  },
});

export default styles;
