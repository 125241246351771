import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRight: 'none',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
    flexShrink: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  nameText: {
    textAlign: 'left',
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: 'pointer',
    width: '40px',
  },
  logoDivider: {
    marginBottom: theme.spacing(2),
  },
  profileContainer: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderRadius: '0 24px 0 0',
    marginLeft: 0 - theme.spacing(1),
  },
  profile: {
    padding: theme.spacing(3),
    minHeight: 'fit-content',
  },
  avatar: {
    width: '100px',
    height: '100px',
  },
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  list: {
    flex: '1 1 auto',
  },
  listSubheader: {
    color: theme.palette.text.secondary,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      '& $listItemIcon': {
        color: theme.palette.common.white,
        marginLeft: '-4px',
      },
      '& $listItemText': {
        color: theme.palette.common.white,
      },
    },
    '& + &': {
      marginTop: theme.spacing(1),
    },
  },
  activeListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    '& $listItemText': {
      color: theme.palette.common.white,
    },
    '& $listItemIcon': {
      color: theme.palette.common.white,
      marginLeft: '-4px',
    },
  },
  listItemIcon: {
    marginRight: 0,
  },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  listDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  brandImage: {
    width: '100%',
  },
  brandContainer: {
    height: theme.spacing(4),
    overflow: 'hidden',
  },
});

export default styles;
