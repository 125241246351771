import React, { useContext, useEffect, useState } from 'react';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import { AuthContext } from '../../../components/AuthProvider';
import Dashboard from '../../../layouts/Dashboard';
import { DataCard, DataCardContainer } from '../../../components/DataCard';

import styles from './styles';

const Overview = ({ classes }) => {
  const { user } = useContext(AuthContext);
  const { client_id: clientUUID } = user ? user : {};

  const [clinics, setClinics] = useState(null);
  const [sessions, setSessions] = useState(null);

  const count = { aggregate: { count: types.number }};
  const q = {
    clinics_aggregate: params(
      {where: { client_id: { _eq: rawString(clientUUID) } }},
      count,
    ),
    sessions_aggregate: count,
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClinics(data.clinics_aggregate.aggregate.count);
      setSessions(data.sessions_aggregate.aggregate.count);
    }
    return () => { mounted = false; };
  }, [data]);

  return (
    <Dashboard title="Dashboard">
      <DataCardContainer>
        <DataCard value={clinics} label="Clinics" />
        <DataCard value={sessions} label="Sessions" />
      </DataCardContainer>
    </Dashboard>
  );
};

export default withStyles(styles)(Overview);
