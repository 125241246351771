import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import { Button, withStyles } from '@material-ui/core';

import { Add as AddIcon } from '@material-ui/icons';

import Dashboard from '../../../layouts/Dashboard';
import TableTitle from '../../../components/TableTitle';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import {
  AdminsTable,
  ClinicsTable,
  CreateAdminDialog,
  CreateClinicDialog,
} from './components';

import styles from './styles';

const Client = ({ history, classes }) => {
  const { clientUUID } = useParams();

  const [client, setClient] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [clientAdmins, setClientAdmins] = useState([]);

  const crumbs = [
    { label: 'Clients', to: '/admin/clients' },
    { label: client ? client.name : '' },
  ];

  const where = { client_id: { _eq: rawString(clientUUID) } };
  const q = {
    clients_by_pk: params(
      { id: rawString(clientUUID) },
      {
        id: types.string,
        name: types.string,
      }
    ),
    clinics: params(
      {where},
      [{
        id: types.string,
        name: types.string,
        address_line_1: types.string,
        address_line_2: types.string,
        address_city: types.string,
        address_postcode: types.string,
      }],
    ),
    client_admins: params(
      {where},
      [{
        id: types.string,
        first_name: types.string,
        last_name: types.string,
        full_name: types.string,
        email: types.string,
      }],
    ),
  };

  const { data, refetch } = useQuery(gql`${query(q)}`, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClient(data.clients_by_pk || null);
      setClinics(data.clinics);
      setClientAdmins(data.client_admins);
    }
    return () => { mounted = false; };
  }, [data]);

  const [isCreateClinicDialogOpen, setIsCreateClinicDialogOpen] = useState(false);
  const [isCreateClientAdminDialogOpen, setIsCreateClientAdminDialogOpen] = useState(false);

  const handleOpenCreateClientClinic = () => {
    setIsCreateClinicDialogOpen(true);
  };

  const handleCloseCreateClinic = () => {
    setIsCreateClinicDialogOpen(false);
  };

  const handleOpenCreateClientAdmin = () => {
    setIsCreateClientAdminDialogOpen(true);
  };

  const handleCloseCreateClientAdmin = () => {
    setIsCreateClientAdminDialogOpen(false);
  };

  const handleCreateClinic = () => {
    refetch();
    handleCloseCreateClinic();
  };

  const handleCreateClientAdmin = () => {
    refetch();
    handleCloseCreateClientAdmin();
  };

  const handleClinic = (clinicUUID) => {
    history.push(`/admin/clients/${clientUUID}/clinic/${clinicUUID}`);
  };

  return (
    <>
      <Dashboard title={`Client - ${client ? client.name : ''}`}>
        <NavigationBreadcrumbs crumbs={crumbs} />
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="Clinics" />
            <Button data-qa="create-clinic-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateClientClinic}>Create Clinic</Button>
          </div>
          <ClinicsTable data={clinics} handleClinic={handleClinic} />
        </div>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="Client admins" />
            <Button data-qa="create-clientAdmin-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateClientAdmin}>Create Client Admin</Button>
          </div>
          <AdminsTable data={clientAdmins} />
        </div>
      </Dashboard>
      {isCreateClinicDialogOpen && (
        <CreateClinicDialog handleClose={handleCloseCreateClinic} handleCreate={handleCreateClinic} clientUUID={clientUUID} />
      )}
      {isCreateClientAdminDialogOpen && (
        <CreateAdminDialog handleClose={handleCloseCreateClientAdmin} handleCreate={handleCreateClientAdmin} clientUUID={clientUUID} />
      )}
    </>
  );
};

export default withRouter(withStyles(styles)(Client));
