import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  full: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
