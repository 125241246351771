const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    fontWeight: 700,
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    // padding: theme.spacing(3),
    paddingBottom: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    borderColor: '#3f51b5',
    borderWidth: theme.spacing(0.5),
    borderStyle: 'solid',
    borderRadius: theme.spacing(3),
    width: theme.spacing(48),
  },
  content: {
    padding: theme.spacing(3),
  },
});

export default styles;
