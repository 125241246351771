import Amplify, { Auth } from 'aws-amplify';
import React, { Dispatch, ReactElement, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { CognitoUser } from '../../data/mixins/WithCognitoUser';
import { CustomError } from '../../react-app-env';

import { gql } from '@apollo/client';
import gqlClient from '../../utils/apolloClient';

Amplify.configure({
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT,
});

interface AuthContextItems {
  Auth: typeof Auth;
  userId: string | null;
  user: Object | null;
  loggedInUser: CognitoUser | null;
  userRoles: Array<string>;
  signIn: (username: string, password: string, ignoreSet?: boolean) => Promise<CognitoUser | CustomError>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void | CustomError>;
  confirmSignUp: (username: string, code: string) => Promise<true | CustomError>;
  setLoggedInUser: Dispatch<SetStateAction<CognitoUser | null>>;
  resendSignUp: (username: string) => Promise<true | CustomError>;
}

export const AuthContext = React.createContext<AuthContextItems | null>(null);

type Props = { children: ReactNode }

const AuthProvider = ({ children }: Props): ReactElement => {
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<Object | null>(null);
  const [userRoles, setUserRoles] = useState<Array<string>>([]);
  const [loggedInUser, setLoggedInUser] = useState<CognitoUser | null>(null);

  const getUser = useCallback(async () => {
    if (loggedInUser && loggedInUser.attributes && userId && userRoles.length > 0) {
      const role: string = userRoles[0];
      if (role === 'admin') {
        const q1 = gql`
          query ($userId: uuid!) {
            admins(where: { user_id: { _eq: $userId } }) {
              id
              email
              first_name
              last_name
              user_id
            }
          }`
          const res =  await gqlClient.query<{
            admins: [{
              id: string,
              email: string,
              first_name: string,
              last_name: string,
              user_id: string,
            }]
          }>({ query: q1!, variables: { userId } });
          setUser(res.data.admins[0]);
      }
      if (role === 'client') {
        const q1 = gql`
          query ($userId: uuid!) {
            client_admins(where: { user_id: { _eq: $userId } }) {
              id
              client_id
              email
              first_name
              last_name
              user_id
            }
          }`
          const res =  await gqlClient.query<{
            client_admins: [{
              id: string,
              client_id: string,
              email: string,
              first_name: string,
              last_name: string,
              user_id: string,
            }]
          }>({ query: q1!, variables: { userId } });
          setUser(res.data.client_admins[0]);
      }
      if (role === 'manager' || role === 'medic') {
        const q1 = gql`
          query ($userId: uuid!) {
            client_staff(where: { user_id: { _eq: $userId } }) {
              id
              client_id
              clinic_id
              first_name
              last_name
              email
              staff_type
              miin
              user_id
            }
          }`
          const res =  await gqlClient.query<{
            client_staff: [{
              id: string,
              client_id: string,
              clinic_id: string,
              email: string,
              first_name: string,
              last_name: string,
              staff_type: string,
              miin: string,
              user_id: string,
            }]
          }>({ query: q1!, variables: { userId } });
          setUser(res.data.client_staff[0]);
      }
      if (role === 'patient') {
        const q1 = gql`
          query ($userId: uuid!) {
            patients(where: { user_id: { _eq: $userId } }) {
              id
              first_name
              last_name
              gender
              email
              address_line_1
              address_line_2
              address_city
              address_postcode
              confirmed
              dob
              user_id
            }
          }`
        const res =  await gqlClient.query<{
          patients: [{
            id: string,
            first_name: string,
            last_name: string,
            gender: string,
            email: string,
            address_line_1: string,
            address_line_2: string,
            address_city: string,
            address_postcode: string,
            confirmed: string,
            dob: string,
            user_id: string,
          }]
        }>({ query: q1!, variables: { userId } });
        setUser(res.data.patients[0]);
      }
    }
  }, [loggedInUser, userId, userRoles, setUser]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loggedInUser && userId && userRoles.length > 0) {
      getUser();
    }
  return () => { mounted = false; };
  }, [loggedInUser, userId, userRoles, getUser]);

  const fetchRoles = useCallback(async () => {
    const q = gql`
    query ($userId: uuid!) {
      user_roles(where: { user_id: { _eq: $userId } }) {
        role
      }
    }`
    try {
      const res = await gqlClient.query<{
        user_roles: [{
          role: string,
        }]
      }>({ query: q!, variables: { userId } });
      setUserRoles(res.data.user_roles.map((r: any) => r.role));
    } catch(err) {
      if (err.message === 'Authentication hook unauthorized this request') {
        return; // TODO probably want to add something to clear the login data from amplify here
      }
      console.error(err);
    }
  }, [userId, setUserRoles]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loggedInUser && loggedInUser.attributes && userId) {
      fetchRoles();
    } else {
      setUserRoles([]);
    }
    return () => { mounted = false; };
  }, [loggedInUser, userId, fetchRoles]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(async (user) => {
      setUserId(user.attributes.sub);
      setLoggedInUser(user);
      localStorage.setItem('token', user.signInUserSession.accessToken.jwtToken);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const authContext: AuthContextItems = {
    Auth,
    user,
    userId,
    userRoles,
    loggedInUser,
    setLoggedInUser,
    forgotPassword: async (email) => Auth.forgotPassword(email.toLowerCase())
      .catch((error) => ({ error: true, ...error })),
    signIn: async (emailAddress: string, password: string) => {
      const cognitoUser = await Auth.signIn(emailAddress.toLowerCase(), password);
      if (cognitoUser.attributes) {        
        setUserId(cognitoUser.attributes.sub);
        setLoggedInUser(cognitoUser);
        localStorage.setItem('token', cognitoUser.signInUserSession.accessToken.jwtToken);
      }
      return cognitoUser;
    },
    signOut: async () => {
      await Auth.signOut();
      setLoggedInUser(null);
      localStorage.removeItem('token');
    },
    confirmSignUp: async (username, code) => {
      try {
        await Auth.confirmSignUp(username.toLowerCase(), code);
        return true;
      } catch (error) {
        return error;
      }
    },
    resendSignUp: async (username) => {
      try {
        await Auth.resendSignUp(username);
        return true;
      } catch (error) {
        return error
      }
    },
  };
  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};


export default AuthProvider;
