import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import moment from 'moment';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  ButtonGroup,
  InputAdornment,
  Tooltip,
  TextField,
  withStyles,
} from '@material-ui/core';

import {
  ErrorOutline as RevokeIcon,
  FileCopy as CopyIcon,
} from '@material-ui/icons';

import Alert from '../../../components/Alert';
import Dashboard from '../../../layouts/Dashboard';
import { DataCard, DataCardContainer } from '../../../components/DataCard';
import TableTitle from '../../../components/TableTitle';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import StaffMembersTable from '../Clinic/components/StaffMembers/components/StaffMembersTable';

import {
  CloudUpload as UploadIcon,
} from '@material-ui/icons';

import {
  CancelSessionDialog,
  RevokeInviteDialog,
  PatientsTable,
  PatientsCsvUploadDialog,
} from './components';

import styles from './styles';

const Session = ({ classes, history }) => {
  const { clinicUUID, sessionUUID } = useParams();

  const [session, setSession] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [sessionInvite, setSessionInvite] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);
  const [patients, setPatients] = useState([]);
  const [medicationType, setMedicationType] = useState(null);
  const [treatmentType, setTreatmentType] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [view, setView] = useState(history.location.hash ? history.location.hash.slice(1).toUpperCase() : 'TEAM');

  const [isCancelSessionDialogOpen, setIsCancelSessionDialogOpen] = useState(false);
  const [isRevokeInviteDialogOpen, setIsRevokeInviteDialogOpen] = useState(false);
  const [isPatientsUploadDialogOpen, setIsPatientsUploadDialogOpen] = useState(false);

  const crumbs = [
    { label: 'Clinics', to: '/client/clinics' },
    { label: clinic ? clinic.name : '', to: `/client/clinics/${clinicUUID}` },
    { label: session ? session.name : '' },
  ];

  const count = { aggregate: { count: types.number }};
  const q = {
    sessions_by_pk: params(
      { id: rawString(sessionUUID) },
      {
        id: types.string,
        name: types.string,
        active: types.boolean,
        start_date: types.string,
        end_date: types.string,
        client_id: types.string,
        clinic_id: types.string,
        clinic: {
          id: types.string,
          name: types.string,
        },
        treatment_type: {
          id: types.string,
          name: types.string,
        },
        medication_type: {
          id: types.string,
          name: types.string,
        },
        invite: params(
          {where: {active: {_eq: rawString(true)}}},
          {
            id: types.string,
            client_id : types.string,
            clinic_id: types.string,
            session_id: types.string,
            name: types.string,
          },
        ),
        patients: [{
          created_at: types.string,
          patient: {
            full_name: types.string,
            confirmed: types.boolean,
            gender: types.string,
            id: types.string,
            results_aggregate: {
              aggregate: {
                count: types.number,
              },
            },
          },
        }],
        team: {
          id: types.string,
          name: types.string,
          team_staff_assignments_aggregate: count,
          sessions_aggregate: count,
          team_staff_assignments: {
            client_staff: {
              id: types.string,
              full_name: types.string,
              email: types.string,
            },
          },
        },
      },
    ),
  };

  const { data, refetch } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const ses = data.sessions_by_pk;
      if (ses) {
        setSession({
          ...ses,
          start_date: new Date(ses.start_date),
          end_date: new Date(ses.end_date),
        });
        setClinic(ses.clinic);
        setSessionInvite(ses.invite[0] || null);
        setMedicationType(ses.medication_type);
        setTreatmentType(ses.treatment_type);
        setStaffMembers(ses.team.team_staff_assignments.reduce((a, b) => [...a, b.client_staff], []));
        setPatients(ses.patients.map(i => ({
          created_at: new Date(i.created_at),
          ...i.patient,
          is_vaccinated: i.patient.results_aggregate.aggregate.count > 0,
        })));
      }
    }
    return () => { mounted = false; };
  }, [data]);

  const handleOpenCancelSession = () => {
    setIsCancelSessionDialogOpen(true);
  };

  const handleCloseCancelSession = () => {
    setIsCancelSessionDialogOpen(false);
  };

  const handleOpenRevokeInvite = () => {
    setIsRevokeInviteDialogOpen(true);
  };

  const handleCloseRevokeInvite = () => {
    refetch();
    setIsRevokeInviteDialogOpen(false);
  };

  const handleOpenPatientsUpload = () => {
    setIsPatientsUploadDialogOpen(true);
  };

  const handleClosePatientsUpload = () => {
    setIsPatientsUploadDialogOpen(false);
  };

  const todayTimestamp = new Date().setHours(0, 0, 0, 0);

  const handleCopyInvite = async () => {
    const copyText = document.getElementById('invite-link');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setIsCopied(true);
  };

  const getDate = (date) => moment(new Date(date)).format('dddd Do MMMM YYYY');

  const getInviteLink = () => `${window.location.origin}/i/${sessionInvite.id}`;

  const handleViewChange = (viewName) => {
    setView(viewName);
    history.replace(`#${viewName.toLowerCase()}`);
  };

  const handlePatientsUpload = () => {
    refetch();
    handleClosePatientsUpload();
  }

  return (
    <>
      <Dashboard title={`Session - ${session ? session.name : ''}`}>
        <NavigationBreadcrumbs crumbs={crumbs} />
        <DataCardContainer>
          {session && session.active && (
            <DataCard value={session ? getDate(session.start_date) : null} label={session ? `${moment(session.start_date).fromNow()} for ${moment(session.end_date).from(session.start_date, true)}` : null} />
          )}
          {session && !session.active && (
            <DataCard label={session ? getDate(session.start_date) : null} value={'Canceled'} />
          )}
        </DataCardContainer>
        <DataCardContainer>
          <DataCard value={session ? session.name : null} label="Session name" />
          <DataCard value={clinic ? clinic.name : null} label="Clinic" />
          <DataCard value={treatmentType ? treatmentType.name : null} label="Treatment Type" />
          <DataCard value={medicationType ? medicationType.name : null} label="Medication Type" />
        </DataCardContainer>
        <div className={classes.actions}>
          {sessionInvite && session && session.end_date > todayTimestamp && session.active && (
            <ButtonGroup className={classes.buttonGroup} variant="outlined" color="primary">
              <TextField data-qa="revoke-link" className={classes.linkInput} id="invite-link" value={getInviteLink()} InputProps={{ startAdornment: <InputAdornment position="start">Session invite link:</InputAdornment> }} />
              <Tooltip title="Copy invite link"><Button data-qa="copy-button" onClick={handleCopyInvite}><CopyIcon /></Button></Tooltip>
              <Tooltip title="Revoke invite link"><Button data-qa="revoke-button" className={classes.revokeButton} onClick={handleOpenRevokeInvite}><RevokeIcon /></Button></Tooltip>
              {session.start_date > todayTimestamp && (
                <Button data-qa="delete-button" className={classes.deleteButton} onClick={handleOpenCancelSession}>Cancel Session</Button>
              )}
            </ButtonGroup>
          )}
          {session && session.start_date > todayTimestamp && !session.active && (
            <Button variant="outlined" color="primary" data-qa="enable-button" onClick={handleOpenCancelSession}>Start Session</Button>
          )}
        </div>

        {session && (
          <>
            <div className={classes.viewButtons}>
              <ButtonGroup color="primary">
                <Button data-qa="team-button" variant={view === 'TEAM' ? 'contained' : ''} onClick={() => handleViewChange('TEAM')}>Team</Button>
                <Button data-qa="patients-button" variant={view === 'PATIENTS' ? 'contained' : ''} onClick={() => handleViewChange('PATIENTS')}>Registered Patients {patients.length}</Button>
              </ButtonGroup>
            </div>
            {view === 'TEAM' && (
              <div className={classes.table}>
                <TableTitle title={session.team.name} />
                <StaffMembersTable data={staffMembers} />
              </div>
            )}
            {view === 'PATIENTS' && (
              <div className={classes.table}>
                {sessionInvite && session && session.end_date > todayTimestamp && session.active && (
                  <div className={classes.tableHeader}>
                    <TableTitle title="" />
                    <Button data-qa="upload-patients-button" variant="outlined" color="primary" startIcon={<UploadIcon />} onClick={handleOpenPatientsUpload}>Upload patients</Button>
                  </div>
                )}
                <PatientsTable data={patients} />
              </div>
            )}
          </>
        )}
        {isCopied && (
          <Alert data-qa="invite-link-alert" message="Invite link has been copied to the clipboard" />
        )}
      </Dashboard>
      {isCancelSessionDialogOpen && <CancelSessionDialog sessionId={sessionUUID} handleClose={handleCloseCancelSession} isCanceled={!session.active} label={session.active ? 'Cancel' : 'Start'} />}
      {isRevokeInviteDialogOpen && <RevokeInviteDialog sessionInvite={sessionInvite} handleClose={handleCloseRevokeInvite} />}
      {isPatientsUploadDialogOpen && <PatientsCsvUploadDialog session={session} handleClose={handleClosePatientsUpload} handleUpload={handlePatientsUpload} />}
    </>
  );
};

export default withRouter(withStyles(styles)(Session));
