
import { red } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  form: {
    // display: 'grid',
    // gridTemplateColumns: 'repeat(2, minmax(200px, 300px))',
    // gridGap: theme.spacing(2),
    // alignItems: 'flex-start',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  actions: {
    padding: theme.spacing(2),
    alignSelf: 'flex-end',
    '& > button': {
      marginLeft: theme.spacing(2),
    },
  },
  deleteAction: {
    color: red[500],
    borderColor: red[500],
    '&:hover': {
      borderColor: red[700],
      backgroundColor: red[50],
    },
  },
});

export default styles;
