const styles = (theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  button: {
    marginTop: theme.spacing(2),
  },
});

export default styles;
