import { WithStyles } from '@material-ui/core/styles';
import React, { PropsWithChildren, ReactElement } from 'react';

import {
  withStyles,
} from '@material-ui/core';
import { EmptyObject } from '../../react-app-env';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, PropsWithChildren<EmptyObject> {}

const CenteredLayout = ({ classes, children }: Props): ReactElement => (
  <div className={classes.root}>
    <div className={classes.container}>
      {children}
    </div>
  </div>
);

export default withStyles(styles)(CenteredLayout);
