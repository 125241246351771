import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Avatar,
  CircularProgress,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const StaffTable = ({ classes, type, data, history }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [staffData, setStaffData] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setStaffData(data);
    }
    setTimeout(() => {
      if (mounted) {
        setFetchTimeout(true);
      }
    }, 3000);
    return () => { mounted = false; };
  }, [data]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, staffData.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table data-qa="staff-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {staffData.length > 0 && (
              staffData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((member) => (
                <TableRow className={classes.tableRow} key={member.id}>
                  <TableCell component="th" scope="row">
                    <div className={classes.name}>
                      <Avatar className={classes.avatar}>{member.full_name.split(' ').map(i => i[0]).join('')}</Avatar>
                      {member.full_name}
                    </div>
                  </TableCell>
                  <TableCell>{member.email}</TableCell>
                </TableRow>
              ))
            )}
            {staffData.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={3} />
              </TableRow>
            )}
            {staffData.length === 0 && (
              <TableRow>
                <TableCell className={classes.loader} colSpan={3}>
                  {fetchTimeout && ('No Data')}
                  {!fetchTimeout && (<CircularProgress />)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={staffData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage} />
    </Paper>
  );
};

export default withRouter(withStyles(styles)(StaffTable));
