import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Avatar,
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../../components/StateProvider';
import MedicLayout from '../../../../../layouts/Medic';
import ButtonContainer from '../../../components/ButtonContainer';
import LottieAnimation from '../../../../../components/LottieAnimation';
import getDate from '../../../../../utils/conversions';

import styles from './styles';

const PatientConfirmation = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const [photo] = useState('');
  const [patient, setPatient] = useState(null);
  const [alreadyVaccinated, setAlreadyVaccinated] = useState(false);
  const [abortTimer, setAbortTimer] = useState(3);

  const q = {
    patients_by_pk: params(
      { id: rawString(history.location.state.patientUUID) },
      {
        id: types.string,
        full_name: types.string,
        gender: types.string,
        address_line_1: types.string,
        address_postcode: types.string,
        dob: types.string,
        results: {
          id: types.string,
        },
      },
    ),
  };

  const { data, loading } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const pat = data.patients_by_pk;
      if (pat) {
        if (pat.results.length > 0) {
          setAlreadyVaccinated(true);
        } else {
          setPatient({
            ...pat,
            dob: new Date(pat.dob),
          });
        }
      }
    }
    return () => { mounted = false; };
  }, [data]);

  const handleAbort = useCallback(() => {
    nsProDevice.stop();
    history.replace(`/medic/session/${sessionUUID}`);
  }, [history, nsProDevice, sessionUUID]);

  const handleContinue = () => {
    history.replace({
      pathname: `/medic/session/${sessionUUID}/patient/pre-qualification`,
      state: {
        ...history.location.state,
        patient,
      },
    });
  };

  useEffect(() => {
    if (alreadyVaccinated) {
      if (abortTimer > 0) {
        setTimeout(() => {
          setAbortTimer((t) => t - 1);
        }, 1000);
      } else {
        handleAbort();
      }
    }
  }, [abortTimer, alreadyVaccinated, handleAbort]);

  return (
    <MedicLayout title="Patient ID">
      {patient && (
        <div className={classes.root}>
          <div autoComplete="off" className={classes.form}>
            <Avatar src={photo} className={classes.photo} />
            <TextField label="Name" value={patient.full_name} disabled />
            <TextField label="Address" value={patient.address_line_1} disabled />
            <TextField label="Postcode" value={patient.address_postcode} disabled />
            <TextField label="Date of birth" value={getDate(patient.dob)} disabled />
          </div>
        </div>
      )}
      {!patient && !alreadyVaccinated && !loading && (
        <div className={classes.root}>
          <LottieAnimation file="search" />
          <Typography variant="body1">Patient ID not found</Typography>
        </div>
      )}
      {alreadyVaccinated && (
        <div className={classes.imageContainer} role="button" tabIndex="0">
          <div className={classes.image}>
            <LottieAnimation file="error" />
            <Typography variant="body1">Patient has already been vaccinated</Typography>
            <Typography variant="body1">Aborting in {abortTimer} seconds</Typography>
          </div>
        </div>
      )}
      {!alreadyVaccinated && (
        <ButtonContainer split>
          <Button data-qa="abort-button" variant="contained" onClick={handleAbort}>Abort</Button>
          {patient && <Button data-qa="confirm-id-button" variant="contained" color="primary" onClick={handleContinue}>Confirm Identity</Button>}
        </ButtonContainer>
      )}
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(PatientConfirmation));
