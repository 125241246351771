import React, { useContext, useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import { AuthContext } from '../../../../../components/AuthProvider';

import {
  TeamsTable,
  CreateTeamDialog,
} from './components';

import styles from './styles';

const Teams = ({ classes, history }) => {
  const { clinicUUID } = useParams();
  const { user } = useContext(AuthContext);
  const { client_id: clientUUID } = user ? user : {};

  const [teams, setTeams] = useState([]);

  const count = { aggregate: { count: types.number }};
  const where = {clinic_id: { _eq: rawString(clinicUUID) }};
  const q = {
    teams: params(
      {where},
      {
        id: types.string,
        name: types.string,
        team_staff_assignments_aggregate: count,
        sessions_aggregate: count,
      },
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setTeams(data.teams);
    }
    return () => { mounted = false; };
  }, [data]);

  const [isCreateTeamDialogOpen, setIsCreateTeamDialogOpen] = useState(false);
  
  const handleOpenCreateTeam = () => {
    setIsCreateTeamDialogOpen(true);
  };

  const handleCloseCreateTeam = () => {
    setIsCreateTeamDialogOpen(false);
  };

  const handleTeam = (teamUUID) => {
    history.push(`/client/clinics/${clinicUUID}/teams/${teamUUID}`);
  };

  return (
    <>
      <div className={classes.table}>
        <div className={classes.tableHeader}>
          <Typography className={classes.tableTitle} variant="h2">Teams</Typography>
          <Button data-qa="create-team-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateTeam}>Create Team</Button>
        </div>
        <TeamsTable data={teams} handleTeam={handleTeam} />
      </div>
      {isCreateTeamDialogOpen && <CreateTeamDialog clientUUID={clientUUID} clinicUUID={clinicUUID} handleClose={handleCloseCreateTeam} />}
    </>
  );
};

export default withRouter(withStyles(styles)(Teams));
