import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import {
  ControlPoint as AddIcon,
} from '@material-ui/icons';

import MedicationTypeQualificationQuestion from '../MedicationTypeQualificationQuestion';

import styles from './styles';

const MedicationTypeQualification = ({ classes, data = [{ key: uuidv4(), question: '', onInvite: false }], setQualifications }) => {
  const [questions, setQuestions] = useState(data);

  const handleAddQuestion = () => {
    const field = { key: uuidv4(), question: '', onInvite: false };
    const items = [...questions];
    items.push(field);
    checkErrors(items);
    setQuestions(items);
  };

  const handleRemoveQuestion = (questionKey) => {
    const items = [...questions].filter((a) => a.key !== questionKey);
    checkErrors(items);
    setQuestions(items);
  };

  const handleUpdateQuestion = (question) => {
    const items = [...questions];
    items.splice(items.findIndex((a) => a.key === question.key), 1, question);
    setQuestions(items);
    checkErrors(items);
  };

  const checkErrors = (items) => {
    const errors = items.some((i) => !i.question || i.question === '');
    if (errors) {
      setQualifications(null);
    } else {
      setQualifications(items);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.questionsContainer}>
        <div className={classes.dots}><p /></div>
        {questions.map((i, index) => (
          <MedicationTypeQualificationQuestion
            key={i.key}
            questionKey={i.key}
            questionIndex={index}
            questionQuestion={i.question}
            questionOnInvite={i.onInvite}
            handleUpdateQuestion={handleUpdateQuestion}
            handleRemoveQuestion={handleRemoveQuestion} />
        ))}
      </div>
      <div className={classes.addButtonContainer}>
        <Button className={classes.addButton} variant="contained" startIcon={<AddIcon />} onClick={handleAddQuestion}>Add Question</Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(MedicationTypeQualification);
