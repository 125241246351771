import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const EnableDisableMedicationTypeDialog = ({ handleClose, label, isEnabled }) => {
  const { medicationTypeUUID } = useParams();
  const m = gql`
    mutation toggleEnableMedicationType($pk_columns: medication_types_pk_columns_input!, $set: medication_types_set_input!) {
      update_medication_types_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
        enabled
      }
    }
  `;

  const [toggleEnableMedicationType, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleClose();
    }
    return () => { mounted = false; };
  }, [data, handleClose]);

  const handleContinue = () => {
    toggleEnableMedicationType({
      variables: {
        pk_columns: {
          id: medicationTypeUUID,
        },
        set: {
          enabled: !isEnabled,
        },
      },
    });
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{label} medication type</DialogTitle>
      <DialogContent>
        <Typography varient="body1">Are you sure you want to {label.toLowerCase()} this medication type?</Typography>
      </DialogContent>
      <DialogActions>
        {!loading && (
          <>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button onClick={handleContinue} color="primary">{label}</Button>
          </>
        )}
        {loading && (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EnableDisableMedicationTypeDialog;
