import React, { ReactElement } from 'react';

const NsLogo = (): ReactElement => (
  <svg id="NS_LOGO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 860 860">
    <path style={{ fill: '#2BB6C5' }} d="M440.19,0.75c-0.02,31.28-0.06,62.56-0.07,93.83c0,105.5,0.06,211.01-0.12,316.51c-0.01,6.92,1.12,9.39,9.02,9.36c65.57-0.29,131.14-0.35,196.71,0.24c50.73,0.46,101.45,1.92,152.18,3c20.28,0.43,40.56,1.04,60.84,1.57c0,1.33,0,2.66,0,4c-15.96,0.39-31.92,0.77-47.88,1.16c-17.61,0.26-35.21,0.46-52.82,0.81c-7.76,0.15-15.52,0.59-23.28,0.89c-96.51,0.73-193.03,1.5-289.54,2.08c-4.45,0.03-5.11,1.64-5.12,5.47c-0.05,33.12-0.21,66.23-0.45,99.35c-0.11,14.97-0.45,29.94-0.69,44.91c-0.26,0.77-0.73,1.53-0.74,2.3c-0.36,28.59-0.68,57.19-1.01,85.78c-0.82,40.59-1.64,81.18-2.47,121.77c-0.35,17.13-0.62,34.26-1.15,51.39c-0.05,1.56-1.78,3.06-2.73,4.59c-1.07-1.6-3.05-3.19-3.08-4.82c-1.01-47.08-1.91-94.16-2.65-141.24c-1.37-87.01-2.73-174.02-3.72-261.03c-0.08-7.1-1.79-8.54-8.67-8.51c-60.26,0.24-120.53,0.42-180.79-0.12c-50.75-0.45-101.48-1.86-152.22-2.9c-21.46-0.44-42.91-0.95-64.36-1.6c-1.92-0.06-3.8-1.37-5.7-2.1c1.92-0.95,3.82-2.69,5.75-2.74c30.61-0.67,61.23-1.29,91.85-1.59c103.01-1.03,206.03-1.93,309.04-2.89c1.32-0.01,2.65-0.11,4.9-0.21c0-2.64,0-5.22,0-7.8c0-105.03,0-210.07,0-315.1c0-32.12-0.01-64.24-0.02-96.36C427.54,0.75,433.87,0.75,440.19,0.75z" />
    <path style={{ fill: '#B1DCE6' }} d="M19.76,449.18c61.4,0.03,122.79-0.05,184.19,0.13c64.7,0.19,129.39,0.7,194.09,0.84c5.15,0.01,6.32,1.46,6.32,6.43c0,19.27,0.66,38.54,0.79,57.81c0.19,26.89,0.02,53.78,0.21,80.68c0.18,26.26,0.63,52.51,0.95,78.77c0.33,26.76,0.68,53.51,0.99,80.27c0.32,28.25,0.6,56.5,0.89,84.74c-4.3-0.3-8.61-0.54-12.9-0.9c-36.75-3.07-72.35-11.14-106.92-24.01c-29.79-11.08-58.34-24.75-84.32-43.01c-18.27-12.84-35.87-26.81-52.63-41.57c-24.33-21.42-45.66-45.86-62.53-73.56c-13.56-22.27-26.25-45.22-37.25-68.84c-13.55-29.08-22.03-60.05-26.91-91.81C22.4,479.93,21.38,464.51,19.76,449.18z" />
    <path style={{ fill: '#2BB6C5' }} d="M569.21,623.91c-12.28-3.52-24.9-6.14-36.76-10.73c-39.36-15.24-54.32-60.73-31.42-94.36c12.34-18.12,30.74-27.07,51.21-31.99c35.28-8.48,70.55-8.57,105.17,3.27c28.54,9.77,45.12,29.87,49.36,59.98c0.76,5.39-0.06,7.69-6.37,7.56c-19.79-0.39-39.6-0.18-59.4,0c-3.71,0.03-5.04-0.87-5.52-4.85c-0.92-7.68-4.98-13.84-12.29-16.97c-14.86-6.37-30.14-6.66-45.06-0.73c-11.76,4.67-13.78,18.98-2.82,25.18c11.03,6.25,23.62,10.3,35.99,13.53c18.75,4.88,37.91,8.18,56.88,12.24c2.26,0.48,4.39,1.52,6.58,2.31c12.88,6.3,23.33,15.47,31.85,26.92c0,0,0.03,0.04,0.03,0.04c1.33,2.63,2.65,5.26,3.98,7.89c0,0,0.02,0.09,0.03,0.09c0.21,1.14,0.29,2.32,0.65,3.41c7.22,22.37,4.05,43.39-9.02,62.67c-14.78,21.8-37.32,31.24-61.87,36.51c-29.71,6.38-59.44,5.48-88.79-2.37c-19.68-5.26-36.83-14.74-49.36-31.51c-9.38-12.55-13.26-26.92-14.63-42.13c-0.11-1.17,2.69-3.73,4.16-3.74c21.13-0.26,42.26-0.09,63.4-0.29c4.18-0.04,4.91,1.88,5.96,5.39c1.68,5.58,3.47,11.76,7.16,15.99c12.19,13.95,35.22,17.23,55.41,8.59c8.28-3.54,13.81-9.56,14.43-19.16c0.66-10.06-4.82-16.88-13.39-19.35C606.46,632.06,587.75,628.29,569.21,623.91z" />
    <path style={{ fill: '#2BB6C5' }} d="M175.78,213.43c2.24-40.35,26.1-71.37,64.38-81.39c36.5-9.55,72.25-7.14,102.3,19.61c18.1,16.11,28.18,36.89,28.66,61.02c1.03,52.06,0.91,104.14,1.35,156.21c0.03,3.42-0.96,4.96-4.53,4.5c-1.64-0.21-3.36-0.26-4.98,0.02c-4.61,0.77-5.49-1.31-5.47-5.56c0.14-42.11,0.07-84.22,0.08-126.33c0-17.57-2.1-34.81-8.33-51.35c-9.52-25.24-27.02-41.64-53.86-46.54c-24.63-4.5-48.96-3.66-70.56,10.95c-23.32,15.77-32.7,39.95-33.65,66.46c-1.75,48.69-1.53,97.46-1.59,146.2c-0.01,8.74-5.35,5.64-9.46,6.23c-5.03,0.72-5.88-1.6-5.83-6.27c0.26-25.46,0.13-50.93,0.13-76.4C174.78,290.77,174.36,239.13,175.78,213.43z" />
    <path style={{ fill: '#2BB6C5' }} d="M19.76,449.18c1.62,15.33,2.64,30.75,4.97,45.97c4.88,31.76,13.36,62.73,26.91,91.81 c11,23.62,23.69,46.58,37.25,68.84c16.87,27.7,38.2,52.14,62.53,73.56c16.76,14.76,34.36,28.72,52.63,41.57 c25.98,18.26,54.53,31.92,84.32,43.01c34.57,12.86,70.17,20.94,106.92,24.01c4.3,0.36,8.6,0.6,12.9,0.9 c0.19,3.38,0.37,7.62,0.63,12.23c-11.42-0.51-22.19-1.12-32.77-2.45c-49.92-6.27-97.19-21.23-141.72-44.65 c-44.81-23.56-84.14-54.26-118.23-91.68c-0.78-0.86-1.65-1.64-2.47-2.45c-5.88-7.09-11.76-14.18-17.63-21.28 c-4.12-5.56-8.23-11.12-12.35-16.68c-1.21-1.76-2.41-3.52-3.62-5.28c-0.08-0.25-0.22-0.48-0.4-0.68c-1.99-3.01-3.97-6.02-5.96-9.03 c-33.8-53.05-55.4-110.64-63.48-173.06c-1.46-11.3-2-22.99-2.09-34.47C10.78,449.02,19.09,448.91,19.76,449.18z" />
    <path style={{ fill: '#2BB6C5' }} d="M792.73,625.99c25.27-45.58,40.82-94.35,47.79-145.91c1.16-8.55,2.14-17.2,2.15-25.81c0-7.97,5.32-5.38,8.94-5.16c5.44,0.33,4.43,3.61,4,6.36c-4.18,26.54-6.98,53.43-13.24,79.49c-11.68,48.58-32.92,93.24-61.48,134.27c-37.52,53.89-84.86,97.09-142.19,129.2c-49.05,27.47-101.61,44.32-157.39,50.93c-9.01,1.07-15.55,1.73-27,2.13c-3.58-13.76-4.38-12.17,7.25-12.91c60.74-4.53,117.74-21.56,170.95-51.26c42.07-23.48,78.71-53.63,110.34-89.88c7.09-8.12,13.46-16.86,20.16-25.32c1.94-2.7,3.89-5.4,5.83-8.1c0.17-0.23,0.29-0.49,0.36-0.77c1.25-1.71,2.5-3.42,3.75-5.13c0,0,0.02-0.04,0.02-0.04C779.55,647.38,786.14,636.69,792.73,625.99z" />
    <path style={{ fill: '#2BB6C5' }} d="M42.75,288.51c-0.47,1.57-0.94,3.15-1.41,4.72c-5.3,20.31-10.6,40.62-15.91,60.93c0,0-0.01,0.1-0.01,0.1c-1.96,14.03-4.84,28.02-5.52,42.12c-0.39,8.18-4.33,9.23-14.73,7.52c-0.33-31.93,11.54-80.18,25.58-118.77c7.63-20.98,17.96-40.98,27.07-61.42c0.85-1.2,1.82-2.34,2.54-3.61c15.63-27.73,33.61-53.85,55.52-76.97c14.35-15.15,29.78-29.27,44.73-43.84c0.5-0.37,1-0.75,1.5-1.12c7.49-5.76,14.98-11.52,22.48-17.27c0,0,0.07-0.03,0.07-0.03c0.35-0.24,0.7-0.47,1.05-0.7c0,0,0.19-0.23,0.19-0.23c15.85-9.72,31.71-19.44,47.57-29.16c39.1-20.57,80.41-34.67,123.97-42.01c13.08-2.2,26.32-3.45,39.5-5.1c3.02-0.38,4.98-0.39,5.41,4.01c0.3,4.12,2.7,7.95-4.54,8.51c-47.12,3.65-92.37,15.07-135.39,34.67c-56.25,25.63-104.75,61.78-144.92,108.87c-6.45,7.56-12.4,15.55-18.58,23.34c-11.29,17.85-23.59,35.16-33.56,53.72C56.48,253.31,50.18,271.22,42.75,288.51z" />
    <path style={{ fill: '#B1DCE6' }} d="M842.86,405.25c-26.13,0-52.26,0.13-78.4-0.03c-28.44-0.17-56.87-0.66-85.3-0.94c-38.26-0.37-76.51-0.66-114.77-1.02c-33.93-0.31-67.86-0.76-101.79-0.9c-4.51-0.02-5.55-1.36-5.51-5.67c0.22-21.14,0.06-42.28,0.13-63.41c0.3-93.68,0.61-187.35,0.99-281.03c0.05-12.12,0.57-24.24,0.87-36.36c37.06,1.88,72.92,9.61,107.97,21.62c31.77,10.88,61.58,25.8,89.69,43.96c26.99,17.43,52.08,37.43,72.91,62.07c16.22,19.19,32.87,38.22,46.97,58.94c25.08,36.86,42.59,77.51,53.79,120.74C837.39,350.13,841.98,377.42,842.86,405.25z" />
    <path style={{ fill: '#2BB6C5' }} d="M842.86,405.25c-0.87-27.83-5.46-55.12-12.44-82.01c-11.21-43.23-28.72-83.88-53.79-120.74c-14.09-20.71-30.75-39.75-46.97-58.94c-20.84-24.65-45.92-44.64-72.91-62.07c-28.11-18.16-57.92-33.08-89.69-43.96C532,25.51,496.14,17.79,459.08,15.9c-0.19-3.33-0.15-11.43,0.06-12.16c12.5,1.13,25.1,1.6,37.48,3.49c54.88,8.35,106.2,26.9,153.73,55.53c46.43,27.97,86.06,63.6,119.04,106.74c29.25,38.25,51.12,80.25,66.11,125.89c10.46,31.84,17.12,64.49,19.35,97.97c0.27,4.03,0.39,8.1,0.36,12.15C853.79,405.58,845.57,405.38,842.86,405.25z" />
  </svg>
);

export default NsLogo;
