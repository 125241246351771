const styles = (theme) => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  title: {
    flex: '1 1 auto',
  },
  list: {
    listStyleType: 'lower-alpha',
  },
  nestedList: {
    listStyleType: 'lower-roman',
  },
});

export default styles;
