const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  dob: {
    marginTop: theme.spacing(2),
  },
});

export default styles;
