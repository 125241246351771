const styles = (theme) => ({
  session: {
    padding: theme.spacing(2),
  },
  title: {
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 12,
  },
  medicationContainer: {
    display: 'flex',
    fontSize: 12,
  },
  treatment_type: {
    marginRight: theme.spacing(2),
  },
  medication_type: {
    marginLeft: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default styles;
