import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Avatar,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import {
  StaffMemberDetails,
} from './components';

import SessionsTable from '../Clinic/components/Sessions/components/SessionsTable';
import TeamsTable from '../Clinic/components/Teams/components/TeamsTable';


import styles from './styles';

const StaffMember = ({ classes }) => {
  const { staffUUID, clinicUUID } = useParams();

  const [staffMember, setStaffMember] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [teams, setTeams] = useState([]);

  const [photo] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);


  const crumbs = [
    { label: 'Clinics', to: '/client/clinics' },
    { label: clinic ? clinic.name : '', to: `/client/clinics/${clinicUUID}` },
    { label: 'Staff Members', to: `/client/clinics/${clinicUUID}#staff` },
    { label: staffMember ? staffMember.full_name : '' },
  ];

  const count = { aggregate: { count: types.number }};
  const q = {
    client_staff_by_pk: params(
      { id: rawString(staffUUID) },
      {
        id: types.string,
        full_name: types.string,
        first_name: types.string,
        last_name: types.string,
        email: types.string,
        staff_type: types.string,
        clinic: {
          id: types.string,
          name: types.string,
        },
        team_staff_assignments: {
          team: {
            id: types.string,
            name: types.string,
            team_staff_assignments_aggregate: count,
            sessions_aggregate: count,
            sessions: {
              id: types.string,
              name: types.string,
              start_date: types.string,
              end_date: types.string,
              clinic: {
                name: types.string,
              }
            },
          },
        },
      },
    ),
  };

  const { data } = useQuery(gql`${query(q)}`, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const stm = data.client_staff_by_pk;
      if (stm) {
        setStaffMember(stm);
        setClinic(stm.clinic);
        const tems = stm.team_staff_assignments.flatMap(i => i.team);
        setTeams(tems);
        const sesins = tems.reduce((a, b) => [...a, ...b.sessions], []);
        setSessions(sesins.map(i => ({
          ...i,
          start_date: new Date(i.start_date),
          end_date: new Date(i.end_date),
        })));
      }
    }
    return () => { mounted = false; };
  }, [data]);

  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dashboard title={`Staff Member - ${staffMember ? staffMember.full_name : ''}`}>
      <NavigationBreadcrumbs crumbs={crumbs} />
      {staffMember && (
        <div className={classes.root}>
          <Paper className={classes.profile} elevation={1}>
            <div className={classes.avatarContainer}>
              <Avatar src={photo} className={classes.photo} />
              <Typography variant="h6">{staffMember.full_name}</Typography>
              <Typography variant="body1">{staffMember.email.toLowerCase()}</Typography>
              <Typography variant="body1">{staffMember.staff_type.toLowerCase()}</Typography>
            </div>
          </Paper>
          <Paper className={classes.actions} elevation={1}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabIndexChange}>
              <Tab label="Account" />
              <Tab label="Sessions" />
              <Tab label="Teams" />
              <Tab label="Data" />
            </Tabs>
            <Divider />
            <div className={classes.tabContent}>
              {tabIndex === 0 && (
                <StaffMemberDetails data={staffMember} />
              )}
              {tabIndex === 1 && (
                <SessionsTable data={sessions} />
              )}
              {tabIndex === 2 && (
                <TeamsTable data={teams} />
              )}
              {tabIndex === 3 && (
                <Typography>General user logs and sessions</Typography>
              )}
            </div>
          </Paper>
        </div>
      )}
    </Dashboard>
  );
};

export default withStyles(styles)(StaffMember);
