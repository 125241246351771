import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../components/StateProvider';
import MedicLayout from '../../../../layouts/Medic';
import ButtonContainer from '../../components/ButtonContainer';
import Begin from '../../components/Images/Begin';

import styles from './styles';

const Welcome = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const handleContinue = () => {
    history.push({
      pathname: `/medic/session/${sessionUUID}/patient/id-scan`,
      state: {
        ...history.location.state,
      }
    });
  };

  const handleAbort = () => {
    nsProDevice.stop();
    history.replace(`/medic/session/${sessionUUID}`);
  };

  return (
    <MedicLayout title="Begin when ready">
      <div className={classes.imageContainer}>
        <div className={classes.image}>
          <Begin />
        </div>
      </div>
      <ButtonContainer split>
        <Button data-qa="dashboard-button" variant="contained" onClick={handleAbort}>Abort</Button>
        {/* <Button variant="contained" color="primary" onClick={handleNeedleDestruction}>Needle Destruction</Button> */}
        <Button data-qa="begin-button" variant="contained" color="primary" onClick={handleContinue}>Begin Vaccination</Button>
      </ButtonContainer>
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(Welcome));
