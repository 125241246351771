import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  withStyles,
} from '@material-ui/core';

import styles from './styles';
import { validateEmailAddress } from '../../../../../../../utils/validators';

const validGenders = ['male', 'female', 'other', 'prefernottosay'];
const validGendersPropper = ['Male', 'Female', 'Other', 'PreferNotToSay'];

const PatientsUploadTable = ({ classes, data = [], setTableErrors }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [patients, setPatients] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPatients(data);
  }, [data]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, patients.length - page * rowsPerPage);

  const getDate = (date) => moment(new Date(date)).format('Do MMMM YYYY');

  const validateGender = (gender) => {
    return validGenders.includes(gender.toLowerCase());
  };

  const setErrors = useCallback((validGender, validDate, validEmail) => {
    const errors = [];
    if (!validGender) {
      errors.push(`Genders must conform to the ${validGenders.length} allowed values. ${validGendersPropper.join(', ')}`);
    }
    if (!validDate) {
      errors.push('Invalid date');
    }
    if (!validEmail) {
      errors.push('Invalid email');
    }
    setTableErrors(errors.length > 0);
  }, [setTableErrors]);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table data-qa="patients-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Address Line 1</TableCell>
              <TableCell>Address Line 2</TableCell>
              <TableCell>Address City</TableCell>
              <TableCell>Address Postcode</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.length > 0 && (
              patients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((patient) => {
                const date = getDate(patient.dob);
                const validDate = date !== 'Invalid date';
                const validGender = validateGender(patient.gender);
                const validEmail = validateEmailAddress(patient.email);
                setErrors(validGender, validDate, validEmail);
                return (
                  <TableRow className={classes.tableRow} key={patient.id}>
                    <TableCell  className={!patient.first_name ? classes.errorText : null} component="th" scope="row">{patient.first_name || 'MISSING'}</TableCell>
                    <TableCell  className={!patient.last_name ? classes.errorText : null} component="th" scope="row">{patient.last_name || 'MISSING'}</TableCell>
                    <TableCell className={!validGender ? classes.errorText : null}>{patient.gender}</TableCell>
                    <TableCell className={!validDate ? classes.errorText : null}>{date}</TableCell>
                    <TableCell className={!validEmail ? classes.errorText : null}>{patient.email}</TableCell>
                    <TableCell className={!patient.address_line_1 ? classes.errorText : null}>{patient.address_line_1 || 'MISSING'}</TableCell>
                    <TableCell>{patient.address_line_2}</TableCell>
                    <TableCell className={!patient.address_city ? classes.errorText : null}>{patient.address_city || 'MISSING'}</TableCell>
                    <TableCell className={!patient.address_postcode ? classes.errorText : null}>{patient.address_postcode || 'MISSING'}</TableCell>
                  </TableRow>
                );
              })
            )}
            {patients.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={20} />
              </TableRow>
            )}
            {patients.length === 0 && (
              <TableRow>
                <TableCell className={classes.loader} colSpan={20}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={patients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage} />
    </Paper>
  );
};

export default withRouter(withStyles(styles)(PatientsUploadTable));
