const styles = (theme) => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '40%',
    minWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    // padding: theme.spacing(3),
    paddingBottom: 0,
  },
  photo: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    alignSelf: 'center',
    marginBottom: theme.spacing(4),
  },
  imageContainer: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80%',
    '& > svg': {
      height: '100%',
    },
  },
});

export default styles;
