import React, { useEffect, useState } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import { validateEmailAddress } from '../../../../../../../utils/validators';

import styles from './styles';

const CreateStaffMemberDialog = ({ classes, clientUUID, clinicUUID, staffType, handleClose, handleCreate }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState({});
  
  const m = gql`
    mutation addStaffMember($object: client_staff_insert_input!) {
      insert_client_staff_one(object: $object) {
        id
        full_name
        email
        staff_type
      }
    }
  `;

  const [addStaffMember, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleCreate();
    }
    return () => { mounted = false; };
  }, [data, handleCreate]);

  const handleContinueCreate = () => {
    let error = false;
    setErrors({});
    if (!firstName) {
      setErrors((e) => ({ ...e, firstName: 'Please provide a first name' }));
      error = true;
    }
    if (!lastName) {
      setErrors((e) => ({ ...e, lastName: 'Please provide a last name' }));
      error = true;
    }
    if (!email) {
      setErrors((e) => ({ ...e, email: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(email)) {
      setErrors((e) => ({ ...e, email: 'Please provide a valid email address' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = () => {
    addStaffMember({
      variables: {
        object: {
          client_id: clientUUID,
          clinic_id: clinicUUID,
          first_name: firstName,
          last_name: lastName,
          email: email.toLowerCase(),
          staff_type: staffType.toUpperCase(),
        }
      },
    });
  };

  const getTitle = () => {
    if (staffType === 'MANAGER') {
      return 'Lead Medic';
    }
    return `${staffType[0].toUpperCase()}${staffType.slice(1)}`;
  };

  return (
    <Dialog data-qa="create-staffmember-dialog" open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create {getTitle()}</DialogTitle>
      <DialogContent>
        <div autoComplete="off" className={classes.form}>
          <TextField data-qa="first-name-input" required label="First name" name="firstName" error={errors.firstName} helperText={errors.firstName} value={firstName} autoComplete="off" onChange={(event) => setFirstName(event.target.value || null)} />
          <TextField data-qa="last-name-input" required label="Last name" name="lastName" error={errors.lastName} helperText={errors.lastName} value={lastName} autoComplete="off" onChange={(event) => setLastName(event.target.value || null)} />
          <TextField data-qa="email-input" required label="Email address" type="email" error={errors.email} helperText={errors.email} value={email} onChange={(event) => setEmail(event.target.value || null)} />
        </div>
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button data-qa="circular-progress-button" color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="cancel-button" onClick={handleClose} color="primary">Cancel</Button>
              <Button data-qa="create-button" onClick={handleContinueCreate} color="primary">Create</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CreateStaffMemberDialog);
