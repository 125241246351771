const styles = (theme) => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 650
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  },
  loader: {
    textAlign: 'center'
  },
  dateFromNow: {
    fontSize: '12px',
    color: theme.palette.secondary.main
  },
  endDateFromNow: {
    fontSize: '12px',
    color: theme.palette.error.main
  },
  tableTitle: {
    flex: '1 1 auto'
  },
  canceled: {
    textDecoration: 'line-through',
    color: theme.palette.error.main
  }
});

export default styles;
