import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import ButtonContainer from '../../../../components/ButtonContainer';
import Terms from './components/Terms';

import styles from './styles';

const SignUpTerms = ({ classes, history }) => {
  const handleAgreement = () => {
    history.push({
      pathname: '/patient/sign-up',
      state: {
        ...history.location.state,
      },
    });
  };

  return (
    <PatientLayout toolbarTitle="Terms" canGoBack>
      <div className={classes.root}>
        <div data-qa="patient-layout" className={classes.terms}>
          <Terms />
        </div>
        <ButtonContainer>
          <Button data-qa="agree-button" variant="contained" color="primary" onClick={handleAgreement}>I AGREE</Button>
        </ButtonContainer>
      </div>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpTerms));
