import React from 'react';

const LeftArm = () => (
  <svg version="1.1" id="NS_LIFT_ARM" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 800">
    <path style={{ fill: '#00A2AD' }} d="M283.27,442.96c-5.71,32.74-10.08,65.71-16.99,98.23c-3.97,18.69-8.49,37.27-12.65,55.93c-5.25,23.53-5.19,47.09-1.03,70.8c2.41,13.74,3.51,27.68,5.37,41.51c0.27,1.99-0.43,3.58-1.88,4.9c-85.33,0-170.67,0-256,0c0-136,0-272,0-408c1.64-2.11,3.98-2.02,6.33-2.2c28.06-2.13,55.75-6.4,82.61-15.05c21.81-7.03,42.5-16.31,60.67-30.59c18.22-14.32,32.07-31.71,38.32-54.44c0.55-2,1.39-3.74,3.34-4.72c31.32,5.02,62.74,9.47,93.92,15.26c20.46,3.8,40.45,10,57.57,22.59c13.58,9.99,21.96,23.73,26.52,39.86c-0.44,1.82-1.75,2.72-3.42,3.26c-14.11,4.54-23.86,14.48-32.42,26c-17.31,23.28-28.58,49.64-39.27,76.31c-1.37,3.42-2.16,6.97-2.26,10.67c-2.66,6.36-2.97,13.17-3.89,19.87" />
    <path style={{ fill: '#EDC9AE' }} d="M386.64,416.77c0.07,2.88-0.66,5.57-1.13,8.34c-0.04,0.78-0.07,1.56-0.11,2.33c0.18,0.62,0.2,1.23-0.1,1.82c-2.78,33.65-5.79,67.28-8.26,100.95c-1.38,18.74-2.14,37.53-2.73,56.32c-0.99,31.79,3.37,63.05,9.98,94.06c2.39,11.22,4.53,22.49,6.78,33.74c-32.33,0-64.67,0-97,0c-2.19-1.86-3.15-4.36-3.1-7.1c0.15-7.85-1.08-15.6-1.25-23.44c-0.41-18.63-1.57-37.23-2.74-55.83c-0.61-9.63-0.55-19.29-0.96-28.94c-1.84-43.12-2.19-86.31-0.8-129.41c0.69-21.55,0.5-43.28,3.68-64.75c0.63-4.23,0.13-8.6,1.21-12.82c2.66-8.33,4.76-16.9,8.08-24.96c8.88-21.55,18.66-42.76,33.11-61.23c6.17-7.89,13.59-15.03,21.39-21.33c4.58-3.7,11.04-5.07,16.66-7.49c2.27,7.08,5,14.04,6.74,21.25c7.46,30.9,10.43,62.32,9.97,94.08c-0.02,1.66,0.1,3.33,0.16,4.99" />
    <path style={{ fill: '#EEC9AE' }} d="M191.35,199.33c-3.15,18.58-12.65,33.85-25.27,47.27c-19.9,21.15-44.85,34.09-72.01,43.21c-29.69,9.97-60.33,14.84-91.51,16.69c-0.82,0.05-1.65-0.11-2.48-0.17c0-33.67,0-67.33,0-101c1.49-1.92,3.59-2.19,5.81-2.2c10.13-0.04,20.27-0.06,30.4,0.02c4.43,0.03,7.85,2.36,11.39,4.49c1.49,0.9,2.7,2.39,4.95,2.3c-8.06-6.24-16.17-10.91-26.83-9.53c-6.37,0.82-12.94,0.18-19.42,0.12c-2.36-0.02-4.69-0.1-6.31-2.21c0-37.6,0-75.2,0-113c55.67,0,111.33,0,167,0c-6.25,16.52-13.11,32.73-24.36,46.65c-0.94,1.16-1.71,2.47-2.7,3.58c-19.49,21.99,2.13,61.56,31.53,61.88C178.15,197.52,184.75,198.67,191.35,199.33z" />
    <path style={{ fill: '#ECC0A5' }} d="M0.08,198.34c11.64,0,23.29-0.16,34.92,0.12c2.81,0.07,5.79,1.17,8.32,2.51c5.2,2.76,10.15,6,15.23,9.05c-3,3.79-6.62,4.45-10.23,1.19c-5.33-4.8-11.34-6.21-18.31-5.97c-9.97,0.35-19.95,0.09-29.93,0.09C0.08,203,0.08,200.67,0.08,198.34z" />
    <path style={{ fill: '#00A2AD' }} d="M445.91,324.32c0.71,2.04,1.42,4.08,2.12,6.12c-2.39-3.14-5.34-2.24-8.44-1.34c-12.56,3.67-25.16,7.23-37.74,10.84c-0.18-0.38-0.36-0.76-0.54-1.15c2.37-1.05,4.66-2.37,7.12-3.09c11.3-3.32,22.65-6.45,33.96-9.72C443.62,325.63,444.74,324.88,445.91,324.32z" />
    <path style={{ fill: '#FFFBFC', stroke: '#000000', strokeWidth: 0.5, strokeMiterlimit: 10 }} d="M524,325c-4.37,1.46-48.99,13.74-54.07,15.14c-0.66-2.6-1.22-4.78-1.77-6.97c-1.11-1.02-0.04-3.43-2.34-3.7c-2.26,0.25-4.52,0.51-6.77,0.76c-2.39,0.28-4.81-0.2-7.16,1c-1.46-0.34-2.48-0.57-3.94-0.91c-1.39-1.75-1.83-3.84-2.03-6.01c-0.13-0.17-0.17-0.36-0.11-0.57c-0.36-1.53-0.6-3.02,1.37-3.69c3.33-1.86,6.55-3.97,10.01-5.51c3.77-1.67,4.99-3.95,3.33-7.8c-0.63-1.47-0.86-3.12-1.38-5.12c15.31-4.46,30.24-8.82,45.18-13.17c0.84-2.34,2.9-3.11,4.99-3.73c11.47-3.4,23-6.58,34.42-10.12c2.36-0.73,4.89-1.02,7.4-0.43c0.32,0.04,0.63,0.11,0.94,0.21c0.67,0.55,1.35,0.55,2.03,0.01c10.04-2.89,20.07-5.83,30.14-8.65c2.47-0.69,3-0.8,2.27-4.24c-0.13-0.61-2-3,1-4s3.48,1.62,3.42,3.13c-0.14,3.62,2.21,6.9,3.51,11.15c9.95-2.8,13.82-3.67,23.06-6.27c3,0,5.09-3.7,5-4c-1.52-4.81,3-4,4-3c0.98,1.87,0.7,2.85,1.68,4.72c2.16,7.38,4.07,14.86,6.59,22.12c1.42,4.09,0.09,5.13-3.62,4.96c-5.4-2.8-10.28,0.29-15.29,1.45c-2.58,0.6-5.02,1.85-7.6,2.36c-3.68,0.74-4.54,2.64-3.72,6.07c0.68,2.85,4.96,6.32,0.96,8.32c-2,1-2.87-0.19-2.93-0.28c-3.93-6.19-9.13-3.3-14.04-1.89c-10.33,2.96-20.63,6-30.93,9.07c-1.56,0.46-3.02,1.25-4.53,1.89c-7.16,4.09-14.63,7-23.12,6C528.47,323.74,526.57,324.21,524,325" />
    <path style={{ fill: '#ECC0A5' }} d="M120.13,205.34c-7.83,0-15.66,0.09-23.49-0.03c-6.01-0.09-11.98-0.48-16.77,4.37c-0.75,0.75-2.62,0.4-3.97,0.55c0.37-1.44,0.35-3.17,1.17-4.27c3.95-5.3,9.47-7.61,15.99-7.61c17.99-0.01,35.98-0.03,53.97,0.03c2.13,0.01,4.37,0.24,6.36,0.94c1.22,0.43,2.06,1.94,3.07,2.96c-1.29,1.01-2.54,2.85-3.87,2.9c-7.15,0.3-14.32,0.15-21.48,0.16C127.46,205.34,123.8,205.34,120.13,205.34z" />
    <path style={{ fill: '#00A2AD' }} d="M523.85,286.65c3.14,10.9,6.07,21.07,9.14,31.7c-2.3,0.77-4.27,1.43-6.51,2.18c-0.82-1.84-1.53-3.42-2.24-5.01c-0.33,0.06-0.65,0.11-0.98,0.17c0.23,1.54,0.45,3.07,0.78,5.31c-1.51,0.43-3.12,0.9-4.96,1.42c-0.99-2.96-1.84-5.51-2.69-8.06c-0.4,0.11-0.79,0.23-1.19,0.34c0.58,2.62,1.15,5.24,1.81,8.24c-1.65,0.5-3.15,0.96-4.69,1.42c-0.91-1.9-1.65-3.46-2.39-5.01c-0.26,0.08-0.51,0.16-0.77,0.23c0.21,1.57,0.42,3.14,0.7,5.16c-1.34,0.53-2.78,1.1-4.6,1.82c-1.12-2.92-2.12-5.54-3.12-8.17c-0.35,0.11-0.7,0.22-1.05,0.34c0.57,2.5,1.14,5,1.86,8.14c-1.53,0.5-3.13,1.02-4.79,1.56c-0.9-2.06-1.6-3.66-2.3-5.26c-0.28,0.07-0.55,0.14-0.83,0.21c0.24,1.69,0.47,3.37,0.78,5.57c-1.37,0.48-2.84,0.99-4.64,1.62c-1.07-2.9-2.01-5.44-2.95-7.97c-0.38,0.1-0.77,0.19-1.15,0.29c0.59,2.54,1.18,5.07,1.85,7.94c-1.57,0.54-3.07,1.05-4.73,1.62c-0.89-1.82-1.65-3.39-2.41-4.95c-0.21,0.05-0.42,0.1-0.63,0.15c0.15,1.72,0.3,3.44,0.47,5.48c-2.86,0.85-5.69,1.68-9.2,2.71c-3.06-10.6-6.03-20.88-9.2-31.85C483.42,298.23,503.3,292.53,523.85,286.65z" />
    <path style={{ fill: '#D1EFF2' }} d="M592.08,300.4c-4.33,1.47-8.63,3.07-13.02,4.32c-0.93,0.26-2.41-0.54-3.31-1.25c-1.23-0.98-2.15-2.34-3.79-4.21c-0.68,3.14-1.17,5.39-1.99,9.12c-1.71-2.94-2.69-4.63-4.49-7.7c-0.45,2.83-0.58,4.29-0.95,5.69c-0.25,0.96-0.82,1.83-1.25,2.74c-0.99-0.7-2.44-1.19-2.87-2.14c-0.69-1.53-0.7-3.36-1-5.07c-0.53-0.02-1.05-0.04-1.58-0.06c-0.19,2.97-0.38,5.95-0.63,9.9c-2.33-1.63-3.73-2.6-5.14-3.58c-1.12,1.93-2.21,3.79-3.36,5.77c-6.96-7.84-10.26-16.75-12.46-26.16c-0.78-3.35,1.08-4.88,4.06-5.66c5.9-1.55,11.78-3.18,17.67-4.78c5.08-0.16,8.65,2.97,12.38,5.73c6.01,4.45,12.77,7.74,18.69,12.31C590.72,296.64,592.85,297.65,592.08,300.4z" />
    <path style={{ fill: '#D1EFF2' }} d="M592.08,300.4c-11.37-7.7-22.73-15.39-34.1-23.09c10.24-2.99,20.49-5.98,30.9-9.01c2.89,9.96,5.64,18.91,8.03,27.97C597.97,300.24,594.94,300.46,592.08,300.4z" />
    <path style={{ fill: '#D1EFF2' }} d="M602.17,289.75c-1.19-1.09-2.77-1.92-3.33-3.2c-1.12-2.57-2.16-5.36-2.29-8.1c-0.07-1.38,1.63-3.72,3-4.17c7.59-2.44,15.33-4.43,23.02-6.56c0.47-0.13,1.35-0.3,1.42-0.15c1.59,3.28,3.32,6.51,4.48,9.95c0.4,1.2-0.3,3.92-1.11,4.2C619.2,284.55,610.92,287.02,602.17,289.75z" />
    <path style={{ fill: '#D1EFF2' }} d="M499.6,298.19c5.32-1.52,10.65-3.03,15.97-4.55c0.2,0.65,0.4,1.3,0.6,1.95c-5.32,1.59-10.65,3.18-15.97,4.77	C500,299.64,499.8,298.92,499.6,298.19z" />
  </svg>
);

export default LeftArm;
