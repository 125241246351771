import { WithStyles } from '@material-ui/core/styles';
import React, { useState, PropsWithChildren, ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  Drawer,
  withStyles,
  withWidth,
  WithWidth,
} from '@material-ui/core';
import { EmptyObject } from '../../react-app-env';

import {
  Sidebar,
  Topbar,
} from './components';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps, WithWidth, PropsWithChildren<EmptyObject> {
  title: string;
  tabs: null;
}

const Dashboard = ({ classes, width, title, tabs = null, history, children }: Props): ReactElement => {

  const [isMobile] = useState(['xs', 'sm', 'md'].includes(width));
  const [isOpen, setIsOpen] = useState(!isMobile);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggleOpen = () => {
    setIsOpen((open) => !open);
  };

  const shiftContent = isOpen && !isMobile;

  return (
    <>
      <Topbar
        className={`${classes.topbar} ${shiftContent ? classes.topbarShift : ''}`}
        isSidebarOpen={isOpen}
        onToggleSidebar={handleToggleOpen}
        title={title} />
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        onClose={handleClose}
        open={isOpen}
        variant={isMobile ? 'temporary' : 'persistent'}>
        <Sidebar />
      </Drawer>
      <main className={`${classes.content} ${shiftContent ? classes.contentShift : ''}`}>
        {tabs !== null && (
          <div className={classes.tabsContainer}>
            {tabs}
          </div>
        )}
        <div className={classes.contentContainer}>
          {children}
        </div>
      </main>
    </>
  );
};

export default withRouter(withStyles(styles)(withWidth()(Dashboard)));
