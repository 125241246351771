import React, { useEffect } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const RevokeInviteDialog = ({ handleClose, sessionInvite }) => {
  const m = gql`
    mutation revokeInvite($pk_columns: session_invites_pk_columns_input!, $set: session_invites_set_input!, $object: session_invites_insert_input!) {
      update_session_invites_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
      }
      insert_session_invites_one(object: $object) {
        id
      }
    }
  `;

  const [revokeInvite, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleClose();
    }
    return () => { mounted = false; };
  }, [data, handleClose]);

  const handleRevoke = () => {
    revokeInvite({
      variables: {
        pk_columns: {
          id: sessionInvite.id,
        },
        set: {
          active: false,
        },
        object: {
          client_id: sessionInvite.client_id,
          clinic_id: sessionInvite.clinic_id,
          session_id: sessionInvite.session_id,
          name: sessionInvite.name,
        },
      },
    });
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Revoke session invite link</DialogTitle>
      <DialogContent>
        Are you sure you want to revoke this session invite?
        Anyone with this link will no longer be able to sign up to this session.
        <p>A new link will be created.</p>
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="revoke-cancel" onClick={handleClose} color="primary">No</Button>
              <Button data-qa="revoke-confirm" onClick={handleRevoke} color="primary">Yes revoke it</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RevokeInviteDialog);
