import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../components/StateProvider';
import MedicLayout from '../../../../layouts/Medic';
import ButtonContainer from '../../components/ButtonContainer';
import LottieAnimation from '../../../../components/LottieAnimation';

import styles from './styles';

const NeedleDestruction = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const [needle, setNeedle] = useState(null);
  const [ready, setReady] = useState(false);
  const [noNeedle, setNoNeedle] = useState(false);

  const m = gql`
    mutation addNeedleResult($object: needle_results_insert_input!,) {
      insert_needle_results_one(object: $object) {
        id
      }
    }
  `;

  const [addNeedleResult, { data }] = useMutation(m);

  const handleAbort = () => {
    nsProDevice.stop();
    history.replace(`/medic/session/${sessionUUID}`);
  };

  const handleContinue = () => {
    history.replace({
      pathname: `/medic/session/${sessionUUID}/dvs-signature`,
      state: {
        ...history.location.state,
        needle,
      },
    });
  };

  const handleRetry = () => {
    setNeedle(null);
    setNoNeedle(false);
    readyToMelt();
  };

  const readyResponse = (value) => {
    setReady(value === 'READY_TO_MELT');
  };

  const meltResponse = (value) => {
    setNeedle(null);
    setNoNeedle(false);
    if (value === 'NO_NEEDLE') {
      return setNoNeedle(true);
    }
    if (value === 'FAIL') {
      return setNoNeedle(true);
    }
    setNeedle({
      timestamp: new Date().getTime(),
      temperature: 1200,
      success: value === 'PASS',
    });
  };

  const readyToMelt = useCallback(() => {
    nsProDevice.readyToMelt(readyResponse, meltResponse);
  }, [nsProDevice]);

  useEffect(() => {
    if (needle) {
      const {arm, staffUUID, patient, session, qualification, vaccine } = history.location.state;
      addNeedleResult({
        variables: {
          object: {
            client_staff_id: staffUUID,
            clinic_id: session.clinic.id,
            patient_id: patient.id,
            client_id: session.client.id,
            session_id: session.id,
            medication_type_id: session.medication_type.id,
            treatment_type_id: session.treatment_type.id,
            ns_pro_device_id: 'NO_IDEA',
            dose_id: vaccine.id,
            arm,
            qualified: qualification !== null,
            temperature: needle.temperature,
            success: needle.success,
          },
        },
      });
    }
  }, [needle, history, addNeedleResult]);

  useEffect(() => {
    if (nsProDevice) {
      readyToMelt();
    }
  }, [nsProDevice, readyToMelt]);

  if (noNeedle) {
    return (
      <MedicLayout title="Unsuccessful record">
        <div className={classes.imageContainer}>
          <div className={classes.image}>
            <LottieAnimation file="error" />
            <Typography variant="body1">Needle was not found</Typography>
          </div>
        </div>
        <ButtonContainer>
          <Button data-qa="retry-button" variant="contained" color="primary" onClick={handleRetry}>Retry</Button>
        </ButtonContainer>
      </MedicLayout>
    );
  }

  if (needle) {
    return (
      <MedicLayout title="Successful record">
        <div className={classes.imageContainer}>
          <div className={classes.image}>
            <LottieAnimation file="complete" />
            <Typography variant="body1">Needle successfully destroyed</Typography>
          </div>
        </div>
        {data && (
          <ButtonContainer>
            <Button data-qa="signature-button" variant="contained" color="primary" onClick={handleContinue}>View Signature</Button>
          </ButtonContainer>
        )}
      </MedicLayout>
    );
  }

  return (
    <MedicLayout title="Needle destruction">
      {!ready && (
        <div className={classes.imageContainer}>
          <div className={classes.image}>
            <LottieAnimation file="waiting" />
            <Typography variant="body1">Awaiting device ready to melt</Typography>
          </div>
        </div>
      )}
      {ready && (
        <div className={classes.imageContainer}>
          <div className={classes.image}>
            <LottieAnimation file="thermometer" />
            <Typography variant="body1">Awaiting needle melt</Typography>
          </div>
        </div>
      )}
      <ButtonContainer leftAligned>
        <Button data-qa="abort-button" variant="contained" onClick={handleAbort}>Abort</Button>
      </ButtonContainer>
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(NeedleDestruction));
