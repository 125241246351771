import React, { useContext, useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, useMutation, gql } from '@apollo/client';

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';

import { AuthContext } from '../../../components/AuthProvider';
import { PatientLayout } from '../../../layouts';

import getDate from '../../../utils/conversions';

import styles from './styles';
import PreQualification from './components/PreQualification';

const ConfirmInvite = ({ classes, history }) => {
  const { user } = useContext(AuthContext);
  const [session, setSession] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [medicationType, setMedicationType] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [accepted, setAccepted] = useState(false);

  const q = {
    session_invites_by_pk: params(
      { id: rawString(history.location.state.invite.id) },
      {
        id: types.string,
        name: types.string,
        session: {
          id: types.string,
          name: types.string,
          start_date: types.string,
          end_date: types.string,
          client: {
            id: types.string,
          },
          clinic: {
            id: types.string,
            name: types.string,
            address_line_1: types.string,
            address_line_2: types.string,
            address_city: types.string,
            address_postcode: types.string,
          },
          medication_type: {
            name: types.string,
            qualification: types.string,
            treatment_type: {
              name: types.string,
            },
          },
        },
      },
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const si = data.session_invites_by_pk;
      if (si) {
        setSession({
          ...si.session,
          start_date: new Date(si.session.start_date),
          end_date: new Date(si.session.end_date),
        });
        setClinic(si.session.clinic);
        setMedicationType(si.session.medication_type);
        let qus = si.session.medication_type.qualification || [];
        if (qus.length > 0) {
          qus = qus.filter(i => i.onInvite);
        }
        setQuestions(qus);
      }
    }
    return () => { mounted = false; };
  }, [data]);

  const m = gql`
    mutation acceptInvitation($object: session_patient_assignment_insert_input!) {
      insert_session_patient_assignment_one(object: $object) {
        id
      }
    }
  `;

  const [acceptInvitation, { data: mutationData, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && mutationData) {
      history.replace('/patient/home');
    }
    return () => { mounted = false; };
  }, [mutationData, history]);

  const handleAccept = async () => {
    setAccepted(true);
    if (questions.length === 0) {
      handleComplete();
    }
  };

  const handleDecline = () => {
    history.replace('/patient/home');
  };

  const handleComplete = useCallback((qualification = null) => {
    const { invite } = history.location.state;
    acceptInvitation({
      variables: {
        object: {
          patient_id: user.id,
          client_id: invite.client_id,
          clinic_id: invite.clinic_id,
          session_id: invite.session_id,
          name: invite.name,
          qualification,
        },
      },
    });
  }, [history, user, acceptInvitation]);

  const handleCompleteQualifications = async (data) => {
    const answers = {};
    data.forEach((answer, index) => {
      const { key } = questions[index];
      answers[key] = answer === 'YES';
    });
    handleComplete(answers);
  };

  if (loading) {
    return (
      <PatientLayout toolbarTitle="Accepting invitation">
        <div className={classes.accepting}>
          <CircularProgress size={64} />
          <Typography className={classes.pleaseWait}>Please wait</Typography>
        </div>
      </PatientLayout>
    );
  }

  if (accepted && questions.length > 0) {
    return (
      <PreQualification questions={questions} handleComplete={handleCompleteQualifications} />
    );
  }

  return (
    <PatientLayout toolbarTitle="Confirm session">
      <div className={classes.body}>
        {session && (
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                {session.name}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                {medicationType ? `${medicationType.treatment_type.name} - ${medicationType.name}` : null}
              </Typography>
              <Typography className={classes.date} color="textSecondary" gutterBottom>
                {getDate(session.start_date)} | {getDate(session.end_date)}
              </Typography>
              <Typography className={classes.clinic} color="textSecondary">
                {clinic ? clinic.name : null}
              </Typography>
              <div className={classes.address}>
                <Typography variant="body2" color="textSecondary">{session.clinic.address_line_1}</Typography>
                <Typography variant="body2" color="textSecondary">{session.clinic.address_line_2}</Typography>
                <Typography variant="body2" color="textSecondary">{session.clinic.address_city}</Typography>
                <Typography variant="body2" color="textSecondary">{session.clinic.address_postcode}</Typography>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
      <Typography align="center">You have been invited to a NeedleSmart vaccination session. Please accept or decline</Typography>
      <Button className={classes.button} variant="contained" color="primary" onClick={handleAccept}>Accept</Button>
      <Button className={classes.button} variant="outlined" color="primary" onClick={handleDecline}>Decline</Button>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(ConfirmInvite));
