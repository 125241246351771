import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import TableTitle from '../../../components/TableTitle';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import SessionsTable from './components/SessionsTable';

import styles from './styles';

const Location = ({ history, classes }) => {
  const { clientUUID, clinicUUID } = useParams();
  const todayTimestamp = new Date().getTime();

  const [client, setClient] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [sessions, setSessions] = useState([]);

  const crumbs = [
    { label: 'Clients', to: '/admin/clients' },
    { label: client ? client.name : '', to: `/admin/clients/${clientUUID}` },
    { label: clinic ? clinic.name : '' },
  ];

  const q = {
    clients_by_pk: params(
      { id: rawString(clientUUID) },
      {
        id: types.string,
        name: types.string,
      },
    ),
    clinics_by_pk: params(
      { id: rawString(clinicUUID) },
      {
        id: types.string,
        name: types.string,
      },
    ),
    sessions: params(
      {where: {clinic_id: { _eq: rawString(clinicUUID) }}},
      [{
        id: types.string,
        team_id: types.string,
        client_id: types.string,
        clinic_id: types.string,
        treatment_type_id: types.string,
        medication_type_id: types.string,
        name: types.string,
        start_date: types.string,
        end_date: types.string,
      }],
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClient(data.clients_by_pk);
      setClinic(data.clinics_by_pk);
      setSessions(data.sessions.map(i => ({
        ...i,
        start_date: new Date(i.start_date),
        end_date: new Date(i.end_date),
      })));
    }
    return () => { mounted = false; };
  }, [data]);

  const handleSession = (sessionUUID) => {
    history.push(`/admin/clients/${clientUUID}/clinic/${clinicUUID}/sessions/${sessionUUID}`);
  };

  return (
    <>
      <Dashboard title={`Clinic - ${client ? client.name : ''} - ${clinic ? clinic.name : ''}`}>
        <NavigationBreadcrumbs crumbs={crumbs} />
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="Upcoming sessions" />
          </div>
          <SessionsTable data={sessions.filter((i) => i.end_date >= todayTimestamp)} handleSession={handleSession} />
        </div>
        <div className={classes.table}>
          <TableTitle title="Past sessions" />
          <SessionsTable data={sessions.filter((i) => i.end_date < todayTimestamp)} handleSession={handleSession} />
        </div>
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(Location));
