const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  error: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main,
  },
});

export default styles;
