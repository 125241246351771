import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import BodyText from '../../../../components/BodyText';
import LottieAnimation from '../../../../components/LottieAnimation';
import ButtonContainer from '../../../../components/ButtonContainer';

import styles from './styles';

const SignUpFinish = ({ history }) => {
  const handleContinue = () => {
    const { state } = history.location;
    if (state) {
      const { invite, patient } = state;
      if (invite) {
        return history.push({
          pathname:'/patient/confirm-invite',
          state: {
            patient,
            invite,
          },
        });
      }
    }

    history.replace({
      pathname: '/patient/home',
      state: {
        ...history.location.state,
      },
    });
  };

  return (
    <PatientLayout title="You're all set">
      <LottieAnimation file="complete" />
      <BodyText>You&apos;re all signed up. You can now use your NeedleSmart ID to prove who you are.</BodyText>
      <ButtonContainer>
        <Button variant="contained" color="primary" onClick={handleContinue}>FINISH</Button>
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpFinish));
