import React, { ReactElement, useContext } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  MenuOpen as CloseIcon,
  Input as InputIcon,
} from '@material-ui/icons';

import styles from './styles';
import { AuthContext } from '../../../../components/AuthProvider';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  title: string;
  classes: ClassNameMap<string>,
  className: string;
  isSidebarOpen: boolean;
  onToggleSidebar?: () => void;
}

const Topbar = ({ classes, className, history, title, isSidebarOpen, onToggleSidebar }: Props): ReactElement => {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }
  const { signOut } = authContext;
  const handleSignOut = async () => {
    await signOut();
    history.push('/sign-in');
  };

  return (
    <>
      <div className={`${classes.root} ${className}`}>
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.menuButton} onClick={onToggleSidebar}>
            {/*<IconButton className={classes.menuButton} onClick={onToggleSidebar} variant="text">*/}
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography data-qa="topbar-title" className={classes.title} variant="h2">{title}</Typography>
          <div className={classes.toolbarButtons}>
            <Tooltip title="Sign out" arrow>
              <IconButton data-qa="signout-button" className={classes.toolbarButton} onClick={handleSignOut}>
                <InputIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </div>
    </>
  );
};

export default withRouter(withStyles(styles)(Topbar));
