import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  withStyles,
} from '@material-ui/core';


import { PatientLayout } from '../../../../layouts';
import BodyText from '../../../../components/BodyText';
import ButtonContainer from '../../../../components/ButtonContainer';
import LottieAnimation from '../../../../components/LottieAnimation';
import ProgressIndicator from '../../../../components/ProgressIndicator';

import styles from './styles';

const TakePhoto = ({ classes, history }) => {

  const [takingPhoto, setTakingPhoto] = useState(false);
  const [photo, setPhoto] = useState(null);

  const cameraElement = useRef(null);

  const [cameraOpen, setCameraOpen] = useState(false);

  const handleTakePhoto = () => {
    setTakingPhoto(true);
  };

  const handleFile = (fileUpload) => {
    if (fileUpload) {
      const reader = new FileReader();
      reader.onloadend = () => {
        cropPhoto(reader.result);
        setTakingPhoto(false);
      };
      reader.readAsDataURL(fileUpload);
    } else {
      setPhoto(null);
      setCameraOpen(false);
    }
  };

  const handleContinue = () => {
    history.push({
      pathname: '/patient/sign-up-details-personal',
      state: {
        ...history.location.state,
        photo,
      },
    });
  };

  const cropPhoto = (dataUri) => {
    const image = new Image();
    image.onload = () => {
      const size = 400;
      const scale = (size / image.width);
      const width = image.width * scale;
      const height = image.height * scale;
      const y = (height - size) / 2;
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, -y, width, height);
      setPhoto(canvas.toDataURL('image/jpeg'));
    };
    image.src = dataUri;
  };

  useEffect(() => {
    if (takingPhoto) {
      setTimeout(() => {
        cameraElement.current.click();
      }, 300);
    }
  }, [takingPhoto]);

  const handleFileClick = (event) => {
    if (cameraOpen && event) {
      event.stopImmediatePropagation();
    } else {
      setCameraOpen(true);
      setTimeout(() => {
        setCameraOpen(false);
      }, 2000);
    }
  };

  if (takingPhoto) {
    return (
      <div className={classes.openCamera}>
        <label htmlFor="File">
          <Button data-qa="take-photo-button" variant="contained" color="primary">
            {!cameraOpen && 'OPEN CAMERA'}
            {cameraOpen && (<ProgressIndicator size={24} color="inherit" />)}
          </Button>
        </label>
        <input
          id="File"
          name="File"
          className={classes.file}
          type="file"
          ref={cameraElement}
          accept="image/*"
          capture="user"
          onClick={(event) => handleFileClick(event)}
          onChange={(event) => handleFile(event.target.files[0])} />
      </div>
    );
  }

  if (photo !== null) {
    return (
      <PatientLayout toolbarTitle="NeedleSmart photo ID" canGoBack>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photo} alt="" />
        </div>
        <BodyText align="center">Make sure you can see you full face</BodyText>
        <ButtonContainer>
          <Button data-qa="retake-photo-button" variant="outlined" color="primary" onClick={handleTakePhoto}>RE-TAKE PHOTO</Button>
          <Button data-qa="continue-button" variant="contained" color="primary" onClick={handleContinue}>CONTINUE</Button>
        </ButtonContainer>
      </PatientLayout>
    );
  }

  return (
    <PatientLayout toolbarTitle="NeedleSmart photo ID" canGoBack>
      <LottieAnimation file="camera" size={15} />
      <BodyText varient="h6" align="center">Let&apos;s make sure nobody can impersonate you.</BodyText>
      <BodyText align="center">Take a passport-style portrait photo of your likeness. No hats, scarfs, sun glasses or masks.</BodyText>
      <ButtonContainer>
        <Button data-qa="take-photo-button" variant="contained" color="primary" onClick={handleTakePhoto}>TAKE PHOTO</Button>
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(TakePhoto));
