const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '&[data-split="true"]': {
      justifyContent: 'space-between',
    },
    '&[data-leftaligned="true"]': {
      justifyContent: 'flex-start',
    },
  },
});

export default styles;
