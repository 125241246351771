import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  Button,
  TextField,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../components/StateProvider';
import MedicLayout from '../../../../layouts/Medic';
import ButtonContainer from '../../components/ButtonContainer';
import getDate from '../../../../utils/conversions';

import styles from './styles';

const DvsSignature = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const [date, setDate] = useState('');
  const [patient, setPatient] = useState('');
  const [clinic, setClinic] = useState('');
  const [medicationType, setMedicationType] = useState('');
  const [signatureId, setSignatureId] = useState('');

  const handleContinue = () => {
    nsProDevice.stop();
    history.replace({
      pathname: `/medic/session/${sessionUUID}`,
      state: {
        update: true,
      },
    });
  };

  const updateData = useCallback(async () => {
      const {arm, patient, session, vaccine } = history.location.state;
      const sigId = [
        arm[0].toUpperCase(),
        patient.id.split('-')[0],
        session.clinic.id.split('-')[0],
        session.medication_type.id.split('-')[0],
        vaccine.id,
      ].join('-');

      setDate(getDate(vaccine.timestamp, 'Do MMMM YYYY'));
      setPatient(patient);
      setClinic(session.clinic);
      setMedicationType(session.medication_type);
      setSignatureId(sigId);
  }, [history]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return (
    <MedicLayout title="DVS - Digital Signature">
      <div className={classes.root}>
        <div autoComplete="off" className={classes.form}>
          <TextField label="Patient name" value={patient ? patient.full_name : ''} disabled />
          <TextField label="Date" value={date} disabled />
          <TextField label="City" value={clinic ? clinic.address_city : ''} disabled />
          <TextField label="Clinic" value={clinic ? clinic.name : ''} disabled />
          <TextField label="Vaccien type" value={medicationType ? medicationType.name : ''} disabled />
          <TextField label="Unique signature" value={signatureId} disabled />
        </div>
      </div>
      <ButtonContainer>
        <Button data-qa="finish-button" variant="contained" color="primary" onClick={handleContinue}>Finish</Button>
      </ButtonContainer>
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(DvsSignature));
