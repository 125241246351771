import { WithStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

import {
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  file: string;
  size: number;
}

const LottieAnimation = ({ classes, file, size }: Props): ReactElement => {
  const filePath = `/images/lottie/${file}.gif`;

  const style = {
    width: size ? `${size}rem` : '20rem',
    height: size ? `${size}rem` : '20rem',
  };

  return (
    <div className={classes.root}>
      <img src={filePath} alt="" style={style} />
    </div>
  );
};

export default withStyles(styles)(LottieAnimation);
