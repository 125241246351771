import { WithStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useState } from 'react';

import {
  IconButton,
  Input,
  InputAdornment,
  withStyles,
} from '@material-ui/core';

import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClear: () => void;
  placeholder: string;
}

const SearchInput = ({ classes, onChange, onClear, placeholder }: Props) => {
  const [query, setQuery] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @todo: should it be target.value.value?
    const { value } = e.target.value as any;
    setQuery(value);
    onChange(e);
  };

  const handleClear = () => {
    setQuery('');
    onClear();
  };

  return (
    <div className={classes.root}>
      <SearchIcon className={classes.icon} />
      <Input
        value={query}
        placeholder={placeholder}
        className={classes.input}
        onChange={handleChange}
        endAdornment={<InputAdornment position="end"><IconButton size="small" onClick={handleClear}><ClearIcon /></IconButton></InputAdornment>} />
    </div>
  );
};
export default withStyles(styles)(SearchInput);
