const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(4),
  },
  profile: {
    marginRight: theme.spacing(4),
    alignSelf: 'flex-start',
  },
  avatarContainer: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photo: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    alignSelf: 'center',
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabContent: {
    display: 'flex',
    flex: '1 1 auto',
  },
});

export default styles;
