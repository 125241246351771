import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { 
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select, 
  withStyles, 
  WithWidth, 
 } from '@material-ui/core';

import Grid, { GridSize} from '@material-ui/core/Grid';

import { WithStyles } from '@material-ui/core/styles';

import moment from 'moment';

import styles from './styles';


interface Props extends WithStyles<typeof styles>, WithWidth {
  date: Date;
  label: string | null;
  size: number;
  yearsFromNow: number;
  handleDateChange: (newDate: Date) => void;
}

const today = new Date();
const createYearArray = (years: number) => {
  if (years > 0) {
    return Array.from(new Array(years), (item, index) => index + today.getFullYear());
  }
  return Array.from(new Array((Math.abs(years))), (item, index) => today.getFullYear() - index);
};

const DatePicker = ({ classes, date = new Date(), handleDateChange, label = null, size = 12, yearsFromNow, width }: Props): ReactElement => {
  const [day, setDay] = useState<number>(date.getDate());
  const [month, setMonth] = useState<number>(date.getMonth());
  const [year, setYear] = useState<number>(date.getFullYear());
  const pastDate = !(yearsFromNow > 0); // if greater than 0 it's a future date
  const [isError, setIsError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<boolean | string>(false);

  // listen for a date change
  const dateUpdate = useCallback(() => {
    setDay(date.getDate());
    setMonth(date.getMonth());
    setYear(date.getFullYear());
  }, [date, setDay, setMonth, setYear]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dateUpdate();
    }
    return () => { mounted = false; };
  }, [dateUpdate]);

  const days = Array(31).fill(1).map((i, index) => i + index);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const years = createYearArray(yearsFromNow);

  const handleDayChange = (e: React.ChangeEvent<any>) => {
    setDay(e.target.value);
  };

  const handleMonthChange = (e: React.ChangeEvent<any>) => {
    setMonth(e.target.value);
  };

  const handleYearChange = (e: React.ChangeEvent<any>) => {
    setYear(e.target.value);
  };

  const validateDate = useCallback(() => {
    setIsError(false);
    setErrorText(false);
    const mnth = `${month + 1}.padStart(2, '0')`;
    const dteTest = moment(`${year}/${mnth}/${day}`, 'YYYY/MM/DD'); // date.parse wasn't working here.

    if (dteTest.isValid()) {
      if (pastDate && moment().isBefore(dteTest)) {
        setIsError(true);
        setErrorText('Date needs to be in the past.');
      } else if (!pastDate && moment().isAfter(dteTest) && (today.getDate() !== date.getDate())) {
        setIsError(true);
        setErrorText('Date needs to be in the future.');
      } else {
        handleDateChange(new Date(year, month, day));
      }
    } else {
      setIsError(true);
      setErrorText('Invalid date');
    }
  }, [day, month, year, date, pastDate, handleDateChange]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      validateDate();
    }
    return () => { mounted = false; };
  }, [validateDate]);

  const monthSize = Math.floor(size * 0.5);
  const yearSize = Math.floor(size * 0.3);

  let containerWidth: GridSize = size as any;
  let dayWidth: GridSize = size - (monthSize + yearSize) as any;
  let monthWidth: GridSize = monthSize as any;
  let yearWidth: GridSize = yearSize as any;

  return (
    <Grid container xs={12} spacing={2} className={classes.root}>
      <>
        {label && <Grid item xs={12}><InputLabel id="day-select-label">{label}</InputLabel></Grid>}
        <Grid container spacing={2} item xs={containerWidth} className={classes.datePicker}>
          <Grid item xs={dayWidth}>
            <FormControl className={classes.selectInput} error={isError}>
              <InputLabel id="day-select-label">Day</InputLabel>
              <Select
                labelId="day-select-label"
                autoWidth
                value={day}
                onChange={handleDayChange}>
                {days.map((i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={monthWidth}>
            <FormControl className={classes.selectInput} error={isError}>
              <InputLabel id="month-select-label">Month</InputLabel>
              <Select
                labelId="month-select-label"
                autoWidth
                value={month}
                onChange={handleMonthChange}>
                {months.map((i, index) => <MenuItem key={i} value={index}>{i}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={yearWidth}>
            <FormControl className={classes.selectInput} error={isError}>
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                autoWidth
                value={year}
                onChange={handleYearChange}>
                {years.map((i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {isError && (
          <Grid item xs={12}>
            <FormHelperText error>{errorText}</FormHelperText>
          </Grid>
        )}
      </>
    </Grid>
  );
};

export default withStyles(styles)(DatePicker);
