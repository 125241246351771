import React, { useContext, useEffect, useState } from 'react';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import { AuthContext } from '../../../components/AuthProvider';
import Dashboard from '../../../layouts/Dashboard';

import {
  AdminsTable,
  CreateAdminDialog,
} from './components';

import styles from './styles';

const Admins = ({ classes }) => {
  const { user } = useContext(AuthContext);
  const { client_id: clientUUID } = user ? user : {};

  const [admins, setAdmins] = useState([]);
  const [isCreateAdminDialogOpen, setIsCreateAdminDialogOpen] = useState(false);

  const where = {client_id: { _eq: rawString(clientUUID) }};
  const q = {
    client_admins: params(
      {where},
      [{
        id: types.string,
        full_name: types.string,
        email: types.string,
      }],
    ),
  };

  const { data, refetch } = useQuery(gql`${query(q)}`, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setAdmins(data.client_admins);
    }
    return () => { mounted = false; };
  }, [data]);

  const handleOpenCreateAdmin = () => {
    setIsCreateAdminDialogOpen(true);
  };

  const handleCloseCreateAdmin = () => {
    setIsCreateAdminDialogOpen(false);
  };

  const handleCreateAdmin = () => {
    refetch();
    handleCloseCreateAdmin();
  };

  return (
    <>
      <Dashboard title="Admins">
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <Typography className={classes.tableTitle} variant="h2">Admins</Typography>
            <Button data-qa="create-admin-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenCreateAdmin()}>Create Admin</Button>
          </div>
          <AdminsTable data={admins} />
        </div>
      </Dashboard>
      {isCreateAdminDialogOpen && <CreateAdminDialog handleClose={handleCloseCreateAdmin} handleCreate={handleCreateAdmin} />}
    </>
  );
};

export default withStyles(styles)(Admins);
