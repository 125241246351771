export const capitalise = (value: String) => {
  const words = value.toLowerCase().split(' ');
  return words.map((i) => `${i[0].toUpperCase()}${i.slice(1)}`).join(' ');
};

export const camelToCapital = (value: String) => {
  const words = value.replace(/([A-Z])/g, ',$1').replace(/^,/, '').split(',');
  return words.map((i) => `${i[0].toUpperCase()}${i.slice(1)}`).join(' ');
};

export const camelToSpaces = (value: String) => {
  return value.replace(/([A-Z])/g, ',$1').replace(/^,/, '').split(',').join(' ');
};
