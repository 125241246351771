import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectInput: {
    width: '90%',
  },
});

export default styles;
