import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  progress: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progressLabel: {
    marginTop: theme.spacing(1),
  },
  notRunning: {
    color: theme.palette.error.main,
  },
  signOut: {
    alignSelf: 'flex-start',
  },
});

export default styles;
