import { WithStyles } from '@material-ui/core/styles';
import React, { useContext, PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  Typography,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../components/StateProvider';
import { EmptyObject } from '../../react-app-env';
import NsProConnection from './components/NsProConnection';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps, PropsWithChildren<EmptyObject> {
  title: string;
}

const MedicLayout = ({ classes, title, children }: Props) => {
  const { nsProConnected, nsServerRunning } = useContext(AppContext)!;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="h2">{title}</Typography>
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </div>
      {(!nsProConnected || !nsServerRunning) && <NsProConnection />}
    </>
  );
};

export default withStyles(styles)(MedicLayout);
