import React from 'react';

import { AppBar, Dialog, DialogContent, IconButton, Toolbar, withStyles } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ConsentDialog = ({ classes, handleCloseConsent }) => (
  <Dialog data-qa="consent-dialog" fullScreen open TransitionComponent={Transition}>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleCloseConsent} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <DialogContent>
      {/* <div>
        <h2>NeedleSmart Application consent statement</h2>
        <p>You have been invited to book an appointment at a NeedleSmart testing event for a COVID-19 [antibody / antigen] test.  The testing event will be operated by NeedleSmart, and the test itself will be administered by CORE, who are a regulated medical services provider.
          Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data.  This information is provided in [link to App Privacy Notice] and it is important that you read that information.
        </p>
        <p>Before proceeding, please indicate your consent to:</p>
        <ul className={classes.list}>
          <li>the collection and storage of the personal data that is requested from you during the process to book your appointment at a NeedleSmart testing event;</li>
          <li>having a COVID-19 [antibody / antigen] test conducted at your appointment at the NeedleSmart testing event;</li>
          <li>CORE contacting you at a later date with the result of your test and to provide you with additional information about their services and clinical research into COVID-19; and</li>
          <li>us sharing the result of your test with the organisation that referred you to us. </li>
        </ul>
        <p><strong>YES</strong>I consent the use of my data as described above.</p>
        <p><strong>NO</strong>I do not consent to the use of my data as described above.</p>
        <p>Please note that if you do not wish to provide us with this consent, we will be unable to process your booking or to conduct your COVID-19 [antibody / antigen] test.</p>
        <p>If you give us your consent but then change your mind, you can withdraw your consent at any time by contacting us at stefaan@testassurancegroup.com but that will not affect the lawfulness of any processing carried out before you withdraw your consent.</p>
      </div> */}
      <div>
        DVS APP CONSENT STATEMENT
        You have been invited to book an appointment at a DVS vaccination event. The testing event will be operated by your health care provider using technology designed and built by NeedleSmart.
        Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data. This information is provided in App Privacy Notice and it is important that you read that information.
        Before proceeding, please indicate your consent to:
         the collection and storage of the personal data that is requested from you during the process to book your appointment at a DVS vaccination event;
         Recording the minimum relevant data pertaining to your vaccination
         Us sharing the result of your test with the organisation that referred you to us.
        YES I consent the use of my data as described above.
        NO I do not consent to the use of my data as described above.
        Please note that if you do not wish to provide us with this consent, we will be unable to process your vaccination
        If you give us your consent but then change your mind, you can withdraw your consent at any time by contacting your Health Care Provider but that will not affect the lawfulness of any processing carried out before you withdraw your consent.
      </div>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(ConsentDialog);
