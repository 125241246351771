import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Fade ,withStyles } from '@material-ui/core';

import { NsLogo } from '../../../components/Logos';

import styles from './styles';

const Splash = ({ classes, history }) => {
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        history.replace('/sign-in');
      }, 5000);
    }
    return () => { mounted = false; };
  }, [history]);

  return (
    <div className={classes.root}>
      <Fade in timeout={2700}>
        <div className={classes.icon}>
          <NsLogo />
        </div>
      </Fade>
    </div>
  );
};

export default withRouter(withStyles(styles)(Splash));
