import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { CenteredLayout, PatientLayout } from '../../../layouts';
import { AuthContext } from '../../../components/AuthProvider';
import ButtonContainer from '../../../components/ButtonContainer';
import BodyText from '../../../components/BodyText';
import ProgressIndicator from '../../../components/ProgressIndicator';
import NsLogo from '../../../components/Logos/NsLogo';
import { validatePassword, validateEmailAddress } from '../../../utils/validators';

import styles from './styles';

const SignIn = ({ classes, history }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const { loggedInUser, signIn, userRoles, setLoggedInUser } = useContext(AuthContext);

  const { invite: patientInvite, email } =  history.location.state ? history.location.state : {};
  const [emailAddress, setEmailAddress] = useState(email ? email : '');
  const [invite] = useState(patientInvite ? patientInvite : null);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [signingIn, setSigningIn] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted && userRoles.length > 0 && loggedInUser) {
      if (userRoles.includes('patient')) {
        return history.push({
          pathname: `/patient/home`,
          state: {
            invite,
          }
        });
      }
      history.push(`/${userRoles[0]}/dashboard`);
    }
    return () => { mounted = false; };
  }, [history, invite, loggedInUser, userRoles]);

  const handleSignIn = () => {
    let error = false;
    setErrors({});
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!password) {
      setErrors((e) => ({ ...e, password: 'Please provide a password' }));
      error = true;
    } else if (!validatePassword(password)) {
      setErrors((e) => ({ ...e, password: 'Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = useCallback(async () => {
    setSigningIn(true);
    try {
      const signInRequest = await signIn(emailAddress.toLowerCase(), password);
      if (signInRequest) {
        const { challengeName } = signInRequest;
        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
          setLoggedInUser(signInRequest);
          history.push('/initial-staff-sign-in');
        } else if (challengeName === 'NEW_PASSWORD') {
          history.push('/initial-staff-sign-in');
        } else if (challengeName === 'RESET_REQUIRED') {
          history.push('/reset-password');
        } else {
          setSigningIn(false);
        }
      }
    } catch(err) {
      setSigningIn(false);
      if (err.code === 'PasswordResetRequiredException') {
        setResetPassword(true);
      } 
      setErrors((e) => ({ ...e, signIn: err.message }));
    }
  }, [history, emailAddress, password, setLoggedInUser, signIn]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleContinue();
    }
  };

  const handleResetPassword = () => {
    history.push(`/reset-password?e=${btoa(emailAddress)}`);
  };

  const handleForgotPassword = () => {
    setForgetPassword(true);
  };

  const handleResetPasswordSubmit = async () => {
    let error = false;
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!error) {
      setSigningIn(true);
      try {
        await Auth.forgotPassword(emailAddress);
        setSigningIn(false);
        setTimeout(() => {
          history.push('/reset-password');
        }, 1500);
      } catch (err) {
        setSigningIn(false);
        setErrors((e) => ({ ...e, signIn: err.message || 'Unknown error' }));
      }
    }
  };

  const getContent = () => (
    <>
      <div autoComplete="off" className={classes.form}>
        <TextField data-qa="email-textfield" required placeholder="Email address" label="Email address" type="email" error={errors.emailAddress} helperText={errors.emailAddress} value={emailAddress} onChange={(event) => setEmailAddress(event.target.value || null)} />
        {!forgetPassword && !resetPassword && (
          <>
            <TextField data-qa="password-textfield" placeholder="Password" className={classes.password} required label="Password" type="password" error={errors.password} helperText={errors.password} value={password} onKeyDown={handleKeyPress} onChange={(event) => setPassword(event.target.value || null)} />
            <Button data-qa="forgotPassword-button" className={classes.forgotPassowrd} variant="text" color="primary" onClick={handleForgotPassword}>Forgot password</Button>
          </>
        )}
      </div>
      {errors.signIn && (
        <BodyText align="center">{errors.signIn}</BodyText>
      )}
      {forgetPassword && !emailAddress && (
        <BodyText align="center">Please provide email address</BodyText>
      )}
      <ButtonContainer>
        {!signingIn && !resetPassword && !forgetPassword && <Button data-qa="signin-button" variant="contained" color="primary" onClick={handleSignIn}>SIGN IN</Button>}
        {!signingIn && forgetPassword && !resetPassword && <Button data-qa="request-new-password" variant="contained" color="primary" onClick={handleResetPasswordSubmit}>Request New Password</Button>}
        {!signingIn && resetPassword && <Button data-qa="reset-password-button" variant="contained" color="primary" onClick={handleResetPassword}>RESET PASSWORD</Button>}
        {signingIn && forgetPassword && <ProgressIndicator label="Sending validation email" />}
        {signingIn && !forgetPassword && <ProgressIndicator label="Signing in" />}
      </ButtonContainer>
    </>
  );

  if (isMobile) {
    return (
      <PatientLayout noToolbar>
        <Typography className={classes.title} variant="h3">Sign in</Typography>
        {getContent()}
      </PatientLayout>
    );
  }

  return (
    <CenteredLayout id="some">
      <div className={classes.modal}>
        <div className={classes.logo}>
          <NsLogo />
        </div>
        <div className={classes.content}>
          {getContent()}
        </div>
      </div>
    </CenteredLayout>
  );
};

export default withStyles(styles)(SignIn);
