import { Button, CircularProgress, Paper, Typography, withStyles, WithWidth, withWidth } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, WithWidth {
  value: number;
  label: string;
  subLabel: string;
  action: {
    label: string;
    to: string;
  };
}

const DataCard = ({ classes, value, label, subLabel, action, width }: Props): ReactElement => {
  // @todo: replace with useMediaQuery
  const [isMobile] = useState(['xs', 'sm', 'md'].includes(width));

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="body1">{label}</Typography>
      {/*<Typography className={classes.label} variant="body1">{label}</Typography>*/}
      {value || value === 0
        ? <Typography className={classes.title} variant={isMobile ? 'h5' : 'h4'}>{value}</Typography>
        : <div className={classes.circularProgress}><CircularProgress /></div>}
      {subLabel && (
        <Typography variant="body1">{subLabel}</Typography>
        // <Typography className={classes.subLabel} variant="body1">{subLabel}</Typography>
      )}
      {action && (
        <Button to={action.to} component={NavLink} color="primary">{action.label}</Button>
      )}
      <div className={classes.border} />
    </Paper>
  );
};

export default withStyles(styles)(withWidth()(DataCard));
