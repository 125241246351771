import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useMutation, gql } from '@apollo/client';

import {
  CircularProgress,
  Button,
  TextField,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import ButtonContainer from '../../../../components/ButtonContainer';

import styles from './styles';

const SignUpDetailsAddress = ({ classes, history }) => {
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [errors, setErrors] = useState({});
  
  const m = gql`
    mutation updatePatient($pk_columns: patients_pk_columns_input!, $set: patients_set_input!) {
      update_patients_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
      }
    }
  `;

  const [updatePatient, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      history.push({
        pathname: '/patient/ns-id/continue/sign-up-finish',
        state: {
          ...history.location.state,
        },
      });
    }
    return () => { mounted = false; };
  }, [history, data]);
  
  const handleContinue = useCallback(() => {
    const { patient } = history.location.state;
    updatePatient({
      variables: {
        pk_columns: {
          id: patient.id,
        },
        set: {
         address_line_1: address1,
         address_city: city,
         address_postcode: postcode,
        },
      }
    });
  }, [history, address1, city, postcode, updatePatient]);

  const handleSignUp = () => {
    setErrors({});
    let error = false;

    if (!address1) {
      setErrors((e) => ({ ...e, address1: 'Please provide an address' }));
      error = true;
    }
    if (!postcode) {
      setErrors((e) => ({ ...e, postcode: 'Please provide a postcode' }));
      error = true;
    }
    if (!city) {
      setErrors((e) => ({ ...e, city: 'Please provide a city' }));
      error = true;
    }

    if (!error) {
      handleContinue();
    }
  };

  return (
    <PatientLayout toolbarTitle="Your address">
      <div className={classes.form} noValidate autoComplete="off">
        <TextField
          required
          label="Address line 1"
          error={errors.address1}
          helperText={errors.address1}
          placeholder="123 Street Name"
          value={address1}
          onChange={(event) => setAddress1(event.target.value || '')} />
        <TextField
          required
          label="City"
          error={errors.city}
          helperText={errors.city}
          value={city}
          onChange={(event) => setCity(event.target.value || '')} />
        <TextField
          required
          label="Postcode"
          error={errors.postcode}
          helperText={errors.postcode}
          value={postcode}
          onChange={(event) => setPostcode(event.target.value || '')} />
      </div>
      <ButtonContainer>        
        {!loading && (
          <Button data-qa="confirm-button" variant="contained" color="primary" onClick={handleSignUp}>CONFIRM</Button>
        )}
        {loading && (
          <CircularProgress />
        )}
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpDetailsAddress));
