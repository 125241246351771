import React, { PropsWithChildren, ReactElement } from 'react';

import {
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { EmptyObject } from '../../react-app-env';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, PropsWithChildren<EmptyObject> {}

const ButtonContainer = ({ classes, children }: Props): ReactElement => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(ButtonContainer);
