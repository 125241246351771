const styles = (theme) => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '40%',
    minWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  imageContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
  },
  image: {
    width: '80%',
    '& > svg': {
      height: '100%',
    },
  },
  scanIdField: {
    width: 300,
  },
});

export default styles;
