const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  terms: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

export default styles;
