import React from 'react';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';

import styles from './styles';

const Overview = ({ classes }) => (
  <Dashboard title="Dashboard" />
);

export default withStyles(styles)(Overview);
