import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: theme.spacing(20),
    marginRight: theme.spacing(4),
    borderRadius: 0,
    // bordeBrottomWidth: 3,
    // borderBottomStyle: 'solid',
    // borderBottomColor: theme.palette.primary.main,
    '& > *': {
      textAlign: 'center',
      width: '100%',
    },
    '&:last-of-type': {
      marginRight: 0,
    },
    '& > a': {
      fontSize: '12px',
      textDecoration: 'none',
    },
  },
  circularProgress: {
    width: 'auto',
    justifySelf: 'center',
    padding: theme.spacing(1),
  },
  title: {
    flex: '1 1 auto',
  },
  jumbo: {
    fontWeight: 700,
    fontSize: '5rem',
  },
  border: {
    width: '70%',
    alignSelf: 'center',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
});

export default styles;
