import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import { AuthContext } from '../../../../components/AuthProvider';
import ButtonContainer from '../../../../components/ButtonContainer';
import ProgressIndicator from '../../../../components/ProgressIndicator';

import styles from './styles';

const SignUpConfirm = ({ history, classes }) => {
  const { loggedInUser, signIn, resendSignUp } = useContext(AuthContext);
  const { patient } = history.location.state;
  const [emailAddress] = useState(patient ? patient.emailAddress : '');
  const [errors, setErrors] = useState({});
  const [confirming, setConfirming] = useState(false);
  
  const m = gql`
    mutation confirmPatient($pk_columns: patients_pk_columns_input!, $set: patients_set_input!) {
      update_patients_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
      }
    }
  `;

  const [confirmPatient, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      history.push({
        pathname: '/patient/sign-up-details-personal',
        state: {
          ...history.location.state,
          patient: {
            ...history.location.state.patient,
            password: null,
          }
        }
      });
    }
    return () => { mounted = false; };
  }, [data, history]);

  useEffect(() => {
    let mounted = true;
    if (mounted && history && loggedInUser) {
      const { patient } = history.location.state;
      confirmPatient({
        variables: {
          pk_columns: {
            id: patient.id,
          },
          set: {
            confirmed: true,
          },
        },
      });
    }
    return () => { mounted = false; };
  }, [history, loggedInUser, confirmPatient]);

  const handleReSend = async () => {
    const { patient } = history.location.state;
    await resendSignUp(patient.emailAddress);
  };

  const handleContinue = async () => {
    setConfirming(true);
    setErrors((e) => ({ ...e, confirmSignUpRequest: false }));
    const { patient } = history.location.state;

    try {
      await signIn(patient.emailAddress, atob(patient.password));
    } catch(err) {
      setConfirming(false);
      setErrors((e) => ({ ...e, confirmSignUpRequest: err.message }));
    }
  };

  return (
    <PatientLayout title="Confirm your NeedleSmart account" noToolbar>
      <Typography>{`We have sent an email to ${emailAddress} with a verification link. Please open the link then come back here and tap confirmed`}</Typography>

      {!confirming && !loading && (
        <ButtonContainer>
          {errors.confirmSignUpRequest && (
            <Typography className={classes.responseError} align="center">{errors.confirmSignUpRequest}</Typography>
          )}
          <Button data-qa="resend-button" variant="outlined" color="primary" onClick={handleReSend}>RE-SEND LINK</Button>
          <Button data-qa="confirm-button" variant="contained" color="primary" onClick={handleContinue}>I HAVE CONFIRMED</Button>
        </ButtonContainer>
      )}
      {(confirming || loading) && (
        <ButtonContainer>
          <ProgressIndicator label="Confirming your account" />
        </ButtonContainer>
      )}
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpConfirm));
