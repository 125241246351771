import React, { useState, useEffect, useCallback } from 'react';

import {
  IconButton,
  Select,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  withStyles,
} from '@material-ui/core';

import {
  HighlightOff as DeleteIcon,
} from '@material-ui/icons';

import styles from './styles';

const MedicationTypeQualificationQuestion = ({ classes, questionIndex = 0, questionKey, questionQuestion = '', questionOnInvite = false, handleRemoveQuestion, handleUpdateQuestion }) => {
  const [key] = useState(questionKey);
  const [question, setQuestion] = useState(questionQuestion);
  const [onInvite, setOnInvite] = useState(questionOnInvite);
  const [errors, setErrors] = useState({});

  const checkErrors = useCallback(() => {
    setErrors({});
    if (!question) {
      setErrors((e) => ({ ...e, question: 'Please provide a question' }));
    }
  }, [question]);

  const handleChangeQuestion = (event) => {
    const { value } = event.target;
    handleUpdateQuestion({
      key,
      question: value,
      onInvite,
    });
    setQuestion(value || '');
    checkErrors();
  };

  const handleChangeOnInvite = (event) => {
    const { value } = event.target;
    const newValue = value === 'YES';
    handleUpdateQuestion({
      key,
      question,
      onInvite: newValue,
    });
    setOnInvite(newValue);
    checkErrors();
  };

  const handleDeleteQuestion = () => {
    handleRemoveQuestion(key);
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        checkErrors();
      }
    }, 100);
    return () => { mounted = false; };
  }, [checkErrors]);

  return (
    <div className={classes.root}>
      <div className={classes.dots}><p /></div>
      <TextField
        className={classes.question}
        label="Question"
        name="question"
        error={errors.question}
        helperText={errors.question}
        value={question || ''}
        autoComplete="off"
        variant="outlined"
        multiline
        onChange={handleChangeQuestion} />
      <FormControl variant="outlined" className={classes.onInvite}>
        <InputLabel id="medicationTypeQualification-onInvite-select">On invite?</InputLabel>
        <Select
          className={classes.onInvite}
          label="On invite?"
          labelId="medicationTypeQualification-onInvite-select"
          value={onInvite ? 'YES' : 'NO'}
          onChange={handleChangeOnInvite}
          error={errors.onInvite}
          inputProps={{
            name: 'medicationTypeQualification-onInvite-select',
            id: 'medicationTypeQualification-onInvite-select',
          }}>
          <MenuItem value="YES">Yes</MenuItem>
          <MenuItem value="NO">No</MenuItem>
        </Select>
        <FormHelperText>{errors.onInvite}</FormHelperText>
      </FormControl>
      <div className={classes.deleteButton}>
        {(questionIndex > 0) && (
          <IconButton size="small" onClick={() => handleDeleteQuestion(key)} title="Delete">
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(MedicationTypeQualificationQuestion);
