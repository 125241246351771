import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import Dashboard from '../../../layouts/Dashboard';
import { AuthContext } from '../../../components/AuthProvider';
import TableTitle from '../../../components/TableTitle';

import {
  MedicationTypesTable,
  CreateMedicationTypeDialog,
} from './components';

import styles from './styles';

const MedicationTypes = ({ history, classes }) => {
  const { user } = useContext(AuthContext);
  const { client_id: clientUUID } = user ? user : {};

  const [medicationTypes, setMedicationTypes] = useState([]);
  const [treatmentTypes, setTreatmentTypes] = useState([]);

  const where = {client_id: { _eq: rawString(clientUUID) }};
  const q = {
    medication_types: params(
      {where},
      [{
        id: types.string,
        name: types.string,
        treatment_type: {
          id: types.string,
          name: types.string,
        },
      }],
    ),
    treatment_types: {
      id: types.string,
      name: types.string,
    },
  };

  const { data, refetch } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setMedicationTypes(data.medication_types);
      setTreatmentTypes(data.treatment_types);
    }
    return () => { mounted = false; };
  }, [data]);

  const [isCreateMedicationTypeDialogOpen, setIsCreateMedicationTypeDialogOpen] = useState(false);

  const handleOpenCreateMedicationType = () => {
    setIsCreateMedicationTypeDialogOpen(true);
  };

  const handleCloseCreateMedicationType = () => {
    setIsCreateMedicationTypeDialogOpen(false);
  };

  const handleCreateMedicationType = (medicationTypeId) => {
    refetch();
    handleCloseCreateMedicationType();
    history.push(`/client/medication-types/${medicationTypeId}`);
  }

  return (
    <>
      <Dashboard title="Medication types">
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="" />
            <Button data-qa="create-medication-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateMedicationType}>Create medication type</Button>
          </div>
          <MedicationTypesTable data={medicationTypes} />
        </div>
      </Dashboard>
      {isCreateMedicationTypeDialogOpen && (
        <CreateMedicationTypeDialog clientUUID={clientUUID} treatmentTypes={treatmentTypes} handleClose={handleCloseCreateMedicationType} handleCreate={handleCreateMedicationType} />
      )}
    </>
  );
};

export default withRouter(withStyles(styles)(MedicationTypes));
