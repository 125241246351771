const styles = (theme) => ({
  table: {
    padding: theme.spacing(4),
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    '& > button': {
      height: theme.spacing(5),
    },
  },
  actions: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: theme.spacing(2),
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
});

export default styles;
