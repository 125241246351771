const styles = (theme) => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '40%',
    minWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  imageContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    outline: 0,
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  images: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: '40%',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    filter: 'grayscale(100%)',
    cursor: 'pointer',
    outline: 0,
    '&:hover': {
      filter: 'none',
    },
  },
  selectedArm: {
    width: '40%',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    cursor: 'pointer',
    outline: 0,
  },
  scannID: {
    opacity: 0,
  },
});

export default styles;
