import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ArrowRightAlt as ArrowRightIcon,
} from '@material-ui/icons';


import { AuthContext } from '../../../components/AuthProvider';
import Dashboard from '../../../layouts/Dashboard';


import styles from './styles';
import { useCallback } from 'react';

const timestampString = new Date().toISOString();

const Session = ({ classes, history }) => {
  const { user } = useContext(AuthContext);

  const [sessions, setSessions] = useState([]);

  const handleSession = useCallback((id) => {
    history.push(`/medic/session/${id}`);
  }, [history])

  const whereSessions = {
    start_date: { _lte: rawString(timestampString) },
    end_date: { _gt: rawString(timestampString),
  }};

  const q = {
    client_staff_by_pk: params(
      { id: rawString(user.id)},
      {
        team_staff_assignments: params(
          {where: {team: {sessions: whereSessions}}},
          {
            team: {
              sessions: params(
                {where: whereSessions},
                [{
                  id: types.string,
                  name: types.string,
                  start_date: types.string,
                  end_date: types.string,
                  treatment_type: {
                    name: types.string,
                  },
                  medication_type: {
                    name: types.string,
                  },
                }]
              )
            }
          }
        )
      }
    )
  };

  const { data, loading } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const staff = data.client_staff_by_pk;
      if (staff) {
        const ses = staff.team_staff_assignments.flatMap(i => i.team.sessions).sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        if (ses.length > 1) {
          setSessions(ses);
        } else if (ses.length === 1) {
          handleSession(ses[0].id);
        }
      }
    }
    return () => { mounted = false; };
  }, [data, handleSession]);

  const getDate = (date) => moment(new Date(date)).format('dddd Do MMMM YYYY');

  return (
    <>
      <Dashboard title="Select session">
        {loading && sessions.length === 0 && (
          <CircularProgress />
        )}
        {!loading && sessions.length === 0 && (
          <Typography variant="h2">No sessions avaliable</Typography>
        )}
        {sessions.length > 0 && (
          <Grid container spacing={4}>
            {sessions.map((session) => {
              return (
                <Grid item xs={5}>
                  <Paper className={classes.session}>
                      <Typography className={classes.title} variant="h5">{session.name}</Typography>
                      <Divider className={classes.divider} />
                      <div className={classes.dateContainer}>
                        <Typography variant="subtitle1" className={classes.date}>{getDate(session.start_date)}</Typography>
                        <ArrowRightIcon />
                        <Typography variant="subtitle1" className={classes.date}>{getDate(session.end_date)}</Typography>
                      </div>
                      <Divider className={classes.divider} />
                      <div className={classes.medicationContainer}>
                        <Typography variant="subtitle1" className={classes.treatment_type}>{session.treatment_type.name}</Typography>
                        <ArrowRightIcon />
                        <Typography variant="subtitle1" className={classes.medication_type}>{session.medication_type.name}</Typography>
                      </div>
                      <Divider className={classes.divider} />
                      <div className={classes.actions}>
                        <Button variant="outlined" onClick={() => handleSession(session.id)}>View</Button>
                      </div>
                  </Paper>
                </Grid>)
            })}
          </Grid>
        )}
      </Dashboard>
    </>
  );
};

export default withStyles(styles)(Session);
