import React, { useContext, useEffect, useState } from 'react';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import { AuthContext } from '../../../../../components/AuthProvider';

import {
  StaffMembersTable,
  CreateStaffMemberDialog,
} from './components';

import styles from './styles';

const StaffMembers = ({ classes }) => {

  const { user } = useContext(AuthContext);
  const { client_id: clientUUID, clinic_id: clinicUUID } = user ? user : {};

  const [leadMedics, setLeadMedics] = useState([]);
  const [medics, setMedics] = useState([]);
  const [createStaffType, setCreateStaffType] = useState(null);
  const [isCreateStaffMemberDialogOpen, setIsCreateStaffMemberDialogOpen] = useState(false);

  const where = {clinic_id: { _eq: rawString(clinicUUID) }};
  const q = {
    client_staff: params(
      {where},
      {
        id: types.string,
        full_name: types.string,
        email: types.string,
        staff_type: types.string,
      },
    ),
  };

  const { data, refetch } = useQuery(gql`${query(q)}`, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const staff = data.client_staff;
      setLeadMedics(staff.filter(i => i.staff_type === 'MANAGER'));
      setMedics(staff.filter(i => i.staff_type === 'MEDIC'));
    }
    return () => { mounted = false; };
  }, [data]);

  const handleOpenCreateStaffMember = (type) => {
    setCreateStaffType(type);
    setIsCreateStaffMemberDialogOpen(true);
  };

  const handleCloseCreateStaffMember = () => {
    setCreateStaffType(null);
    setIsCreateStaffMemberDialogOpen(false);
  };

  const handleCreateStaffMember = () => {
    refetch();
    handleCloseCreateStaffMember(false);
  };

  return (
    <>
      <div className={classes.table}>
        <div className={classes.tableHeader}>
          <Typography className={classes.tableTitle} variant="h2">Lead Medics</Typography>
          <Button data-qa="create-lead-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenCreateStaffMember('MANAGER')}>Create Lead Medic</Button>
        </div>
        <StaffMembersTable data={leadMedics} />
      </div>
      <div className={classes.table}>
        <div className={classes.tableHeader}>
          <Typography className={classes.tableTitle} variant="h2">Medics</Typography>
          <Button data-qa="create-medic-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenCreateStaffMember('MEDIC')}>Create Medic</Button>
        </div>
        <StaffMembersTable data={medics} />
      </div>
      {isCreateStaffMemberDialogOpen && <CreateStaffMemberDialog staffType={createStaffType} clientUUID={clientUUID} clinicUUID={clinicUUID} handleClose={handleCloseCreateStaffMember} handleCreate={handleCreateStaffMember} />}
    </>
  );
};

export default withStyles(styles)(StaffMembers);
