import React, { ReactElement } from 'react';

const QrCodeIcon = (): ReactElement => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 226 226">
    <g fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDashoffset="0">
      <path d="M37.66667,28.25c-5.198,0 -9.41667,4.21867 -9.41667,9.41667v37.66667c0,5.198 4.21867,9.41667 9.41667,9.41667h37.66667c5.198,0 9.41667,-4.21867 9.41667,-9.41667v-37.66667c0,-5.198 -4.21867,-9.41667 -9.41667,-9.41667zM103.58333,28.25v18.83333h18.83333v-18.83333zM150.66667,28.25c-5.198,0 -9.41667,4.21867 -9.41667,9.41667v37.66667c0,5.198 4.21867,9.41667 9.41667,9.41667h37.66667c5.198,0 9.41667,-4.21867 9.41667,-9.41667v-37.66667c0,-5.198 -4.21867,-9.41667 -9.41667,-9.41667zM47.08333,47.08333h18.83333v18.83333h-18.83333zM160.08333,47.08333h18.83333v18.83333h-18.83333zM103.58333,65.91667v18.83333h18.83333v-18.83333zM28.25,103.58333v18.83333h18.83333v-18.83333zM65.91667,103.58333v18.83333h18.83333v-18.83333zM103.58333,103.58333v18.83333h18.83333v-18.83333zM122.41667,122.41667v18.83333h18.83333v-18.83333zM141.25,122.41667h18.83333v-18.83333h-18.83333zM160.08333,122.41667v18.83333h18.83333v-18.83333zM178.91667,122.41667h18.83333v-18.83333h-18.83333zM178.91667,141.25v18.83333h18.83333v-18.83333zM178.91667,160.08333h-18.83333v18.83333h18.83333zM178.91667,178.91667v18.83333h18.83333v-18.83333zM160.08333,178.91667h-18.83333v18.83333h18.83333zM141.25,178.91667v-18.83333h-18.83333v18.83333zM122.41667,178.91667h-18.83333v18.83333h18.83333zM122.41667,160.08333v-18.83333h-18.83333v18.83333zM141.25,160.08333h18.83333v-18.83333h-18.83333zM37.66667,141.25c-5.198,0 -9.41667,4.21867 -9.41667,9.41667v37.66667c0,5.198 4.21867,9.41667 9.41667,9.41667h37.66667c5.198,0 9.41667,-4.21867 9.41667,-9.41667v-37.66667c0,-5.198 -4.21867,-9.41667 -9.41667,-9.41667zM47.08333,160.08333h18.83333v18.83333h-18.83333z" />
    </g>
  </svg>
);

export default QrCodeIcon;
