const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  openCamera: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  file: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  photoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  photo: {
    width: '80vw',
    borderRadius: '50%',
    justifySelf: 'center',
  },
});

export default styles;
