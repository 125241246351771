import React, { useContext, useEffect, useState } from 'react';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';


import { AuthContext } from '../../../components/AuthProvider';
import Dashboard from '../../../layouts/Dashboard';


import SessionsCalendar from './components/SessionsCalendar';

import styles from './styles';

const timestampString = new Date().toISOString();

const Sessions = ({ classes, history }) => {
  const { user } = useContext(AuthContext);

  const [sessions, setSessions] = useState([]);

  const whereSessions = {
    end_date: { _gt: rawString(timestampString),
  }};
  const q = {
    client_staff_by_pk: params(
      { id: rawString(user.id) },
      {
        team_staff_assignments: params(
          { where: { team: { sessions: whereSessions } } },
          [{
            team: {
              sessions: params(
                { where: whereSessions },
                [{
                  id: types.string,
                  name: types.string,
                  start_date: types.string,
                  end_date: types.string,
                  clinic: {
                    id: types.string,
                    name: types.string,
                  }
                }]
              )
            }
          }]
        )
      }
    )
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const staff = data.client_staff_by_pk;
      if (staff) {
        const ses = staff.team_staff_assignments.flatMap(i => i.team.sessions);
        setSessions(ses.map((i) => ({
          ...i,
          start_date: new Date(i.start_date),
          end_date: new Date(i.end_date),
        })));
      }
    }
    return () => { mounted = false; };
  }, [data]);

  return (
    <Dashboard title="Upcoming session">
      <SessionsCalendar sessions={sessions} />
    </Dashboard>
  );
};

export default withStyles(styles)(Sessions);
