import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { CenteredLayout, PatientLayout } from '../../../layouts';
import { AuthContext } from '../../../components/AuthProvider';
import ButtonContainer from '../../../components/ButtonContainer';
import BodyText from '../../../components/BodyText';
import ProgressIndicator from '../../../components/ProgressIndicator';
import { validatePassword } from '../../../utils/validators';
import NsLogo from '../../../components/Logos/NsLogo';
import TakePhoto from './components/TakePhoto';

import styles from './styles';

const InitialStaffSignin = ({ classes, history }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const { loggedInUser, setLoggedInUser } = useContext(AuthContext);

  const givenName = loggedInUser && loggedInUser.challengeParam ? loggedInUser.challengeParam.userAttributes.given_name || '' : '';
  const familyName = loggedInUser && loggedInUser.challengeParam ? loggedInUser.challengeParam.userAttributes.family_name || '' : '';

  const [firstName, setFirstName] = useState(givenName);
  const [lastName, setLastName] = useState(familyName);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [miin, setMiin] = useState('');
  const [photo, setPhoto] = useState(null);
  const [errors, setErrors] = useState({});
  const [changingPassword, setChangingPassword] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Name', 'Photo ID', 'Password'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePhoto = (image) => {
    setPhoto(image);
    handleNext();
  };

  const handleSkipPhoto = () => {
    setPhoto(false);
    handleNext();
  };

  const handleUpdateInformation = () => {
    let error = false;
    setErrors({});
    if (activeStep === 0 && !firstName) {
      setErrors((e) => ({ ...e, firstName: 'Please provide a first name' }));
      error = true;
    }
    if (activeStep === 0 && !lastName) {
      setErrors((e) => ({ ...e, lastName: 'Please provide a last name' }));
      error = true;
    }
    if (activeStep === 1 && photo !== false) {
      setErrors((e) => ({ ...e, photo: 'Please provide a photo' }));
      error = true;
    }
    if (activeStep === 2 && !newPassword) {
      setErrors((e) => ({ ...e, newPassword: 'Please provide a password' }));
      error = true;
    } else if (activeStep === 2 && !validatePassword(newPassword)) {
      setErrors((e) => ({ ...e, newPassword: 'Password does not conform to the passowrd criteria' }));
      error = true;
    }
    if (activeStep === 2 && !confirmPassword) {
      setErrors((e) => ({ ...e, confirmPassword: 'Please provide a password' }));
      error = true;
    } else if (activeStep === 2 && confirmPassword !== newPassword) {
      setErrors((e) => ({ ...e, newPassword: 'Passwords do not match', confirmPassword: 'Passwords do not match' }));
      error = true;
    } else if (activeStep === 2 && !validatePassword(confirmPassword)) {
      setErrors((e) => ({ ...e, confirmPassword: 'Password does not conform to the passowrd criteria' }));
      error = true;
    }
    if (!error) {
      if (activeStep < steps.length - 1) {
        handleNext();
      } else {
        handleContinue();
      }
    }
  };

  const handleContinue = async () => {
    setChangingPassword(true);
    const attributes = {
      given_name: firstName,
      family_name: lastName,
    };
    try {
        await Auth.completeNewPassword(loggedInUser, newPassword, attributes);
        setLoggedInUser(null);
        Auth.signOut();
        history.replace('/sign-in');
    } catch (err) {
      setErrors((e) => ({ ...e, changePassword: err.message }));
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div autoComplete="off" className={classes.form}>
            <TextField data-qa="firstName-textfield" required label="First name" name="firstName" error={errors.firstName} helperText={errors.firstName} value={firstName} autoComplete="off" onChange={(event) => setFirstName(event.target.value || null)} />
            <TextField data-qa="lastName-textField" required label="Last name" name="lastName" error={errors.lastName} helperText={errors.lastName} value={lastName} autoComplete="off" onChange={(event) => setLastName(event.target.value || null)} />
            <TextField data-qa="medical-textField" label="Medical Indemnity Insurance Number" name="miin" error={errors.miin} helperText={errors.miin} value={miin} autoComplete="off" onChange={(event) => setMiin(event.target.value || null)} />
          </div>
        );
      case 1:
        return (
          <>
            <TakePhoto handlePhoto={handlePhoto} handleSkipPhoto={handleSkipPhoto} />
            {errors.photo && (<Typography color="textError">{errors.photo}</Typography>)}
          </>
        );
      case 2:
        return (
          <div autoComplete="off" className={classes.form}>
            <Typography className={classes.passwordDetail}>Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number</Typography>
            <TextField required label="New password" name="password" type="password" error={errors.newPassword} helperText={errors.newPassword} autoComplete="off" value={newPassword} onChange={(event) => setNewPassword(event.target.value || null)} />
            <TextField required label="Confirm new password" name="passwordConfirm" type="password" error={errors.confirmPassword} autoComplete="off" helperText={errors.confirmPassword} value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value || null)} />
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  const getContent = () => (
    <>
      <Stepper className={classes.stepper} activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
      {errors.changePassword && (
        <BodyText>{errors.changePassword}</BodyText>
      )}
      {activeStep !== 1 && (
        <ButtonContainer>
          {!changingPassword && <Button data-qa="continue-finish-button" variant="contained" color="primary" onClick={handleUpdateInformation}>{activeStep < steps.length - 1 ? 'CONTINUE' : 'FINISH'}</Button>}
          {changingPassword && <ProgressIndicator />}
        </ButtonContainer>
      )}
    </>
  );

  if (isMobile) {
    return (
      <PatientLayout title="Update staff profile" noToolbar>
        {getContent()}
      </PatientLayout>
    );
  }

  return (
    <CenteredLayout>
      <div className={classes.modal}>
        <div className={classes.logo}>
          <NsLogo />
        </div>
        <Typography className={classes.title} variant="h4">Update staff profile</Typography>
        <div className={classes.content}>
          {getContent()}
        </div>
      </div>
    </CenteredLayout>
  );
};

export default withStyles(styles)(InitialStaffSignin);
