import React, { ReactElement, useEffect, useState, useContext, useCallback } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps, NavLink, withRouter } from 'react-router-dom';

import {
  BusinessOutlined as OrganisationIcon,
  DashboardOutlined as DashboardIcon,
  DnsOutlined as TestsIcon,
  Event as CalendarIcon,
  LocalHospital as MedicationIcon,
  LocationCity as LocationIcon,
  PersonOutlined as StaffIcon,
  SupervisorAccount as AdminsIcon,
} from '@material-ui/icons';

import { AuthContext } from '../../../../components/AuthProvider';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

const Sidebar = ({ classes }: Props): ReactElement => {
  const { loggedInUser, userRoles } = useContext(AuthContext)!;
  const [userGroups, setUserGroups] = useState<string[]>([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setUserGroups(userRoles);
    }
    return () => { mounted = false; };
  }, [userRoles]);

  const getDisplayName = useCallback(() => {
    if (loggedInUser) {
      const { attributes } = loggedInUser;
      return `${attributes.given_name} ${attributes.family_name}`;
    }
    return '';
  }, [loggedInUser]);

  return (
    <nav className={classes.root}>
      <div className={classes.logoWrapper}>
        <img data-qa="ns-logo" alt="NeedleSmart Logo" className={classes.logoImage} src="/images/icons/NS_LOGO_RGB.svg" />
      </div>
      {userGroups.includes('admin') && (
        <List className={classes.list} component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/admin/dashboard">
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Dashboard" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/admin/clients">
            <ListItemIcon className={classes.listItemIcon}>
              <OrganisationIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Clients" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/admin/admins">
            <ListItemIcon className={classes.listItemIcon}>
              <StaffIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Admins" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/admin/treatment-types">
            <ListItemIcon className={classes.listItemIcon}>
              <TestsIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Treatment types" />
          </ListItem>
        </List>
      )}
      {userGroups.includes('manager') && (
        <List className={classes.list} component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/manager/dashboard">
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Dashboard" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/manager/clinic">
            <ListItemIcon className={classes.listItemIcon}>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Clinic" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/manager/medication-types">
            <ListItemIcon className={classes.listItemIcon}>
              <MedicationIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Medication types" />
          </ListItem>
        </List>
      )}
      {userGroups.includes('medic') && (
        <List className={classes.list} component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/medic/dashboard">
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Dashboard" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/medic/session">
            <ListItemIcon className={classes.listItemIcon}>
              <MedicationIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Sessions" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/medic/sessions">
            <ListItemIcon className={classes.listItemIcon}>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Session calendar" />
          </ListItem>
        </List>
      )}
      {userGroups.includes('client') && (
        <List className={classes.list} component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/client/dashboard">
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Dashboard" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/client/clinics">
            <ListItemIcon className={classes.listItemIcon}>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Clinics" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/client/medication-types">
            <ListItemIcon className={classes.listItemIcon}>
              <MedicationIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Medication types" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/client/admins">
            <ListItemIcon className={classes.listItemIcon}>
              <AdminsIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Admins" />
          </ListItem>
        </List>
      )}
      <div className={classes.profileContainer}>
        <div className={classes.profile}>
          <Typography className={classes.nameText} variant="h6">
            {getDisplayName()}
          </Typography>
        </div>
        <div className={classes.brandContainer}>
          <img
            className={classes.brandImage}
            src="/images/static/ns-background.gif"
            alt="Needle smart brand background" />
        </div>
      </div>
    </nav>
  );
};

export default withRouter(withStyles(styles)(Sidebar));
