import React, { useEffect, useState } from 'react';

import { types, query } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import Dashboard from '../../../layouts/Dashboard';
import { DataCard, DataCardContainer } from '../../../components/DataCard';
import TableTitle from '../../../components/TableTitle';

import {
  ClientsTable,
  CreateClientDialog,
} from './components';

import styles from './styles';

const Clients = ({ classes }) => {
  const [clients, setClients] = useState([]);
  const [isCreateClientDialogOpen, setIsCreateClientDialogOpen] = useState(false);

  const q = {
    clients: {
      id: types.string,
      name: types.string,
    },
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClients(data.clients);
    }
    return () => { mounted = false; };
  }, [data]);

  const handleOpenCreateClient = () => {
    setIsCreateClientDialogOpen(true);
  };

  const handleCloseCreateClient = () => {
    setIsCreateClientDialogOpen(false);
  };

  return (
    <>
      <Dashboard title="Clients">
        <DataCardContainer>
          <DataCard value={clients.length} label="Total clients" />
        </DataCardContainer>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="Clients" />
            <Button data-qa="create-client-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateClient}>Create Client</Button>
          </div>
          <ClientsTable clients={clients} />
        </div>
      </Dashboard>
      {isCreateClientDialogOpen && (
        <CreateClientDialog handleClose={handleCloseCreateClient} />
      )}
    </>
  );
};

export default withStyles(styles)(Clients);
