import React from 'react';
import QRCode from 'qrcode.react';

import {
  useParams,
  withRouter,
} from 'react-router-dom';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../layouts';
import BodyText from '../../../components/BodyText';
import ButtonContainer from '../../../components/ButtonContainer';

import styles from './styles';

const NsID = ({ classes, history }) => {
  const { buttonLabel = 'CLOSE', nextPath } = useParams();

  const getNsId = () => {
    const { state } = history.location;
    if (state && state.patient) {
      return state.patient.id;
    }
    return '';
  };

  const handleContinue = () => {
    if (nextPath !== 'null') {
      history.push({
        pathname: `/patient/${nextPath}`,
        state: {
          ...history.location.state,
        },
      });
    } else {
      history.goBack();
    }
  };

  return (
    <PatientLayout toolbarTitle="NeedleSmart ID">
      <div className={classes.root}>
        <QRCode data-qa="qr-code" value={getNsId()} size={300} />
      </div>
      <BodyText className={classes.text} align="center">Use this to identify yourself at any NeedleSmart clinic session</BodyText>
      <ButtonContainer>
        <Button data-qa="close-button" variant="contained" color="primary" onClick={handleContinue}>{buttonLabel}</Button>
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(NsID));
