const styles = (theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  loader: {
    textAlign: 'center',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
});

export default styles;
