const styles = (theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  percentage: {
    fontSize: '12px',
    opacity: 0.6,
  },
  loader: {
    textAlign: 'center',
  },
});

export default styles;
