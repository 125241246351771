import React, { useState, useRef, useContext } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  Button,
  Typography,
  TextField,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../components/StateProvider';
import MedicLayout from '../../../../layouts/Medic';
import ButtonContainer from '../../components/ButtonContainer';
import LottieAnimation from '../../../../components/LottieAnimation';

import styles from './styles';

const Vaccine = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const keyTimer = useRef(null);
  const scanElement = useRef();
  const [vaccine, setVaccine] = useState(null);
  const [medicationType] = useState(history.location.state.session.medication_type);
  const [vaccineUUID, setVaccineUUID] = useState(null);
  const [manual, setManual] = useState(false);

  const handleAbort = () => {
    nsProDevice.stop();
    history.replace(`/medic/session/${sessionUUID}`);
  };

  const handleContinue = () => {
    history.replace({
      pathname: `/medic/session/${sessionUUID}/patient/arm`,
      state: {
        ...history.location.state,
        vaccine: {
          timestamp: new Date().toISOString(),
          vaccineType: medicationType.id,
          id: vaccineUUID,
        },
      },
    });
  };

  const handleValidateVaccine = () => {
    setVaccine(vaccineUUID);
  };

  const handleScan = async (target) => {
    const element = target;
    clearTimeout(keyTimer.current);
    if (element.value) {
      if (manual) {
        setVaccineUUID(element.value);
      } else {
        keyTimer.current = setTimeout(() => {
          setVaccineUUID(element.value);
          setVaccine(element.value);
          element.value = '';
        }, 700);
      }
    }
  };

  const handleFocus = () => {
    scanElement.current.querySelector('input').focus();
  };

  const handleMnaualInput = () => {
    setManual(true);
  };

  if (vaccine) {
    return (
      <MedicLayout title="Validate confirmation">
        <div className={classes.root}>
          <div autoComplete="off" className={classes.form}>
            <TextField label="Vaccine Type" value={medicationType ? medicationType.name : ''} disabled />
            <TextField label="Vaccine ID" value={vaccineUUID} disabled />
          </div>
        </div>
        <ButtonContainer split>
          <Button data-qa="abort-button" variant="contained" onClick={handleAbort}>Abort</Button>
          <Button data-qa="administer-vaccine-button" variant="contained" color="primary" onClick={handleContinue}>Administer Vaccine</Button>
        </ButtonContainer>
      </MedicLayout>
    );
  }

  return (
    <MedicLayout title="Validate vaccine">
      <div className={classes.imageContainer} onKeyUp={handleFocus} onMouseUp={handleFocus} role="button" tabIndex="0">
        <div className={classes.image}>
          <LottieAnimation file="scan-barcode" />
        </div>
        {!manual && <Typography varient="body1">Scan the vaccine dose</Typography>}
        {manual && <Typography varient="body1">Type the vaccine code</Typography>}
        {!manual && <Typography varient="body1">or</Typography>}
        {!manual && <Button data-cy="manual-input" variant="contained" color="primary" onClick={handleMnaualInput}>Manual input</Button>}
        <TextField data-qa="vaccine-scan-input" className={classes.scanIdField} style={{ opacity: manual ? 1 : 0 }} ref={scanElement} autoFocus onChange={(event) => handleScan(event.target)} inputRef={(input) => input && input.focus()} />
      </div>
      <ButtonContainer split>
        <Button data-qa="abort-button" variant="contained" onClick={handleAbort}>Abort</Button>
        {manual && <Button variant="contained" color="primary" onClick={handleValidateVaccine}>Validate Vaccine</Button>}
      </ButtonContainer>
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(Vaccine));
