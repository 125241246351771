import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  root: {
    flex: '1 1 auto',
    paddingBottom: '16px',
  },
});

export default styles;
