import React, { useEffect } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const ConfirmDeleteAdmin = ({ handleCloseDelete, handleDelete, adminUUID }) => {
  const m = gql`
    mutation deleteAdmin($id: uuid!) {
      delete_client_admins_by_pk(id: $id) {
        id
      }
    }
  `;
  const [deleteAdmin, { data, error, loading }] = useMutation(m);

  useEffect(() => {
    if (data) {
      handleDelete();
    }
  }, [data, handleDelete]);

  const handleDeleteAdmin = () => {
    deleteAdmin({
      variables: {
        id: adminUUID,
      },
    });
  };

  return (
    <Dialog open onClose={handleCloseDelete} maxWidth="xs" fullWidth>
      <DialogTitle>Delete client admin</DialogTitle>
      <DialogContent>
        <Typography varient="body1">Are you sure you want to perminantly delete this client admin user?</Typography>
        {error && (
          <Typography varient="body2" color="error">{error.message}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        {!loading && (
          <>
            <Button onClick={handleCloseDelete} color="primary">Cancel</Button>
            <Button onClick={handleDeleteAdmin} color="primary">Delete</Button>
          </>
        )}
        {loading && (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteAdmin;
