import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  ButtonGroup,
  withStyles,
} from '@material-ui/core';

import { AuthContext } from '../../../components/AuthProvider';
import Dashboard from '../../../layouts/Dashboard';

import Sessions from './components/Sessions';
import Teams from './components/Teams';
import StaffMembers from './components/StaffMembers';

import styles from './styles';

const Clinic = ({ history, classes }) => {
  const { user } = useContext(AuthContext);
  const { clinic_id: clinicUUID } = user ? user : {};

  const [clinic, setClinic] = useState(null);

  const [view, setView] = useState(history.location.hash ? history.location.hash.slice(1).toUpperCase() : 'SESSIONS');

  const q = {
    clinics_by_pk: params(
      { id: rawString(clinicUUID) },
      {
        id: types.string,
        name: types.string,
        address_line_1: types.string,
        address_line_2: types.string,
        address_city: types.string,
        address_postcode: types.string,
      },
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const clin = data.clinics_by_pk;
      if (clin) {
        setClinic(clin);
      }
    }
    return () => { mounted = false; };
  }, [data]);

  const handleViewChange = (viewName) => {
    setView(viewName);
    history.replace(`#${viewName.toLowerCase()}`);
  };

  return (
    <>
      <Dashboard title={clinic ? clinic.name : ''}>
        <div className={classes.viewButtons}>
          <ButtonGroup color="primary">
            <Button data-qa="sessions-button" variant={view === 'SESSIONS' ? 'contained' : ''} onClick={() => handleViewChange('SESSIONS')}>Sessions</Button>
            <Button data-qa="teams-button" variant={view === 'TEAMS' ? 'contained' : ''} onClick={() => handleViewChange('TEAMS')}>Teams</Button>
            <Button data-qa="staff-button" variant={view === 'STAFF' ? 'contained' : ''} onClick={() => handleViewChange('STAFF')}>Staff Members</Button>
          </ButtonGroup>
        </div>
        {view === 'SESSIONS' && (
          <Sessions />
        )}
        {view === 'TEAMS' && (
          <Teams />
        )}
        {view === 'STAFF' && (
          <StaffMembers />
        )}
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(Clinic));
