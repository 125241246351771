import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Avatar,
  CircularProgress,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  withStyles,
} from '@material-ui/core';

import {
  CheckCircleOutline as DataControllerIcon,
  Clear as NotDataControllerIcon,
} from '@material-ui/icons';

import styles from './styles';

const AdminTable = ({ history, classes, data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [admins, setAdmins] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(false);

  const handleAdmin = (adminUUID) => {
    history.push(`/admin/admins/${adminUUID}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setAdmins(data);
    }
    setTimeout(() => {
      if (mounted) {
        setFetchTimeout(true);
      }
    }, 3000);
    return () => { mounted = false; };
  }, [data]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, admins.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table data-qa="admin-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Data controller</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.length > 0 && (
              admins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((admin) => (
                <TableRow className={classes.tableRow} hover key={admin.id} onClick={() => handleAdmin(admin.id)}>
                  <TableCell component="th" scope="row">
                    <div className={classes.name}>
                      <Avatar className={classes.avatar}>{admin.full_name.split(' ').map(i => i[0]).join('')}</Avatar>
                      {admin.full_name}
                    </div>
                  </TableCell>
                  <TableCell data-qa="row-email">{admin.email}</TableCell>
                  <TableCell>
                    {admin.dataController
                      ? <DataControllerIcon color="secondary" />
                      : <NotDataControllerIcon />}
                  </TableCell>
                </TableRow>
              ))
            )}
            {admins.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={3} />
              </TableRow>
            )}
            {admins.length === 0 && (
              <TableRow>
                <TableCell className={classes.loader} colSpan={3}>
                  {fetchTimeout && ('No Data')}
                  {!fetchTimeout && (<CircularProgress />)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        data-qa="table-pagination-count"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={admins.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage} />
    </Paper>
  );
};

export default withRouter(withStyles(styles)(AdminTable));
