import React, { useEffect } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const CancelSessionDialog = ({ sessionId, isCanceled = false, label, handleClose }) => {
  const m = gql`
    mutation cancelSession($pk_columns: sessions_pk_columns_input!, $set: sessions_set_input!) {
      update_sessions_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
        active
      }
    }
  `;

  const [cancelSession, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleClose();
    }
    return () => { mounted = false; };
  }, [data, handleClose]);

  const handleContinue = () => {
    cancelSession({
      variables: {
        pk_columns: {
          id: sessionId,
        },
        set: {
          active: isCanceled,
        },
      },
    })
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{label} session</DialogTitle>
      <DialogContent>
        Are you sure you want to {label.toLowerCase()} this session?
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button onClick={handleClose} color="primary">No</Button>
              <Button onClick={handleContinue} color="primary">Yes {label} it</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CancelSessionDialog);
