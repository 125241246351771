import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import ButtonContainer from '../../../../components/ButtonContainer';
import { validatePassword } from '../../../../utils/validators';

import styles from './styles';

const SignUpPassword = ({ classes, history }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState({});

  const handleSignUp = () => {
    let error = false;
    setErrors({});
    if (!password) {
      setErrors((e) => ({ ...e, password: 'Please provide a password' }));
      error = true;
    } else if (!validatePassword(password)) {
      setErrors((e) => ({ ...e, password: 'Password does not conform to the passowrd criteria' }));
      error = true;
    }
    if (!passwordConfirm) {
      setErrors((e) => ({ ...e, passwordConfirm: 'Please confirm password' }));
      error = true;
    } else if (password !== passwordConfirm) {
      setErrors((e) => ({ ...e, passwordConfirm: 'Passwords must match' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = () => {
    history.push({
      pathname: '/patient/take-photo',
      state: {
        ...history.location.state,
        password: btoa(password),
      },
    });
  };

  return (
    <PatientLayout toolbarTitle="Create a password" canGoBack>
      <div className={classes.form} autoComplete="off">
        <Typography style={{ marginBottom: '16px' }}>
          Passwords must be
          at least 8 characters long 
          and contain at least 1 lower case letter, 
          1 upper case letter, 
          and a number
        </Typography>
        <TextField
          required
          label="Password"
          type="password"
          error={errors.password}
          helperText={errors.password}
          value={password}
          onChange={(event) => setPassword(event.target.value || null)}
        />
        <TextField
          required
          label="Confirm Password"
          type="password"
          error={errors.passwordConfirm}
          helperText={errors.passwordConfirm}
          value={passwordConfirm}
          onChange={(event) => setPasswordConfirm(event.target.value || null)}
        />
      </div>
      <ButtonContainer>
        <Button data-qa="confirm-button" variant="contained" color="primary" onClick={handleSignUp}>CONFIRM</Button>
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpPassword));
