const styles = (theme) => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  question: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    marginBottom: theme.spacing(2),
  },
  answers: {
    alignItems: 'center',
  },
});

export default styles;
