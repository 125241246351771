import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import Dashboard from '../../../layouts/Dashboard';
import { AuthContext } from '../../../components/AuthProvider';
import TableTitle from '../../../components/TableTitle';

import {
  ClinicsTable,
  CreateClinicDialog,
} from './components';

import styles from './styles';

const Clinics = ({ history, classes }) => {
  const { user } = useContext(AuthContext);
  const { client_id: clientUUID } = user ? user : {};

  const [client, setClient] = useState(null);
  const [clinics, setClinics] = useState([]);

  const count = { aggregate: { count: types.number }};
  const where = { client_id: { _eq: rawString(clientUUID) } };
  const q = {
    clients_by_pk: params(
      { id: rawString(clientUUID) },
      {
        id: types.string,
        name: types.string,
      },
    ),
    clinics: params(
      {where},
      [{
        id: types.string,
        name: types.string,
        address_line_1: types.string,
        address_line_2: types.string,
        address_city: types.string,
        address_postcode: types.string,
        teams_aggregate: count,
        client_staff_aggregate: count
      }],
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClient(data.clients_by_pk);
      setClinics(data.clinics);
    }
    return () => { mounted = false; };
  }, [data]);

  const [isCreateClinicDialogOpen, setIsCreateClinicDialogOpen] = useState(false);

  const handleOpenCreateClinic = () => {
    setIsCreateClinicDialogOpen(true);
  };

  const handleCloseCreateClinic = () => {
    setIsCreateClinicDialogOpen(false);
  };

  const handleClinic = (locationUUID) => {
    history.push(`/client/clinics/${locationUUID}`);
  };

  return (
    <>
      <Dashboard title={`${client ? client.name : ''}`}>
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="Clinics" />
            <Button data-qa="create-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateClinic}>Create Clinic</Button>
          </div>
          <ClinicsTable data={clinics} handleClinic={handleClinic} />
        </div>
      </Dashboard>
      {isCreateClinicDialogOpen && (
        <CreateClinicDialog clientUUID={clientUUID} handleClose={handleCloseCreateClinic} />
      )}
    </>
  );
};

export default withRouter(withStyles(styles)(Clinics));
