import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../layouts';
import { AuthContext } from '../../../components/AuthProvider';
import LottieAnimation from '../../../components/LottieAnimation';
import BodyText from '../../../components/BodyText';

import styles from './styles';

const Invite = ({ classes, history }) => {
  const { loggedInUser } = useContext(AuthContext);
  const { inviteUUID } = useParams();

  const [invite, setInvite] = useState(null);

  const q = {
    session_invites_by_pk: params(
      { id: rawString(inviteUUID) },
      {
        id: types.string,
        client_id: types.string,
        clinic_id: types.string,
        session_id: types.string,
        name: types.string,
        active: types.boolean,
      }
    )
  };

  const { data, loading } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const invite = data.session_invites_by_pk;
      if (invite) {
        setInvite(invite);
      }
    }
    return () => { mounted = false; };
  }, [data]);

  useEffect(() => {
    let mounted = true;
    if (mounted && invite) {
      setTimeout(() => {
        if (loggedInUser) {
          history.push({
            pathname: '/patient/home',
            state: {
              invite,
            },
          });
        } else {
          history.push({
            pathname: '/patient/welcome',
            state: {
              invite,
            },
          });
        }
      }, 2500);
    }
    return () => { mounted = false; };
  }, [invite, history, loggedInUser]);

  if (!invite && !loading) {
    return (
      <PatientLayout title="Session invite not found" noToolbar>
        <LottieAnimation file="no-invite" />
        <BodyText>We can&apos;t find the session invite you are looking for. Please check again with who supplied it.</BodyText>
      </PatientLayout>
    );
  }
  return (
    <PatientLayout title="Searching for invite" noToolbar>
      <LottieAnimation file="search" />
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(Invite));
