import React, { useEffect, useState } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const CreateTeamDialog = ({ classes, clientUUID, clinicUUID, handleClose }) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  
  const m = gql`
    mutation addSession($object: teams_insert_input!) {
      insert_teams_one(object: $object) {
        id
        name
      }
    }
  `;

  const [addTeam, { data, loading }] = useMutation(m, {
    update(cache, { data: { insert_teams_one } }) {
      cache.modify({
        fields: {
          teams(existing = []) {
            const n = cache.writeFragment({
              data: insert_teams_one,
              fragment: gql`
                fragment NewTeam on Team {
                  id
                  name
                }
              `
            });
            return [...existing, n];
          },
        }
      });
    }
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleClose();
    }
    return () => { mounted = false; };
  }, [data, handleClose]);

  const handleContinueCreate = () => {
    let error = false;
    setErrors({});
    if (!name) {
      setErrors((e) => ({ ...e, name: 'Please provide a name' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = async () => {
    addTeam({
      variables: {
        object: {
          client_id: clientUUID,
          clinic_id: clinicUUID,
          name,
        },
      },
    });
  };

  return (
    <Dialog data-qa="create-team-dialog" open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create new team</DialogTitle>
      <DialogContent>
        <div autoComplete="off" className={classes.form}>
          <TextField data-qa="team-name-input" required label="Name" name="name" error={errors.name} helperText={errors.name} value={name} autoComplete="off" onChange={(event) => setName(event.target.value || null)} />
        </div>
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button data-qa="circular-progress-button" color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="cancel-button" onClick={handleClose} color="primary">Cancel</Button>
              <Button data-qa="create-button" onClick={handleContinueCreate} color="primary">Create</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CreateTeamDialog);
