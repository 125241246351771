import React from 'react';

import { AppBar, Dialog, DialogContent, IconButton, Toolbar, withStyles } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PrivacyDialog = ({ classes, handleClosePrivacy }) => (
  <Dialog data-qa="privacy-dialog" fullScreen open TransitionComponent={Transition}>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleClosePrivacy} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <DialogContent>
      {/* <div>
        <h2>NeedleSmart Application privacy notice</h2>
        <h3>1. Introduction</h3>
        <p>1.1 This notice (together with our end-user terms as set out at [link to NeedleSmart App end user terms] (EULA) and any additional terms of use incorporated by reference into the EULA, together our Terms of Use) applies to your use of:</p>
        <ul className={classes.list}>
          <li>the NeedleSmart App mobile application software (App), once you have downloaded or streamed a copy of the App onto your mobile smartphone or handheld device (Device).</li>
          <li>any of the services accessible through the App (Services), including:</li>
          <ul className={classes.nestedList}>
            <li>booking an appointment for a medical test at a NeedleSmart testing event, including the provision of personal data necessary to undertake identity verification checks (Booking Services); and</li>
            <li>using the App in the course of providing services at a NeedleSmart testing event, including to verify the ID of an attendee at a NeedleSmart testing event, allocating a test kit to an attendee, monitoring the conduct of the test and recording the test result (Event Services).</li>
          </ul>
        </ul>
        <p>1.2 The App has dual functionality, as it may be used by individuals for either Booking Services or Event Services.  This notice applies to all users of the App, regardless of which Service they are using the App to access.</p>
        <p>1.3 This notice sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. This App is not intended for children and we do not knowingly collect data relating to children. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.</p>
        <p>1.4 This notice is provided in a layered format so you can click through to the specific areas set out below. Alternatively, you can download a pdf version of the notice here: </p>
        <h3>2. Who we are</h3>
        <p>2.1 Atcore Ltd (trading as CORE) is the controller and is responsible for your personal data.  CORE is the medical services provider that has legal responsibility for the provision of medical tests at NeedleSmart testing events.  </p>
        <p>2.2 Gatset Limited (trading as NeedleSmart) is the operator of the App and processes your personal data as a processor on behalf of CORE.  NeedleSmart provides CORE with the facility and functionality of the App, which CORE then uses in the course of providing medical testing services at NeedleSmart testing events.</p>
        <p>2.3 This notice is concerned only with how personal data is processed using the NeedleSmart App.  If you are using the App for Booking Services, you can find out more information about how CORE will use your data as a patient in CORE&apos;s patient privacy notice available here [link to CORE patient privacy notice].  A copy of this will also be provided to you when CORE provides your test results.</p>
        <p>2.4 References to &quot;we&quot;, &quot;our&quot; and &quot;us&quot; in this policy refer to CORE and NeedleSmart.</p>
        <p>2.5 If you have any questions about this privacy policy, please contact us using the details set out below.</p>
        <ul className={classes.list}>
          <li>stefaan@testassurancegroup.com</li>
          <li>Unit 4-6 The Wharf Centre, Wharf Street, Warwick, Warwickshire, UK CV34 5LB (trading as NeedleSmart)</li>
        </ul>
        <h3>3. Important information</h3>
        <h4>3.1 Complaints</h4>
        <p>You have the right to make a complaint regarding how your personal data is processed by us at any time to the Information Commissioner&apos;s Office (ICO), the UK supervisory authority for data protection issues.  You can contact the ICO via their website at ico.org.uk.</p>
        <h4>3.2 Changes To This Notice</h4>
        <p>We keep our privacy notice under regular review.
          This version was last updated on 17/09/2020.  It may change and if it does, these changes will be posted on this page and, where appropriate, notified to you when you next start or open the App.  The new notice may be displayed on-screen and you may be required to read the changes to continue your use of the App or the Services.
          It is important that the personal data we hold about you is accurate and current.  Please keep us informed if your personal data changes during our relationship with you.
        </p>
        <h4>3.3 Third Party Links</h4>
        <p>The App may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates.  Please note that these websites and any services that may be accessible through them have their own privacy notices and that we do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services, such as contract and location data.  Please check these policies before you submit any personal data to these websites or use these services.</p>
        <h3>4. What Personal Data We Collect About You</h3>
        <p>4.1 Where you are using the App to access Booking Services, we may collect, use, store and transfer different kinds of personal data about you, as follows:</p>
        <ul className={classes.list}>
          <li>full name (first name and last name);</li>
          <li>gender</li>
          <li>email address</li>
          <li>a photograph of your face</li>
          <li>date of birth</li>
          <li>postal address (including postcode)</li>
          <li>test kit allocation; and</li>
          <li>test results.</li>
        </ul>
        <p>4.2 Where you are using the App to access Event Services, we may collect, use, store and transfer different kinds of personal data about you, as follows:</p>
        <ul className={classes.list}>
          <li>full name (first name and last name);</li>
          <li>professional or work email address;</li>
          <li>mobile telephone number;</li>
          <li>employment details (your employer and your role); and</li>
          <li>your account details (including your password).</li>
        </ul>
        <p>4.3 For everyone using the App, we also may collect, use, store and transfer other types of personal data, as follows:</p>
        <ul className={classes.list}>
          <li>device data;</li>
          <li>usage data; and</li>
          <li>location data.</li>
        </ul>
        <p>4.4 [We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose.  Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity.  For example, we may aggregate usage data to calculate the percentage of users accessing a specific App feature, or to analyse performance of the App at different stages of a NeedleSmart testing event.  However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.</p>
        <h3>5. How We Collect Your Personal Data</h3>
        <p>5.1 We will collect information about you in the following ways:</p>
        <ul className={classes.list}>
          <li>Information you give us: This is information you give us about you by filling in forms on the App, or by corresponding with us (for example, by email or by chat).  It includes information you provide when you download or register to use the App or access any of the Services using the App, and when you report a problem with the App or the Services.  If you contact us, we will keep a record of that correspondence.</li>
          <li>Information we collect about you and your device: Each time you use the App we will automatically collect personal data including device data and usage data.  We collect this data using cookies and other similar technologies.</li>
          <li>Location data: We also use GPS technology to determine your current location when you use the App.  You can stop us from processing your location data at any time by disabling location data in your settings on your device, however as some of our Services require your location data for the feature to work, such features may not be available to you if you decide to disable location data sharing.</li>
          <li>Information we receive from other sources. We will receive personal data about you from various third parties as set out below: </li>
          <ul className={classes.nestedList}>
            <li>where you are using the App to access Event Services, we may receive personal data about you from your employer; and</li>
            <li>we may receive device data from the following providers:</li>
            <ul className={classes.list}>
              <li>analytics providers;</li>
              <li>advertising networks;</li>
              <li>search information providers;</li>
            </ul>
          </ul>
          <li>Unique Allocation Numbers: Where you are using the App to access Booking Services, during the NeedleSmart testing event you will be allocated a unique allocation number which will itself be allocated to a test kit identifiable by a unique QR code.  During the testing process, information about the test kit linked to your unique allocation number will be processed using the App.</li>
        </ul>
        <h3>6. Cookies</h3>
        <h3>7. How We Use Your Personal Data</h3>
        <p>7.1 We will only use your personal data when the law allows us to do so.  Most commonly we will use your personal data in the following circumstances:</p>
        <ul className={classes.list}>
          <li>where you have consented before the processing;</li>
          <li>where we need to perform a contract we are about to enter or have entered into with you; </li>
          <li>where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; or</li>
          <li>where we need to comply with a legal or regulatory obligations.</li>
        </ul>
        <h3>8. Purposes For Which We Will Use Your Personal Data</h3>
        <p>8.1 Where you are using the App to access Booking Services, we will use your personal data for the following purposes: </p>
        <table>
          <tbody>
            <tr>
              <th>Purpose / Activity</th>
              <th>Types of Data</th>
              <th>Lawful basis of processing</th>
            </tr>
            <tr>
              <td>To install the App and register you as a new App user</td>
              <td>Full Name, Email address</td>
              <td>Your consent</td>
            </tr>
            <tr>
              <td>To be able to verify your identity when you arrive at a NeedleSmart testing event</td>
              <td>Gender, Photograph, Date of Birth, Postal Address</td>
              <td>Your consent; Performance of a contract with you; Necessary for our legitimate interests (to comply with our obligations to our clients); Necessary to comply with legal obligations (to reduce fraud)</td>
            </tr>
            <tr>
              <td>To provide medical testing services</td>
              <td>Test Kit Allocation; Test Kit Results</td>
              <td>Your consent; Performance of a contract with you; Necessary for our legitimate interests (to comply with our obligations to our clients; Necessary to comply with legal obligations (CORE is a regulated medical services provider)</td>
            </tr>
            <tr>
              <td>To manage our relationship with you including contacting you in the event of a complaint or notifying you of changes to the App or any Services</td>
              <td>Full Name, Email Address, Device Data</td>
              <td>Performance of a contract with you; Necessary for our legitimate interests (to keep records updated and to analyse how users use the App and the Services); Necessary to comply with legal obligations (to inform you of any changes to our Terms of Use)</td>
            </tr>
            <tr>
              <td>To administer and protect our business and this App including troubleshooting, data analysis, system testing and monitoring trends.</td>
              <td>Device Data; Usage Data; Location Data; Aggregated Data</td>
              <td>Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security)</td>
            </tr>
          </tbody>
        </table>

        <p>8.2 Where you are using the App to access Event Services, we will use your personal data for the following purposes:</p>
        <table>
          <tbody>
            <tr>
              <th>Purpose / Activity</th>
              <th>Types of Data</th>
              <th>Lawful basis of processing</th>
            </tr>
            <tr>
              <td>To install the App and register you as a new App user</td>
              <td>Full Name, Email Address, Mobile Number, Account Details (Password)</td>
              <td>Necessary for our legitimate interests and the legitimate interests of your employer (to enable the provision of services by your employer)</td>
            </tr>
            <tr>
              <td>To enable you to carry out services during a NeedleSmart testing event</td>
              <td>Employment Details</td>
              <td>Necessary for our legitimate interests (in ensuring that qualified personnel are allocated to correct roles in the delivery of NeedleSmart testing events)</td>
            </tr>
            <tr>
              <td>To manage our relationship with you including notifying you of changes to the App or any Services</td>
              <td>Full Name; Email Address; Device Data</td>
              <td>Performance of a contract with you; Necessary for our legitimate interests (to keep records updated and to analyse how users use the App and the Services); Necessary to comply with legal obligations (to inform you of any changes to our Terms of Use)</td>
            </tr>
            <tr>
              <td>To administer and protect our business and this App including troubleshooting, data analysis, system testing and monitoring trends.</td>
              <td>Device Data; Usage Data; Location Data; Aggregated Data</td>
              <td>Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security)</td>
            </tr>
          </tbody>
        </table>
        <h3>9. Disclosures Of Your Personal Data</h3>
        <p>9.1 Where you are using the App for Booking Services, we will ask you for your consent to share your test results with your employer.</p>
        <p>9.2 We may also need to share your personal data with the third parties set out below for the purposes set out in the tables:</p>
        <ul className={classes.list}>
          <li>service providers involved in the operation of NeedleSmart testing events (such as the service providers who help operate security and logistics at NeedleSmart testing events)</li>
          <li>service providers involved in the hosting of the App and the databases supporting the App (such as our cloud storage providers who host data held in the App);</li>
          <li>professional advisers including lawyers, bankers, auditors, and insurers based in the UK who provide consultancy, banking, legal, insurance and accounting services to us;</li>
          <li>law enforcement agencies for the purposes of the prevention or detection of crime, or to comply with any court order or legal obligation (such as to confirm whether an individual has attended a NeedleSmart testing event on a particular date or at a particular time); and</li>
          <li>any third parties to whom we may choose to sell, transfer or merge parts of our business or our assets.  Alternatively, we may seek to acquire other businesses or merge with them.  If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</li>
        </ul>
        <h3>10. International tranfers</h3>
        <p>We do not transfer your personal data outside the UK.</p>
        <h3>11. Data security</h3>
        <p>11.1 All information you provide to us is stored on our secure servers.  Where we have given you (or where you have chosen) a password that enables you to access certain parts of the App, you are responsible for keeping this password confidential.  We ask you not to share a password with anyone.</p>
        <p>11.2 Once we have received your information, we will use strict procedures and security features to try to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way.</p>
        <p>11.3 We will collect and store personal data on your device using application data caches and browser web storage and other technology.</p>
        <p>11.4 We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator when we are legally required to do so.</p>
        <h3>12. Data retention</h3>
        <p>12.1 Where you are using the App to access Booking Services, your personal data will be collected and, within 24 hours of the end of the NeedleSmart testing event at which your medical test was conducted, your personal data will be transferred from the App to CORE&apos;s central database system.</p>
        <p>12.2 Where you are using the App to access Event Services, your personal data will be held on the App for as long as you are providing Event Services for NeedleSmart testing events, and for a period of up to after the end of each NeedleSmart testing event that you have worked and used the App at.</p>
        <p>12.3 In some circumstances, you can ask us to delete your data</p>
        <p>12.4 In some circumstances, we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
        <h3>13. Your legal rights</h3>
        <p>13.1 You have the following rights under data protection laws in relation to your personal data:</p>
        <ul className={classes.list}>
          <li>Request Access: Request access to your personal data (commonly known as making a &quot;data subject access request&quot;). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</li>
          <li>Request Correction: This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
          <li>Request Erasure: This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
          <li>Object to Processing: This enable you to object to the processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
          <li>Restriction of Processing: This enables you to ask us to suspend the processing of your personal data in the following scenarios:</li>
          <ul className={classes.nestedList}>
            <li>if you want us to establish the data&apos;s accuracy;</li>
            <li>where our use of the data is unlawful, but you do not want us to erase it;</li>
            <li>where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or</li>
            <li>you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.</li>
          </ul>
          <li>Porting or Transfer: This enable you to request that your data be ported or transferred to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
          <li>Withdraw Consent: This enables you to withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
        </ul>
        <p>13.2 You can exercise any of these rights by contacting us at the details [link to &quot;Who We Are&quot;] above.</p>
      </div> */}

      <div>
        DVS APP PRIVACY NOTICE
        1.1 This notice (together with our end-user terms as set out at DVS App End User Terms and any additional terms of use incorporated by reference into the EULA, together our Terms of Use) applies to your use of:
        (a) the DVS App mobile application software (App) once you have downloaded or streamed a copy of the App onto your mobile smartphone or handheld device (Device).
        (b) any of the services accessible through the App (Services), including:
        (i) booking an appointment for a medical test at a DVS vaccination event, including the provision of personal data necessary to undertake identity verification checks
        (ii) using the App in the course of providing services at a DVS vaccination event, including to verify the ID of an attendee at a DVS vaccination event and monitoring the conduct of the test and recording the event
        1.2 The App has single function to register a user for a (Vaccination) Event Services. These terms apply to all users of the App, regardless of which Service they are using the App to access.
        2 Your Data
        2.1 Your Health Care Provider (the Vaccine Administrator) is the data controller and is responsible for your personal data. Needlesmart is the data capture tool and as such we may need to take on certain data processing functions but only under the express instructions of your Health Care Provider.
        2.2 NeedleSmart Limited is the operator of the App and processes your personal data as a processor on behalf of your Health care Provider. NeedleSmart provides your Health Care Provider with the facility and functionality of the App, which they then use in the course of providing vaccination services at DVS vaccination events.
        2.3 This notice is concerned only with how personal data is processed within the DVS App. If you are using the App for booking a (Vaccination) Event Services, you can find out more information about how Needlesmart will process your data at our Patient Privacy Notice here.
        2.4 If you have any wider concerns or queries about how your personal data is controlled or used outside of this specific Vaccination Event please refer to your Health Care providers / vaccination administrator Privacy Notice and Data management policies
        2.5 If you have any questions about this privacy policy, please contact us using the details set out below.

        (a) enquiries@needlesmart.com
        (b) Suite 2b, Stanley Grange, Ormskirk Road, Knowsley, Prescot, L34 4AT
        3 Important Information
        3.1 Complaints
        You have the right to make a complaint regarding how your personal data is processed by us at any time to the Information Commissioner&apos;s Office (ICO), the UK supervisory authority for data protection issues. You can contact the ICO via their website at ico.org.uk.
        3.2 Changes To This Notice
        We keep our privacy notice under regular review.
        This version was last updated on 1 Janurary 2021 It may change and if it does, these changes will be posted on this page and, where appropriate, notified to you when you next start or open the App. The new notice may be displayed on-screen and you may be required to read the changes to continue your use of the App or the Services.
        It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during our relationship with you.
        3.3 Third Party Links
        The App may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. Please note that these websites and any services that may be accessible through them have their own privacy notices and that we do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services, such as contract and location data. Please check these policies before you submit any personal data to these websites or use these services.
        4 What Personal Data We Collect About You
        4.1 Where you are using the App to register for a Vaccination Event, we may temporarily collect, use and transfer different kinds of personal data about you, as follows:
        (a) full name (first name and last name);
        (b) gender;
        (c) email address;
        (d) a photograph of your face;
        (e) date of birth;
        (f) postal address (including postcode)
        (g) Vaccination Type given
        (h) Date and Location of Vaccination
        (i) Name of HCP who administered vaccine

        (j) Answers to any Vaccination pre-qualification questions answered
        (k) Note of which arm used for vaccination
        4.2 Where you are using the App to register and account we may collect, use, store and transfer different kinds of personal data about you, as follows:
        (a) full name (first name and last name);
        (b) professional or work email address;
        (c) mobile telephone number;
        (d) your account details (including your password).
        4.3 For everyone using the App, we also may collect, use, store and transfer other types of personal data, as follows:
        (a) device data;
        (b) usage data; and
        (c) location data.
        4.4 [We also collect, use and share Aggregated (anonymised) Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate usage data to calculate the percentage of users accessing a specific App feature, or to analyse performance of the App at different stages of a DVS vaccination event. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.]
        5 How We Collect Your Personal Data
        5.1 We will collect information about you in the following ways:
        (a) Information you give us: This is information you give us about you by filling in forms on the App, or by corresponding with us (for example, by email or by chat). It includes information you provide when you download or register to use the App or access any of the Services using the App, and when you report a problem with the App or the Services. If you contact us, we will keep a record of that correspondence.
        (b) Information we collect about you and your device: Each time you use the App we will automatically collect personal data including device data and usage data. We collect this data using cookies and other similar technologies.
        (c) Unique Allocation Numbers: Where you are using the App to access Booking Services, during the DVS vaccination event you will be allocated a unique allocation number which will itself be identifiable by a unique QR code. During the Vaccination process this UIN will be processed using the App. As a result of this process your personal data will not be processed through the App apart from for final reporting purposes.

        6 How We Use Your Personal Data
        6.1 We will only use your personal data when the law allows us to do so. Most commonly we will use your personal data in the following circumstances:
        (a) where you have consented before the processing;
        (b) where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; or
        (c) where we need to comply with a legal or regulatory obligations.
        7 Purposes For Which We Will Use Your Personal Data
        7.1 Where you are using the App to access Booking Services, we will use your personal data for the following purposes:
        Purpose / Activity
        Types of Data
        Lawful basis of processing
        To install the App and register you as a new App user
        Full Name Email address
        Your consent
        To be able to verify your identity when you arrive at a DVS vaccination event
        Gender Photograph Date of Birth Postal Address
        Your consent
        Performance of a contract with you
        Necessary for our legitimate interests (to comply with our obligations to our clients)
        Necessary to comply with legal obligations (to reduce fraud)
        To manage our relationship with you including contacting you in the event of a complaint or notifying you of changes to the App or any Services
        Full Name Email Address Device Data
        Performance of a contract with you
        Necessary for our legitimate interests (to keep records updated and to analyse how users use the App and the Services)
        Necessary to comply with legal obligations (to inform you of any changes to our Terms of Use)

        Purpose / Activity
        Types of Data
        Lawful basis of processing
        To administer and protect our business and this App including troubleshooting, data analysis, system testing and monitoring trends.
        Device Data Usage Data Location Data Aggregated Data
        Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security)
        8 Disclosures Of Your Personal Data
        8.1 Where you are using the App to book a Vaccination event, we will ask you for your consent to share your vaccination record with your Health care Provider (Vaccination Administrator)
        8.2 We may also need to share your personal data with the third parties set out below for the purposes set out in the tables:
        (a) service providers involved in the operation of DVS vaccination events (such as the service providers who help operate security and logistics at DVS vaccination events)
        (b) service providers involved in the hosting of the App and the databases supporting the App (such as our cloud storage providers who host data held in the App);
        (c) professional advisers including lawyers, bankers, auditors, and insurers based in the UK who provide consultancy, banking, legal, insurance and accounting services to us;
        (d) law enforcement agencies for the purposes of the prevention or detection of crime, or to comply with any court order or legal obligation (such as to confirm whether an individual has attended a DVS vaccination event on a particular date or at a particular time); and
        (e) any third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.
        9 International Transfers
        There are currently no plans nor provisions to transfer any personal data outside the UK.
        10 Data Security
        10.1 All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password that enables you to access certain parts of the App, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
        10.2 Once we have received your information, we will use strict procedures and security features to try to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way.

        10.3 We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator when we are legally required to do so.
        11 Data Retention
        11.1 Where you are using the App to access a Vaccination, your personal data will be collected and, within 24 hours of the end of the DVS vaccination event it will be passed to your Health Care Provider. All data will be deleted from the Needlesmart App as soon as we have formal notification that it has been securely passed to the H.ealth Care provider
        11.2 In some circumstances, we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
        12 Your Legal Rights
        12.1 You have the following rights under data protection laws in relation to your personal data:
        (a) Request Access: Request access to your personal data (commonly known as making a &quot;data subject access request&quot;). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
        (b) Request Correction: This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
        (c) Request Erasure: This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
        (d) Object to Processing: This enable you to object to the processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
        (e) Restriction of Processing: This enables you to ask us to suspend the processing of your personal data in the following scenarios:
        (i) if you want us to establish the data&apos;s accuracy;
        (ii) where our use of the data is unlawful but you do not want us to erase it;
        (iii) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or
        (iv) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.

        (f) Porting or Transfer: This enable you to request that your data be ported or transferred to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
        (g) Withdraw Consent: This enables you to withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
        12.2 As Needlesmart is only a short term Data Processor you can exercise any of these rights by contacting your Health Care Provider or Vaccination Administrator as they will be the Data Controller for your vaccination Event and all data pertaining to that event.
      </div>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(PrivacyDialog);
