import React, { useContext, useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  KeyboardBackspace as CloseIcon,
  Input as InputIcon,
} from '@material-ui/icons';


import { AuthContext } from '../../../../components/AuthProvider';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  title?: string;
  classes: ClassNameMap<string>,
  className: string;
  canGoBack: boolean;
}

const Topbar = ({ classes, className, history, title, canGoBack }: Props) => {
  const { loggedInUser, signOut } = useContext(AuthContext)!;

  const [isSignedIn, setIsSignedIn] = useState(false);

  const handleSignOut = async () => {
    await signOut();
    history.push('/sign-in');
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (loggedInUser) {
      setIsSignedIn(true);
    }
  }, [loggedInUser]);

  return (
    <>
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          {canGoBack && (
            <IconButton className={classes.menuButton} onClick={handleBack}>
              {/*<IconButton className={classes.menuButton} onClick={handleBack} variant="text">*/}
              <CloseIcon />
            </IconButton>
          )}
          <Typography className={classes.title} variant="h4">{title}</Typography>
          {isSignedIn && (
            <Tooltip title="Sign out" arrow>
              <IconButton data-qa="signout-button" className={classes.signOutButton} onClick={handleSignOut}>
                <InputIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </div>
    </>
  );
};

export default withRouter(withStyles(styles)(Topbar));
