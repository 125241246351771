import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    fontFamily: 'Roboto',
  },
});

export default styles;
