import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'clsx';

import {
  Grid,
  Paper,
  withStyles,
} from '@material-ui/core';

import {
  Room,
} from '@material-ui/icons';

import {
  ViewState,
} from '@devexpress/dx-react-scheduler';

import {
  DayView,
  AppointmentTooltip,
  Scheduler,
  MonthView,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  ViewSwitcher,
  AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';

import styles from './styles';

const Appointment = withStyles(styles, { name: 'Appointment' })(({ classes, isShaded, ...restProps }) => (
  <Appointments.Appointment
    className={classNames({
      [classes.appointment]: true,
      [classes.shadedAppointment]: isShaded,
    })}
    {...restProps} />
));

const Content = withStyles(styles, { name: 'Content' })(({ children, appointmentData, classes, ...restProps }) => (
  <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
    <Grid container alignItems="center">
      <Grid item xs={2} className={classes.textCenter}>
        <Room className={classes.icon} />
      </Grid>
      <Grid item xs={10}>
        <span>{appointmentData.location}</span>
      </Grid>
    </Grid>
  </AppointmentTooltip.Content>
));

const SessionsCalendar = ({ classes, sessions = [] }) => {
  const [sessionsData, setSessionsData] = useState([]);

  const updateData = useCallback(async () => {
    const data = sessions.map((i) => ({
      id: i.id,
      startDate: i.start_date,
      endDate: i.end_date,
      title: i.name,
      location: i.clinic.name,
      color: '#bbffbb',
    }));
    setSessionsData(data);
  }, [sessions]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      updateData();
    }
    return () => { mounted = false; };
  }, [updateData]);

  return (
    <Paper>
      <Scheduler color="primary" data={sessionsData} locale="en-GB" firstDayOfWeek={1}>
        <ViewState
          defaultCurrentDate={new Date().toDateString()}
          defaultCurrentViewName="Week" />

        <WeekView startDayHour={7} endDayHour={20} showAllDayTitle />
        <MonthView />

        <Toolbar />
        <DayView />
        <AllDayPanel />
        <Appointments appointmentComponent={Appointment} />
        <ViewSwitcher />
        <DateNavigator />
        <TodayButton />
        <AppointmentTooltip showCloseButton contentComponent={Content} />
      </Scheduler>
    </Paper>
  );
};

export default withRouter(withStyles(styles)(SessionsCalendar));
