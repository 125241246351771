const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  questionsContainer: {
    position: 'relative',
  },
  dots: {
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.22) 50%, transparent 0%)',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '2px 6px',
    margin: '-16px 0 18px',
    minWidth: 2,
    width: 2,
    opacity: 1,
    height: 'calc(100% - 40px)',
    position: 'absolute',
  },
  addButtonContainer: {
    flex: '0 0 auto',
    marginTop: theme.spacing(2),
  },
  addButton: {
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
});

export default styles;
