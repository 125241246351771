import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
  createContext,
  ReactElement,
  ReactNode,
} from 'react';
// import Invite from '../../data/models/Invite';

import { AuthContext } from '../AuthProvider';

// import UserDataManager from '../../data/managers/User/UserDataManager';
// import PatientDataManager from '../../data/managers/Patient/PatientDataManager';
// import AdminDataManager from '../../data/managers/Admin/AdminDataManager';
// import ClientDataManager from '../../data/managers/Client/ClientDataManager';
// import ManagerDataManager from '../../data/managers/Manager/ManagerDataManager';
import MedicNsProDeviceDataManager from '../../data/managers/Medic/MedicNsProDeviceDataManager';

// type DataManager = MedicDataManager;

interface AppContextItems {
  // userId: string;
  // dataManager: DataManager | null;
  // userDataManager: UserDataManager;
  // invite: Invite | null;
  // setInvite: Dispatch<SetStateAction<Invite | null>>
  nsProDevice: MedicNsProDeviceDataManager | null;
  nsProConnected: boolean;
  nsServerRunning: boolean;
}

export const AppContext = createContext<AppContextItems | null>(null);

type Props = { children: ReactNode };

const StateProvider = ({ children }: Props): ReactElement => {
  const { loggedInUser, userRoles } = useContext(AuthContext)!;

  const [nsProDevice, setNsProDevice] = useState<MedicNsProDeviceDataManager | null>(null);
  const [nsProConnected, setNsProConnected] = useState(false);
  const [nsServerRunning, setNsServerConnected] = useState(false);
  const initComplete = useRef(false);

  const setupDataManager = useCallback(async () => {
    if ((loggedInUser)) {
      if (userRoles.includes('medic')) {
        initComplete.current = true;
        setNsProDevice(new MedicNsProDeviceDataManager({ setNsProConnected, setNsServerConnected }));
      }
    }
  }, [userRoles, loggedInUser, setNsProDevice]);

  useEffect(() => {
    if (loggedInUser && userRoles && !initComplete.current) {
      void setupDataManager();
    }
  }, [loggedInUser, userRoles, setupDataManager]);

  return (
    <AppContext.Provider value={{
      nsProConnected,
      nsProDevice,
      nsServerRunning,
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default StateProvider;
