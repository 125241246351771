import { WithStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

import {
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
    size: number;
    color: 'primary' | 'secondary' | 'inherit';
    label: string | null;
}

const ProgressIndicator = ({ classes, size = 36, color = 'primary', label = null }: Props): ReactElement => (
  <div className={classes.root}>
    <CircularProgress
      title="progressbar"
      aria-label="progressbar"
      aria-labelledby="progressbar"
      size={size} color={color} />
    {label && <Typography className={classes.label} variant="body2">{label}</Typography>}
  </div>
);

export default withStyles(styles)(ProgressIndicator);
