import React, { useEffect, useState } from 'react';

import { types, query } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import TableTitle from '../../../components/TableTitle';
import Dashboard from '../../../layouts/Dashboard';

import {
  TreatmentTypesTable,
  CreateAdminDialog,
} from './components';

import styles from './styles';

const TreatmentTypes = ({ classes }) => {
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [isCreateStaffDialogOpen, setIsCreateStaffDialogOpen] = useState(false);

  const q = {
    treatment_types: {
      id: types.string,
      name: types.string,
    },
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setTreatmentTypes(data.treatment_types);
    }
    return () => { mounted = false; };
  }, [data]);

  const handleOpenCreateCreateTreatmentType = () => {
    setIsCreateStaffDialogOpen(true);
  };

  const handleCloseCreateTreatmentType = () => {
    setIsCreateStaffDialogOpen(false);
  };

  return (
    <>
      <Dashboard title="Treatment types">
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="Treatment types" />
            <Button data-qa="create-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenCreateCreateTreatmentType()}>Create Treatment Type</Button>
          </div>
          <TreatmentTypesTable data={treatmentTypes} />
        </div>
      </Dashboard>
      {isCreateStaffDialogOpen && <CreateAdminDialog handleClose={handleCloseCreateTreatmentType} />}
    </>
  );
};

export default withStyles(styles)(TreatmentTypes);
