import React, { useRef, useContext,  useCallback } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../../components/StateProvider';
import MedicLayout from '../../../../../layouts/Medic';
import ButtonContainer from '../../../components/ButtonContainer';
import LottieAnimation from '../../../../../components/LottieAnimation';

import styles from './styles';

const PatientScan = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const keyTimer = useRef(null);
  const scanElement = useRef();

  const handleAbort = useCallback(() => {
    nsProDevice.stop();
    history.replace(`/medic/session/${sessionUUID}`);
  }, [nsProDevice, sessionUUID, history]);

  const handleScan = (target) => {
    const element = target;
    clearTimeout(keyTimer.current);
    const patientUUID = element.value;
    if (patientUUID) {
      keyTimer.current = setTimeout(() => {
        element.value = '';
        history.push({
          pathname: `/medic/session/${sessionUUID}/patient/id-check`,
          state: {
            ...history.location.state,
            patientUUID
          }
        });
      }, 700);
    }
  };

  const handleFocus = () => {
    scanElement.current.querySelector('input').focus();
  };

  return (
    <MedicLayout title="Patient ID scan">
      <div className={classes.imageContainer} onKeyUp={handleFocus} onMouseUp={handleFocus} role="button" tabIndex="0">
        <div className={classes.image}>
          <LottieAnimation file="scan-patient" />
          <Typography variant="body1">Scan patient ID</Typography>
        </div>
      </div>
      <div className={classes.scannID}>
        <TextField data-qa="patient-scan-input" ref={scanElement} autoFocus onChange={(event) => handleScan(event.target)} inputRef={(input) => input && input.focus()} />
      </div>
      <ButtonContainer leftAligned>
        <Button data-qa="abort-button" variant="contained" onClick={handleAbort}>Abort</Button>
      </ButtonContainer>
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(PatientScan));
