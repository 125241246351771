const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  select: {
    paddingBottom: theme.spacing(3),
  },
  stepper: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  download: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
  tableError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
    '& > button': {
      width: '100%',
    },
  },
  stepperContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  selectDateFormat: {
    // width: 200,
  },
});

export default styles;
