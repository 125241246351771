import React, { useState, useEffect, useCallback, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../layouts';
import { AuthContext } from '../../../components/AuthProvider';
import ButtonContainer from '../../../components/ButtonContainer';
import { SessionCard } from './components';

import styles from './styles';

const Home = ({ classes, history }) => {
  const { user } = useContext(AuthContext);

  const [patient, setPatient] = useState(null);
  const [sessions, setSessions] = useState([]);

  const q = {
    patients_by_pk: params(
      { id: rawString(user ? user.id : null)},
      {
        id: types.string,
        full_name: types.string,
        sessions: [{
          id: types.string,
          session: {
            id: types.string,
            name: types.string,
            end_date: types.string,
            start_date: types.string,
            medication_type: {
              name: types.string,
              treatment_type: {
                name: types.string,
              },
            },
            clinic: {
              name: types.string,
              address_line_1: types.string,
              address_line_2: types.string,
              address_city: types.string,
              address_postcode: types.string,
            },
          },
        }],
      },
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const pat = data.patients_by_pk;
      if (pat) {
        setPatient(pat);
        setSessions(pat.sessions.map(i => ({
          ...i.session,
          start_date: new Date(i.session.start_date),
        })));
      }
      const { state } = history.location;
      if (state) {
        const { invite } = state;
        if (invite) {
          history.push({
            pathname:'/patient/confirm-invite',
            state: {
              patient: pat,
              invite,
            },
          });
        }
      }
    }
    return () => { mounted = false; };
  }, [data, history]);

  const handleShowQrCode = useCallback(() => {
    history.push({
      pathname: '/patient/ns-id/close/null',
      state: {
        patient,
      }
    });
  }, [history, patient]);

  return (
    <PatientLayout toolbarTitle={`${sessions.length} Session${sessions.length !== 1 ? 's' : ''}`}>
      {/* <Typography className={classes.title} variant="h5">{patient ? patient.full_name : null}</Typography> */}
      <div className={classes.sessions}>
        {sessions.map((session) => <SessionCard key={session.id} session={session} />)}
      </div>
      <ButtonContainer>
        <Button data-qa="showqr-button" variant="contained" color="primary" onClick={handleShowQrCode}>Show QR Code</Button>
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(Home));
