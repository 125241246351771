import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    fontWeight: 700,
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

export default styles;
