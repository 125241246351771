import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Divider,
  TextField,
  withStyles,
} from '@material-ui/core';

import { AuthContext } from '../../../../../components/AuthProvider';
import Alert from '../../../../../components/Alert';
import ConfirmDeleteAdmin from './components/ConfirmDeleteAdmin';
import styles from './styles';

const AdminDetails = ({ history, classes, data }) => {
  const { user } = useContext(AuthContext);

  const [admin, setAdmin] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState(null);
  const [changes, setChanges] = useState({});
  const [errors] = useState({});
  const [helpers] = useState({});

  const [isDeleteAdminDialogOpen, setIsDeleteAdminDialogOpen] = useState(false);

  const m = gql`
    mutation updateAdmin($pk_columns: client_admins_pk_columns_input!, $set: client_admins_set_input!) {
      update_client_admins_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
      }
    }
  `;
  const [updateAdmin, { data: updatedData, error: updateError, loading: saving }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && updatedData) {
      setChanges({});
      setAlert('Account details updated');
    }
    if (mounted && updateError) {
      setError(updateError.error.message);
    }
    return () => { mounted = false; };
  }, [updatedData, updateError]);

  const handleOpenDelete = () => {
    setIsDeleteAdminDialogOpen(true);
  };

  const handleCloseDelete = () => {
    setIsDeleteAdminDialogOpen(false);
  };

  const handleDelete = async () => {
    history.replace('/client/admins');
  };

  const handleSave = async () => {
    updateAdmin({
      pk_columns: {
        id: admin.id,
      },
      set: changes,
    });
  };

  const handleChangeValue = (property, value) => {
    const updates = { ...changes, [property]: value };
    setChanges(updates);
    const hasChanges = Object.keys(updates).some((key) => admin[key] !== updates[key]);
    setIsChanged(hasChanges);
  };

  const handleCloseAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setAdmin(data);
    }
    return () => { mounted = false; };
  }, [data]);

  return (
    <>
      <div className={classes.root}>
        <div autoComplete="off" className={classes.form}>
          <TextField
            data-qa="firstName-textfield"
            required
            label="First name"
            error={errors.firstName}
            helperText={helpers.firstName}
            value={changes.first_name}
            defaultValue={data.first_name}
            autoComplete="off"
            onChange={(event) => handleChangeValue('first_name', event.target.value)}
          />
          <TextField
            data-qa="lastName-textField"
            required
            label="Last name" 
            error={errors.lastName}
            helperText={helpers.lastName}
            value={changes.last_name}
            defaultValue={data.last_name}
            autoComplete="off"
            onChange={(event) => handleChangeValue('last_name', event.target.value)}
          />
        </div>
        {isChanged && (
          <div className={classes.actions}>
            {!saving && (
              <Button data-qa="save-changes-button" color="primary" variant="outlined" onClick={handleSave}>Save Changes</Button>
            )}
            {saving && (
              <CircularProgress />
            )}
          </div>
        )}
        <Divider />
        {user && user.id !== admin.id && (
          <div className={classes.actions}>
            {/* <Button data-qa="signOut-button" color="primary" variant="outlined" onClick={handleSignOut}>Sign out user</Button> */}
            <Button data-qa="delete-user-button" className={classes.deleteAction} variant="outlined" onClick={handleOpenDelete}>Delete User</Button>
          </div>
        )}
      </div>
      {error !== null && (
        <Alert data-qa="alert-error" message={error} severity="error" onClose={handleCloseAlert} />
      )}
      {alert !== null && (
        <Alert data-qa="alert" message={alert} onClose={handleCloseAlert} />
      )}
      {isDeleteAdminDialogOpen && <ConfirmDeleteAdmin adminUUID={admin.id} handleClose={handleCloseDelete} handleDelete={handleDelete} />}
    </>
  );
};

export default withRouter(withStyles(styles)(AdminDetails));
