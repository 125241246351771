const styles = (theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  dates: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
});

export default styles;
