import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const TeamsTable = ({ classes, data, history }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [teams, setTeams] = useState([]);

  const handleTeam = (teamUUID) => {
    history.push(`/manager/clinic/teams/${teamUUID}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setTeams(data);
  }, [data]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, teams.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table data-qa="teams-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Team name</TableCell>
              <TableCell>Sessions</TableCell>
              <TableCell>Staff Members</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.length > 0 && (
              teams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((team) => (
                <TableRow className={classes.tableRow} hover key={team.id} onClick={() => handleTeam(team.id)}>
                  <TableCell component="th" scope="row">{team.name}</TableCell>
                  <TableCell>{team.sessions_aggregate.aggregate.count}</TableCell>
                  <TableCell>{team.team_staff_assignments_aggregate.aggregate.count}</TableCell>
                </TableRow>
              ))
            )}
            {teams.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
            {teams.length === 0 && (
              <TableRow>
                <TableCell className={classes.loader} colSpan={5}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={teams.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage} />
    </Paper>
  );
};

export default withRouter(withStyles(styles)(TeamsTable));
