import React, { useEffect, useContext, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import { AuthContext } from '../../../../../components/AuthProvider';

import TableTitle from '../../../../../components/TableTitle';

import {
  SessionsTable,
  CreateSessionDialog,
} from './components';

import styles from './styles';

const Sessions = ({ classes, history }) => {
  const { clinicUUID } = useParams();
  const { user } = useContext(AuthContext);
  const { client_id: clientUUID } = user ? user : {};

  const todayTimestamp = new Date();
  const [sessions, setSessions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [medicationTypes, setMedicationTypes] = useState([]);

  const where = {clinic_id: { _eq: rawString(clinicUUID) }};
  const q = {
    sessions: params(
      {where},
      [{
        id: types.string,
        name: types.string,
        active: types.boolean,
        start_date: types.string,
        end_date: types.string,
        medication_type: {
          name: types.string,
        },
        clinic: {
          name: types.string,
        },
      }],
    ),
    teams: params(
      {where},
      [{
        id: types.string,
        name: types.string,
      }]
    ),
    medication_types: params(
      {where: {client_id: { _eq: rawString(clientUUID) }}},
      [{
        id: types.string,
        name: types.string,
        treatment_type_id: types.string,
      }]
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setSessions(data.sessions.map(s => ({
        ...s,
        start_date: new Date(s.start_date),
        end_date: new Date(s.end_date),
      })));
      setTeams(data.teams);
      setMedicationTypes(data.medication_types);
    }
    return () => { mounted = false; };
  }, [data]);

  const [isCreateSessionDialogOpen, setIsCreateSessionDialogOpen] = useState(false);

  const handleCloseCreateSession = () => {
    setIsCreateSessionDialogOpen(false);
  };

  const handleCreateSession = (sessionUUID) => {
    handleCloseCreateSession();
    if (sessionUUID) {
      history.push(`/client/clinics/${clinicUUID}/sessions/${sessionUUID}`);
    }
  };

  const handleOpenCreateSession = () => {
    setIsCreateSessionDialogOpen(true);
  };

  return (
    <>
      <div className={classes.table}>
        <div className={classes.tableHeader}>
          <TableTitle title="Upcoming sessions" />
          <Button data-qa="create-session-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleOpenCreateSession}>Create Session</Button>
        </div>
        <SessionsTable data={sessions.filter((i) => i.end_date >= todayTimestamp)} clinicUUID={clinicUUID} />
      </div>
      <div className={classes.table}>
        <div className={classes.tableHeader}>
          <TableTitle title="Past sessions" />
        </div>
        <SessionsTable data={sessions.filter((i) => i.end_date < todayTimestamp)} clinicUUID={clinicUUID}  />
      </div>
      {isCreateSessionDialogOpen && <CreateSessionDialog teams={teams} medicationTypes={medicationTypes} clientUUID={clientUUID} clinicUUID={clinicUUID} handleClose={handleCloseCreateSession} handleCreate={handleCreateSession} />}
    </>
  );
};

export default withRouter(withStyles(styles)(Sessions));
