import { Button, CircularProgress, Dialog, DialogContent, Slide, Typography, withStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { WithStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';

import { ArrowBack as BackIcon } from '@material-ui/icons';
import React, { ReactElement, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppContext } from '../../../../components/StateProvider';

import styles from './styles';

const Transition: React.ComponentType<TransitionProps & { children?: ReactElement<unknown, string> }> = React.forwardRef(({
  children,
  ...props
}, ref) => <Slide direction="up" ref={ref} {...props}>{children as ReactElement}</Slide>);

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
}

interface UrlParams {
  sessionUUID: string,
}

const NsProConnection = ({ history, classes }: Props) => {
  const { sessionUUID } = useParams<UrlParams>();
  const { nsProDevice, nsServerRunning } = useContext(AppContext)!;

  const handleAbort = () => {
    if (nsProDevice) {
      nsProDevice.stop();
    }
    history.replace(`/medic/session/${sessionUUID}`);
  };

  return (
    <Dialog data-qa="nspro-dialog" fullScreen open TransitionComponent={Transition}>
      <DialogContent>
        <div className={classes.root}>
          <img src="/images/static/ns-pro.jpg" alt="NeedleSmart Pro" />
          <div className={classes.progress}>
            <CircularProgress />
            <Typography className={classes.progressLabel} variant="body1">Awaiting NS Pro connection</Typography>
          </div>
          {!nsServerRunning && (
            <Typography className={classes.notRunning} variant="body1">NeedleSmart server is not running</Typography>
          )}
          <div className={classes.signOut}>
            <Button data-qa="abort-button" variant="contained" onClick={handleAbort} startIcon={<BackIcon />}>Abort</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(withStyles(styles)(NsProConnection));
