import React, { useEffect, useState } from 'react';

import { types, query } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import {
  Add as AddIcon,
} from '@material-ui/icons';

import TableTitle from '../../../components/TableTitle';
import Dashboard from '../../../layouts/Dashboard';

import {
  AdminTable,
  CreateAdminDialog,
} from './components';

import styles from './styles';

const Admins = ({ classes }) => {

  const [admins, setAdmins] = useState([]);
  const [isCreateStaffDialogOpen, setIsCreateStaffDialogOpen] = useState(false);

  const q = {
    admins: {
      id: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      email: types.string,
    },
  };

  const { data, refetch } = useQuery(gql`${query(q)}`, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setAdmins(data.admins);
    }
    return () => { mounted = false; };
  }, [data]);

  const handleOpenCreateAdmin = () => {
    setIsCreateStaffDialogOpen(true);
  };

  const handleCloseCreateAdmin = () => {
    setIsCreateStaffDialogOpen(false);
  };

  const handleCreate = () => {
    refetch();
    handleCloseCreateAdmin();
  };

  return (
    <>
      <Dashboard title="Admins">
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <TableTitle title="Admins" />
            <Button data-qa="create-admin-button" variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenCreateAdmin()}>Create Admin</Button>
          </div>
          <AdminTable data={admins} />
        </div>
      </Dashboard>
      {isCreateStaffDialogOpen && <CreateAdminDialog handleClose={handleCloseCreateAdmin} handleCreate={handleCreate} />}
    </>
  );
};

export default withStyles(styles)(Admins);
