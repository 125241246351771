const styles = (theme) => ({
  root: {
    width: '98%',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  media: {
    height: 140,
  },
  address: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
});

export default styles;
