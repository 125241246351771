import React, { useEffect, useState } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const CreateClinicDialog = ({ classes, handleClose, handleCreate, clientUUID }) => {
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [errors, setErrors] = useState({});

  const m = gql`
    mutation addClinic($object: clinics_insert_input!) {
      insert_clinics_one(object: $object) {
        id
        name
        address_line_1
        address_line_2
        address_city
        address_postcode
      }
    }
  `;

  const [addClinic, { data, loading }] = useMutation(m, {
    update(cache, { data: { insert_clinics_one } }) {
      cache.modify({
        fields: {
          clinics(existing = []) {
            const n = cache.writeFragment({
              data: insert_clinics_one,
              fragment: gql`
                fragment NewClinic on Clinic {
                  id
                  name
                  address_line_1
                  address_line_2
                  address_city
                  address_postcode
                }
              `
            });
            return [...existing, n];
          },
        }
      });
    }
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleCreate();
    }
    return () => { mounted = false; };
  }, [data, handleCreate]);

  const handleContinueCreate = () => {
    let error = false;
    setErrors({});
    if (!name) {
      setErrors((e) => ({ ...e, name: 'Please provide a name' }));
      error = true;
    }
    if (!address1) {
      setErrors((e) => ({ ...e, address1: 'Please provide a address1' }));
      error = true;
    }
    if (!address2) {
      setErrors((e) => ({ ...e, address2: 'Please provide a address2' }));
      error = true;
    }
    if (!city) {
      setErrors((e) => ({ ...e, city: 'Please provide a city' }));
      error = true;
    }
    if (!postcode) {
      setErrors((e) => ({ ...e, postcode: 'Please provide a postcode' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = () => {
    addClinic({
      variables: {
        object: {
          client_id: clientUUID,
          name,
          address_line_1: address1,
          address_line_2: address2,
          address_city: city,
          address_postcode: postcode,
        },
      },
    });
  };

  return (
    <Dialog data-qa="create-clinic-dialog" open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle data-qa="dialog-title">Create Clinic</DialogTitle>
      <DialogContent>
        <div autoComplete="off" className={classes.form}>
          <TextField data-qa="clinic-name-input" required label="Clinic name" name="name" error={errors.name} helperText={errors.name} value={name} autoComplete="off" onChange={(event) => setName(event.target.value)} />
          <TextField data-qa="clinic-address1-input" required label="Address 1" name="address1" error={errors.address1} helperText={errors.address1} value={address1} autoComplete="off" onChange={(event) => setAddress1(event.target.value)} />
          <TextField data-qa="clinic-address2-input" required label="Address 2" name="address2" error={errors.address2} helperText={errors.address2} value={address2} autoComplete="off" onChange={(event) => setAddress2(event.target.value)} />
          <TextField data-qa="clinic-city-input" required label="City" name="city" error={errors.city} helperText={errors.city} value={city} autoComplete="off" onChange={(event) => setCity(event.target.value)} />
          <TextField data-qa="clinic-postcode-input" required label="Postcode" name="postcode" error={errors.postcode} helperText={errors.postcode} value={postcode} autoComplete="off" onChange={(event) => setPostcode(event.target.value)} />
        </div>
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button data-qa="circular-progress-button" color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="cancel-button" onClick={handleClose} color="primary">Cancel</Button>
              <Button data-qa="create-button" onClick={handleContinueCreate} color="primary">Create</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CreateClinicDialog);
