import React, { useState, useEffect } from 'react';

import { types, query } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import { DataCard, DataCardContainer } from '../../../components/DataCard';

import styles from './styles';

const Overview = ({ classes }) => {

  const [clients, setClients] = useState(null);
  const [sessions, setSessions] = useState(null);

  const count = { aggregate: { count: types.number }};
  const q = {
    clients_aggregate: count,
    sessions_aggregate: count,
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClients(data.clients_aggregate.aggregate.count);
      setSessions(data.sessions_aggregate.aggregate.count);
    }
    return () => { mounted = false; };
  }, [data]);

  return (
    <Dashboard title="Dashboard">
      <DataCardContainer>
        <DataCard value={clients} label="Clients" />
        <DataCard value={sessions} label="Sessions" />
      </DataCardContainer>
    </Dashboard>
  );
};

export default withStyles(styles)(Overview);
