import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';

import {
  Avatar,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  withStyles
} from '@material-ui/core';

import styles from './styles';

const StaffMembersTable = ({ history, classes, data = [] }) => {
  const { clinicUUID } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [staffMembers, setStaffMembers] = useState([]);

  const handleStaffMember = (staffMemberUUID) => {
    history.push(`/client/clinics/${clinicUUID}/staff/${staffMemberUUID}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setStaffMembers(data);
  }, [data]);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, staffMembers.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table data-qa="staff-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {staffMembers.length > 0 &&
              staffMembers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((staffMember) => (
                  <TableRow
                    className={classes.tableRow}
                    key={staffMember.id}
                    onClick={() => handleStaffMember(staffMember.id)}
                  >
                    <TableCell component="th" scope="row">
                      <div className={classes.name}>
                        <Avatar className={classes.avatar}>
                          {staffMember.full_name
                            .split(' ')
                            .map((i) => i[0])
                            .join('')}
                        </Avatar>
                        {staffMember.full_name}
                      </div>
                    </TableCell>
                    <TableCell>{staffMember.email}</TableCell>
                  </TableRow>
                ))}
            {staffMembers.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
            {staffMembers.length === 0 && (
              <TableRow>
                <TableCell className={classes.loader} colSpan={4}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={staffMembers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default withRouter(withStyles(styles)(StaffMembersTable));
