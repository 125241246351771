import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  IconButton,
  SvgIcon,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Table,
  withStyles,
} from '@material-ui/core';

import {
  PlayCircleFilled as StartIcon,
  CheckCircle as IsVaccinatedIcon,
  Cancel as NotVaccinatedIcon,
  RemoveCircle as NoStartIcon,
} from '@material-ui/icons';

import { AppContext } from '../../../../../components/StateProvider';
import QrCodeIcon from '../../../../../components/Icons/QrCodeIcon';
import UserIdDialog from '../../../../../components/UserIdDialog';

import styles from './styles';

const PatientsTable = ({ history, classes, patients = [], session, staffUUID, searchQuery = null, showQr = true }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [patientData, setPatientData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStart = (patientUUID) => {
    nsProDevice.start();
    history.push({
      pathname: `/medic/session/${sessionUUID}/patient/id-check`,
      state: {
        session,
        patientUUID,
        staffUUID,
      },
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setPatientData(patients);
    }
    return () => { mounted = false; };
  }, [patients]);

  const showUserIdQrCode = (patient) => {
    setSelectedPatient(patient);
  };

  const clearSelectedPatient = () => {
    setSelectedPatient(null);
  };

  const filterPatientsOnSearchQuery = () => patientData.filter((n) => !searchQuery || n.full_name.toLowerCase().includes(searchQuery.toLowerCase()));

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, patientData.length - page * rowsPerPage);

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Gender</TableCell>
                <TableCell align="center">Vaccinated</TableCell>
                {showQr && <TableCell align="center">QR</TableCell>}
                <TableCell align="center">Start</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchQuery && filterPatientsOnSearchQuery().length === 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} align="center">No patients match search query</TableCell>
                </TableRow>
              )}
              {patientData.length > 0 && filterPatientsOnSearchQuery().length > 0 && filterPatientsOnSearchQuery().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((patient) => {
                const isVaccinated = patient.results.length > 0;
                return (
                  <TableRow className={isVaccinated ? classes.isVaccinatedTableRow : classes.tableRow} key={patient.id}>
                    <TableCell component="th" scope="row">
                      <div className={classes.name}>{patient.full_name}</div>
                    </TableCell>
                    <TableCell align="center">{patient.gender}</TableCell>
                    <TableCell align="center">{isVaccinated ? <IsVaccinatedIcon className={classes.isVaccinated} /> : <NotVaccinatedIcon /> }</TableCell>
                    {showQr && (
                      <TableCell className={classes.smallCell} align="center">
                        <IconButton onClick={() => showUserIdQrCode(patient)}>
                          <SvgIcon>
                            <QrCodeIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell className={classes.smallCell} align="center">
                      {isVaccinated && (
                        <Tooltip title="Cannot start another vaccination"><NoStartIcon className={classes.noStart} /></Tooltip>
                      )}
                      {!isVaccinated && (
                        <Tooltip title="Start vaccination with patient">
                          <IconButton onClick={() => handleStart(patient.id)}>
                            <StartIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {patientData.length > 0 && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
              {patientData.length === 0 && (
                <TableRow>
                  <TableCell className={classes.loader} colSpan={9}>
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={patientData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage} />
      </Paper>
      {selectedPatient && <UserIdDialog code={selectedPatient.id} name={selectedPatient.full_name} handleClose={clearSelectedPatient} />}
    </>
  );
};

export default withRouter(withStyles(styles)(PatientsTable));
