import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Fab,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  PlayCircleFilled as StartIcon,
} from '@material-ui/icons';

import { AuthContext } from '../../../components/AuthProvider';
import { AppContext } from '../../../components/StateProvider';
import SearchInput from '../../../components/SearchInput';
import Dashboard from '../../../layouts/Dashboard';
import { DataCard, DataCardContainer } from '../../../components/DataCard';

import {
  PatientsTable,
} from './components';

import styles from './styles';

const Session = ({ classes, history }) => {
  const { sessionUUID } = useParams();
  const { user } = useContext(AuthContext);
  const { nsProDevice } = useContext(AppContext);

  const [session, setSession] = useState(null);
  const [patients, setPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [medicationType, setMedicationType] = useState(null);
  const [treatmentType, setTreatmentType] = useState(null);
  const [staffId] = useState(user ? user.id : null);

  const [totalPatients, setTotalPatients] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [totalResultsPercent, setTotalResultsPercent] = useState(0);

  const q = {
    sessions_by_pk: params(
      { id: rawString(sessionUUID) },
      {
        id: types.string,
        name: types.string,
        active: types.boolean,
        start_date: types.string,
        end_date: types.string,
        client: {
          id: types.string,
          name: types.string,
        },
        clinic: {
          id: types.string,
          name: types.string,
          address_city: types.string,
        },
        medication_type: {
          id: types.string,
          name: types.string,
        },
        treatment_type: {
          id: types.string,
          name: types.string,
        },
        patients: {
          patient: {
            id: types.string,
            full_name: types.string,
            gender: types.string,
            results: {
              id: types.string,
            },
          },
        },
      },
    ),
  };

  const { data, loading, refetch } = useQuery(gql`${query(q)}`);

  const updateData = useCallback(() => {
    const ses = data.sessions_by_pk;
    if (ses && ses.id === sessionUUID) {
      setSession({
        ...ses,
        start_date: new Date(ses.start_date),
        end_date: new Date(ses.end_date),
      });
      setMedicationType(ses.medication_type);
      setTreatmentType(ses.treatment_type);
      const patns = ses.patients.map(i => i.patient);
      setPatients(patns);
      const tPatients = patns.length;
      let tResults = patns.filter(i => i.results.length > 0).length;
      let tResultsPercent = tResults > 0 ? ((tResults / tPatients) * 100) : 0;

      if (tResults > tPatients) {
        tResults = tPatients;
      }
      if (tResultsPercent > 100) {
        tResultsPercent = 100;
      }

      setTotalPatients(tPatients);
      setTotalResults(tResults);
      setTotalResultsPercent(Math.ceil(tResultsPercent));
    }
  }, [data, sessionUUID]);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      updateData();
    }
    return () => { mounted = false; };
  }, [data, updateData]);

  const delayedRefetch = useCallback(() => {
    const { state } = history.location;
    if (state && state.update) {
      setTimeout(() => {
        refetch();
      }, 800);
    }
  }, [history, refetch]);

  useEffect(() => {
    let mounted = true;
    if (mounted && history) {
      delayedRefetch();
    }
    return () => { mounted = false; };
  }, [history, delayedRefetch]);

  const handleStart = () => {
    nsProDevice.start();
    history.push({
      pathname: `/medic/session/${sessionUUID}/welcome`,
      state: {
        session,
        staffUUID: staffId,
      }
    });
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleClearSearch = () => {
    setSearchQuery(null);
  };

  const getDateFromNow = (date) => moment(date).fromNow();

  return (
    <>
      <Dashboard title={`Session - ${session ? session.name : ''}`}>
        {session && (
          <>
            <DataCardContainer>
              <DataCard value={session.name} label="Session name" subLabel={`ending ${getDateFromNow(session.end_date)}`} />
              <DataCard value={treatmentType ? treatmentType.name : null} label="Treatment type" />
              <DataCard value={medicationType ? medicationType.name : null} label="Medication type" />
              <DataCard value={`${totalResults} / ${totalPatients || 0}`} label="Completed vaccinations" subLabel={`${totalResultsPercent}%`} />
            </DataCardContainer>
            <div className={classes.table}>
              <div className={classes.tableHeader}>
                {!loading && patients.length > 0 &&
                  <SearchInput
                    className={classes.searchInput}
                    placeholder="Search for a patient"
                    onChange={(e) => handleSearch(e.target.value)}
                    onClear={handleClearSearch}
                  />}
                <div className={classes.actions}>
                  {loading && (
                    <Button color="primary" variant="outlined"><CircularProgress /></Button>
                  )}
                  {!loading && (
                    <Button color="primary" variant="outlined" onClick={() => refetch()}>Refresh</Button>
                  )}
                </div>
              </div>
              {loading && <CircularProgress />}
              {!loading && patients.length === 0 && <Typography variant="h2">No Patients</Typography>}
              {!loading && patients.length > 0 && (
                <>
                  <PatientsTable patients={patients} session={session} staffUUID={staffId} searchQuery={searchQuery} />
                  <Fab className={classes.fab} color="primary" variant="extended" onClick={handleStart}>
                    <StartIcon className={classes.fabIcon} />
                    Start
                  </Fab>
                </>
              )}
            </div>
          </>
        )}
      </Dashboard>
    </>
  );
};

export default withStyles(styles)(Session);
