import React, { useEffect, useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import ButtonContainer from '../../../../components/ButtonContainer';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import BodyText from '../../../../components/BodyText';

import styles from './styles';

const SignUpCreatePatient = ({ history }) => {
  const [signingUp, setSigningUp] = useState(true);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [signUpData, setSignUpData] = useState(null);
  
  const m = gql`
    mutation addPatient($object: patients_insert_input!) {
      insert_patients_one(object: $object) {
        id
      }
    }
  `;

  const [addPatient, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      history.push({
        pathname: '/patient/sign-up-confirm',
        state: {
          ...history.location.state,
          patient: {
            ...history.location.state.patient,
            id: data.insert_patients_one.id,
            sub: signUpData.userSub,
            confirmed: signUpData.userConfirmed,
          }
        }
      });
    }
    return () => { mounted = false; };
  }, [data, signUpData, history]);

  useEffect(() => {
    let mounted = true;
    if (mounted && signUpData) {
      const { patient } = history.location.state;
      addPatient({
        variables: {
          object: {
            user_id: signUpData.userSub,
            first_name: patient.firstName,
            last_name: patient.lastName,
            email: patient.emailAddress.toLowerCase(),
          }
        },
      });
    }
    return () => { mounted = false; };
  }, [signUpData, history, addPatient]);

  const signUpUser = useCallback(async () => {
    const { patient } = history.location.state;
    const userData = {
      username: patient.emailAddress,
      password: atob(patient.password),
      attributes: {
        email: patient.emailAddress.toLowerCase(),
        given_name: patient.firstName,
        family_name: patient.lastName,
      },
    };

    try {
      const signUpResult = await Auth.signUp(userData);
      const { userSub, userConfirmed } = signUpResult;
      setSignUpData({ userSub, userConfirmed });
    } catch(err) {
      setSigningUp(false);
      setError(err.message);
      setErrorCode(err.code);
    }
  }, [history]);

  const handleSignIn = () => {
    const { invite, patient } = history.location.state;
    history.replace({
      pathname: '/sign-in',
      state: {
        invite,
        email: patient.emailAddress.toLowerCase(),
      },
    });
  };

  useEffect(() => {
    const { invite } = history.location.state;
    if (!invite) {
      setSigningUp(false);
      setError('Invite ID not found. Please try using your invite link again');
      return;
    }
    signUpUser();
  }, [history, signUpUser]);

  return (
    <PatientLayout toolbarTitle="Creating your account">
      {(signingUp || loading) && (
        <ProgressIndicator size={50} />
      )}
      {error && (
        <BodyText align="center" full>{error}</BodyText>
      )}
      {errorCode === 'UsernameExistsException' && (
        <ButtonContainer>
          <Button data-qa="sign-in-button" variant="contained" color="primary" onClick={handleSignIn}>SIGN IN</Button>
        </ButtonContainer>
      )}
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpCreatePatient));
