import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  FormHelperText,
  FormControl,
  FormLabel,
  withStyles,
  CircularProgress,
} from '@material-ui/core';

import { PatientLayout } from '../../../../layouts';
import ButtonContainer from '../../../../components/ButtonContainer';
import DatePicker from '../../../../components/DatePicker';
import GendersRadioGroup from '../../../../components/GendersRadioGroup';

import styles from './styles';

const SignUpDetailsPersonal = ({ classes, history }) => {
  const createDateString = (value) => {
    const d = new Date(value);
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`;
  };

  const createDate = (value) => new Date(value);

  const today = new Date();
  const date = createDateString(today);

  const [dob, setDob] = useState(date);
  const [gender, setGender] = useState('');
  const [errors, setErrors] = useState({});
  
  const m = gql`
    mutation updatePatient($pk_columns: patients_pk_columns_input!, $set: patients_set_input!) {
      update_patients_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
      }
    }
  `;

  const [updatePatient, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      history.push({
        pathname: '/patient/sign-up-details-address',
        state: {
          ...history.location.state,
        },
      });
    }
    return () => { mounted = false; };
  }, [history, data]);
  
  const handleContinue = useCallback(() => {
    const { patient } = history.location.state;
    updatePatient({
      variables: {
        pk_columns: {
          id: patient.id,
        },
        set: {
          dob: new Date(dob).toISOString(),
          gender: gender,
        },
      }
    });
  }, [history, dob, gender, updatePatient]);

  const checkDobIsToday = () => {
    const a = createDate(dob);
    const b = createDate(today);
    a.setHours(0, 0, 0, 0);
    b.setHours(0, 0, 0, 0);
    return a.getTime() === b.getTime();
  };

  const checkDobIsFuture = () => {
    const a = createDate(dob);
    const b = createDate(today);
    b.setHours(0, 0, 0, 0);
    return a.getTime() > b.getTime();
  };

  const handleSignUp = () => {
    setErrors({});
    let error = false;
    if (checkDobIsToday()) {
      setErrors((e) => ({ ...e, dob: 'Please provide a date of birth' }));
      error = true;
    }
    if (checkDobIsFuture()) {
      setErrors((e) => ({ ...e, dob: 'Please provide a date in the past' }));
      error = true;
    }
    if (!gender) {
      setErrors((e) => ({ ...e, gender: 'Please provide a gender' }));
      error = true;
    }

    if (!error) {
      handleContinue();
    }
  };

  return (
    <PatientLayout toolbarTitle="Your details" canGoBack>
      <div className={classes.form} noValidate autoComplete="off">
        <FormControl component="fieldset" error={errors.gender}>
          <FormLabel component="label">Gender</FormLabel>
          <GendersRadioGroup gender={gender} onChange={(event) => setGender(event.target.value || '')} />
          <FormHelperText>{errors.gender}</FormHelperText>
        </FormControl>
        <FormControl className={classes.dob} component="fieldset" error={errors.dob}>
          <FormLabel component="label">Date of birth</FormLabel>
          <DatePicker date={new Date(dob)} size={12} handleDateChange={(date) => setDob(createDateString(date))} yearsFromNow={-110} />
          <FormHelperText>{errors.dob}</FormHelperText>
        </FormControl>
      </div>
      <ButtonContainer>
        {!loading && (
          <Button data-qa="confirm-button" variant="contained" color="primary" onClick={handleSignUp}>CONFIRM</Button>
        )}
        {loading && (
          <CircularProgress />
        )}
      </ButtonContainer>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(SignUpDetailsPersonal));
