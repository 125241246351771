import React, { useEffect, useState } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import styles from './styles';

const CreateMedicationTypeDialog = ({ classes, clientUUID, treatmentTypes, handleClose, handleCreate }) => {
  const [name, setName] = useState('');
  const [treatmentType, setTreatmentType] = useState('');

  const [errors, setErrors] = useState({});
  
  const m = gql`
    mutation addMedicationType($object: medication_types_insert_input!) {
      insert_medication_types_one(object: $object) {
        id
        name
        treatment_type {
          id
          name
        }
      }
    }
  `;

  const [addMedicationType, { data, loading }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleCreate(data.insert_medication_types_one.id);
    }
    return () => { mounted = false; };
  }, [data, handleCreate]);

  const handleContinueCreate = () => {
    let error = false;
    setErrors({});
    if (!name) {
      setErrors((e) => ({ ...e, name: 'Please provide a name' }));
      error = true;
    }
    if (!treatmentType) {
      setErrors((e) => ({ ...e, treatmentType: 'Please provide a treatmentType' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = async () => {
    addMedicationType({
      variables: {
        object: {
          client_id: clientUUID,
          name,
          treatment_type_id: treatmentType.id,
        },
      },
    });
  };

  return (
    <Dialog data-qa="create-medication-dialog" open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create medication type</DialogTitle>
      <DialogContent>
        <div autoComplete="off" className={classes.form}>
          <TextField data-qa="medication-name-input" required label="Name" name="name" error={errors.name} helperText={errors.name} value={name} autoComplete="off" onChange={(event) => setName(event.target.value)} />
          <Autocomplete
            data-qa="medication-treatment-input"
            id="treatmentTypesList"
            options={treatmentTypes}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              if (newValue) {
                setTreatmentType(newValue);
              } else {
                setTreatmentType(null);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Treatment Type" name="treatmentType" error={errors.treatmentType} helperText={errors.treatmentType} />} />
        </div>
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button data-qa="circular-progress-button" color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="cancel-button" onClick={handleClose} color="primary">Cancel</Button>
              <Button data-qa="create-button" onClick={handleContinueCreate} color="primary">Create</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CreateMedicationTypeDialog);
