import { red, orange } from '@material-ui/core/colors';

const styles = (theme) => ({
  table: {
    padding: theme.spacing(4),
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      height: theme.spacing(5),
    },
  },
  tableTitle: {
    flex: '1 1 auto',
  },
  buttonLeft: {
    justifySelf: 'flex-start',
  },
  buttonGroup: {
    flex: '1 1 auto',
    '& > .MuiTextField-root': {
      marginBottom: '0 !important',
    },
  },
  linkInput: {
    marginBottom: 0,
    flex: '1 1 auto',
  },
  actions: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginRight: theme.spacing(2),
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  revokeButton: {
    color: orange[500],
    borderColor: orange[500],
    '&:hover': {
      borderColor: orange[700],
      backgroundColor: orange[50],
    },
  },
  deleteButton: {
    color: red[500],
    borderColor: red[500],
    '&:hover': {
      borderColor: red[700],
      backgroundColor: red[50],
    },
  },
});

export default styles;
