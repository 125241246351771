import { red } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  table: {
    padding: theme.spacing(4),
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(4),
    '& > button': {
      height: theme.spacing(5),
    },
  },
  details: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  actions: {
    alignSelf: 'flex-end',
    '& > button': {
      marginLeft: theme.spacing(2),
    },
  },
  deleteAction: {
    color: red[500],
    borderColor: red[500],
    '&:hover': {
      borderColor: red[700],
      backgroundColor: red[50],
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabError: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
  },
  fabProgress: {
    color: theme.palette.common.white,
  },
});

export default styles;
