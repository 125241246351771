import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Fab,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';

import {
  Save as SaveIcon,
  ErrorOutline as ErrorIcon,
} from '@material-ui/icons';

import Dashboard from '../../../layouts/Dashboard';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';
import Alert from '../../../components/Alert';
import TableTitle from '../../../components/TableTitle';

import {
  EnableDisableMedicationTypeDialog,
  MedicationTypeQualification,
} from './components';

import styles from './styles';

const MedicationType = ({ history, classes }) => {
  const { medicationTypeUUID } = useParams();

  const [medicationType, setMedicationType] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [message, setMessage] = useState(null);

  const [name, setName] = useState('');
  const [isEnabled, setIsEnabled] = useState(true);
  const [qualification, setQualification] = useState(null);
  const [isQualificationEnabled, setQualificationEnabled] = useState(false);
  const [errors, setErrors] = useState({});

  const [isEnableDisableDialogOpen, setIsEnableDisableDialogOpen] = useState(false);

  const crumbs = [
    { label: 'Medication types', to: '/client/medication-types' },
    { label: medicationType ? medicationType.name : '' },
  ];

  const q = {
    medication_types_by_pk: params(
      { id: rawString(medicationTypeUUID) },
      {
        id: types.string,
        name: types.string,
        enabled: types.boolean,
        qualification: types.string,
        treatment_type: {
          id: types.string,
          name: types.string,
        },
      },
    ),
  };

  const { data, refetch } = useQuery(gql`${query(q)}`);

  const m = gql`
    mutation saveMedicationType($pk_columns: medication_types_pk_columns_input!, $set: medication_types_set_input!) {
      update_medication_types_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
        name
        enabled
        qualification
        treatment_type {
          id
          name
        },
      }
    }
  `;

  const [saveMedicationType, { data: updatedData, loading: saving }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const medType = data.medication_types_by_pk;
      if (medType) {
        setMedicationType(medType);
        setName(medType.name);
        setIsEnabled(medType.enabled);
        setQualification(medType.qualification);
        setQualificationEnabled(medType.qualification && medType.qualification.length > 0);
      }
    }
    return () => { mounted = false; };
  }, [data]);

  useEffect(() => {
    let mounted = true;
    if (mounted && updatedData) {
      const medType = updatedData.update_medication_types_by_pk;
      setMedicationType(medType);
      setIsChanged(false);
      setMessage(`Medication type - ${medType.name} - saved`);
    }
    return () => { mounted = false; };
  }, [updatedData]);

  const checkErrors = useCallback(() => {
    let error = false;
    setErrors({});
    if (!name) {
      setErrors((e) => ({ ...e, name: 'Please provide a name' }));
      error = true;
    }
    return error;
  }, [name]);

  const handleContinue = () => {
    if (checkErrors() || hasErrors()) {
      setMessage('Fix errors before saving');
    } else {
      handleSave();
    }
  };

  const handleNameChange = (event) => {
    setIsChanged(true);
    setName(event.target.value);
  };

  const handleQualificationChange = (data) => {
    setIsChanged(true);
    setQualification(data);
  };

  const handleOpenEnableDisable = () => {
    setIsEnableDisableDialogOpen(true);
  };

  const handleCloseEnableDisable = () => {
    setIsEnableDisableDialogOpen(false);
  };

  const handleSaveEnableDisable = (enabled) => {
    setIsEnabled(enabled);
    refetch();
    handleCloseEnableDisable(false);
  };

  const handleSave = async () => {
    saveMedicationType({
      variables: {
        pk_columns: {
          id: medicationTypeUUID,
        },
        set: {
          name,
          qualification,
        }
      }
    });
  };

  const handleEnableQualification = (event) => {
    setIsChanged(true);
    const { checked } = event.target;
    if (checked) {
      setQualification([{ key: uuidv4(), question: '', onInvite: true }]);
      setQualificationEnabled(true);
    } else {
      setQualification(null);
      setQualificationEnabled(false);
    }
  };

  const getFabIcon = () => {
    if (hasErrors()) {
      return <ErrorIcon />;
    }
    if (saving) {
      return <CircularProgress className={classes.fabProgress} />;
    }
    return <SaveIcon />;
  };

  const hasErrors = () => Object.keys(errors).length > 0;

  return (
    <>
      <Dashboard title={`Medication type - ${medicationType ? medicationType.name : ''}`}>
        <NavigationBreadcrumbs crumbs={crumbs} />
        <div className={classes.root}>
          <TableTitle title="Details" />
          <Paper className={classes.details} elevation={1}>
            <div autoComplete="off" className={classes.form}>
              <TextField
                required
                label="Name"
                name="name"
                error={errors.name}
                helperText={errors.name}
                value={name}
                autoComplete="off"
                onChange={handleNameChange} />
            </div>
            <div className={classes.actions}>
              {isEnabled && (
                <Button className={classes.deleteAction} variant="outlined" onClick={handleOpenEnableDisable}>Disable</Button>
              )}
              {!isEnabled && (
                <Button color="primary" variant="outlined" onClick={handleOpenEnableDisable}>Enable</Button>
              )}
            </div>
          </Paper>
          <div className={classes.table}>
            <div className={classes.tableHeader}>
              <TableTitle title="Pre qualification" />
              {medicationType && (
                <FormControlLabel
                  control={(
                    <Switch
                      checked={isQualificationEnabled}
                      label=""
                      onChange={handleEnableQualification}
                      color="primary"
                      name="qualificationSwitch" />
                  )}
                  label={`Pre qualification questions are ${isQualificationEnabled ? 'Enabled' : 'Disabled'} `} />
              )}
            </div>
            {medicationType && isQualificationEnabled && (
              <MedicationTypeQualification data={qualification} setQualifications={handleQualificationChange} />
            )}
          </div>
          {isChanged && (
            <Fab className={hasErrors() ? classes.fabError : classes.fab} color="primary" onClick={handleContinue}>
              {getFabIcon()}
            </Fab>
          )}
          {message && (
            <Alert message={message} onClose={() => setMessage(null)} />
          )}
        </div>
      </Dashboard>
      {isEnableDisableDialogOpen && <EnableDisableMedicationTypeDialog handleClose={handleCloseEnableDisable} handleSave={handleSaveEnableDisable} label={isEnabled ? 'Disable' : 'Enable'} isEnabled={isEnabled} />}
    </>
  );
};

export default withStyles(styles)(MedicationType);
