import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Public
import {
  ForgotPassword,
  InitialStaffSignin,
  Invite,
  SignIn,
  Splash,
} from './views/Public';

/* ---------- Dashboard ---------- */

import {
  Admins as AdminAdmins,
  Admin as AdminAdmin,
  Client as AdminClient,
  ClientAdmin as AdminClientAdmin,
  Clients,
  Session as AdminClientSession,
  Clinic as AdminClientClinic,
  Overview as AdminOverview,
  TreatmentTypes,
} from './views/Admin';

/* ---------- Subject ---------- */

import {
  Home,
  Welcome as PatientWelcome,
  ConfirmInvite as PatientConfirmInvite,
  NsID,
  SignUpCreatePatient,
  SignUpConfirm,
  SignUpDetailsAddress,
  SignUpDetailsPersonal,
  SignUpFinish,
  SignUpName,
  SignUpPassword,
  SignUpTakePhoto,
  SignUpTerms,
} from './views/Patient';

import {
  Overview as ClientOverview,
  Admins as ClientAdmins,
  Admin as ClientAdmin,
  Clinics as ClientClinics,
  Clinic as ClientClinic,
  Session as ClientSession,
  Team as ClientTeam,
  StaffMember as ClientStaffMember,
  MedicationType as ClientMedicationType,
  MedicationTypes as ClientMedicationTypes,
} from './views/Client';

import {
  Overview as ManagerOverview,
  Session as ManagerSession,
  Clinic as ManagerClinic,
  Team as ManagerTeam,
  StaffMember as ManagerStaffMember,
  MedicationTypes as ManagerMedicationTypes,
  MedicationType as ManagerMedicationType,
} from './views/Manager';

import {
  Overview as MedicOverview,
  ActiveSession as MedicActiveSession,
  SelectSession as MedicSelectSession,
  Sessions as MedicSessions,
  Welcome as MedicWelcome,
  PatientScan,
  PatientConfirm,
  PatientPreQualification,
  Vaccine,
  PatientArm,
  NeedleDestruction,
  DvsSignature,
} from './views/Medic';

const Routes = () => (
  <Switch>

    <Route exact path="/sign-in" component={SignIn} />
    <Route exact path="/reset-password" component={ForgotPassword} />
    <Route exact path="/initial-staff-sign-in" component={InitialStaffSignin} />
    <Route exact path="/i/:inviteUUID" component={Invite} />

    <Route exact path="/admin/dashboard" component={AdminOverview} />
    <Route exact path="/admin/clients" component={Clients} />
    <Route exact path="/admin/treatment-types" component={TreatmentTypes} />
    <Route exact path="/admin/clients/:clientUUID" component={AdminClient} />
    <Route exact path="/admin/clients/:clientUUID/admins/:clientAdminUUID" component={AdminClientAdmin} />
    <Route exact path="/admin/clients/:clientUUID/clinic/:clinicUUID" component={AdminClientClinic} />
    <Route exact path="/admin/clients/:clientUUID/clinic/:clinicUUID/sessions/:sessionUUID" component={AdminClientSession} />
    <Route exact path="/admin/clients/:clientUUID/clinic/:clinicUUID/teams/:teamUUID" component={AdminClientClinic} />

    <Route exact path="/admin/admins" component={AdminAdmins} />
    <Route exact path="/admin/admins/:adminUUID" component={AdminAdmin} />

    <Route exact path="/client/dashboard" component={ClientOverview} />
    <Route exact path="/client/admins" component={ClientAdmins} />
    <Route exact path="/client/admins/:clientAdminUUID" component={ClientAdmin} />
    <Route exact path="/client/medication-types" component={ClientMedicationTypes} />
    <Route exact path="/client/medication-types/:medicationTypeUUID" component={ClientMedicationType} />
    <Route exact path="/client/clinics" component={ClientClinics} />
    <Route exact path="/client/clinics/:clinicUUID" component={ClientClinic} />
    <Route exact path="/client/clinics/:clinicUUID/sessions/:sessionUUID" component={ClientSession} />
    <Route exact path="/client/clinics/:clinicUUID/teams/:teamUUID" component={ClientTeam} />
    <Route exact path="/client/clinics/:clinicUUID/staff/:staffUUID" component={ClientStaffMember} />

    <Route exact path="/manager/dashboard" component={ManagerOverview} />
    <Route exact path="/manager/clinic" component={ManagerClinic} />
    <Route exact path="/manager/clinic/sessions/:sessionUUID" component={ManagerSession} />
    <Route exact path="/manager/clinic/teams/:teamUUID" component={ManagerTeam} />
    <Route exact path="/manager/clinic/staff/:staffUUID" component={ManagerStaffMember} />
    <Route exact path="/manager/medication-types" component={ManagerMedicationTypes} />
    <Route exact path="/manager/medication-types/:medicationTypeUUID" component={ManagerMedicationType} />

    <Route exact path="/medic/dashboard" component={MedicOverview} />
    <Route exact path="/medic/sessions" component={MedicSessions} />
    <Route exact path="/medic/session" component={MedicSelectSession} />
    <Route exact path="/medic/session/:sessionUUID" component={MedicActiveSession} />
    <Route exact path="/medic/session/:sessionUUID/welcome" component={MedicWelcome} />
    <Route exact path="/medic/session/:sessionUUID/patient/id-scan" component={PatientScan} />
    <Route exact path="/medic/session/:sessionUUID/patient/id-check" component={PatientConfirm} />
    <Route exact path="/medic/session/:sessionUUID/patient/pre-qualification" component={PatientPreQualification} />
    <Route exact path="/medic/session/:sessionUUID/patient/vaccine" component={Vaccine} />
    <Route exact path="/medic/session/:sessionUUID/patient/arm" component={PatientArm} />
    <Route exact path="/medic/session/:sessionUUID/needle-destruction" component={NeedleDestruction} />
    <Route exact path="/medic/session/:sessionUUID/dvs-signature" component={DvsSignature} />

    <Route exact path="/patient/home" component={Home} />
    <Route exact path="/patient/welcome" component={PatientWelcome} />
    <Route exact path="/patient/confirm-invite" component={PatientConfirmInvite} />
    <Route exact path="/patient/sign-up" component={SignUpName} />
    <Route exact path="/patient/terms" component={SignUpTerms} />
    <Route exact path="/patient/sign-up-password" component={SignUpPassword} />
    <Route exact path="/patient/take-photo" component={SignUpTakePhoto} />
    <Route exact path="/patient/sign-up-details-personal" component={SignUpDetailsPersonal} />
    <Route exact path="/patient/sign-up-details-address" component={SignUpDetailsAddress} />
    <Route exact path="/patient/create-patient" component={SignUpCreatePatient} />
    <Route exact path="/patient/sign-up-confirm" component={SignUpConfirm} />
    <Route exact path="/patient/sign-up-finish" component={SignUpFinish} />
    <Route exact path="/patient/ns-id/:buttonLabel/:nextPath" component={NsID} />

    <Route exact path="" component={Splash} />

  </Switch>
);

export default Routes;
