import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import {
  // Button,
  Card,
  CardActionArea,
  // CardMedia,
  CardContent,
  CardActions,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const SessionCard = ({ classes, history, session }) => {
  // const handleOutcome = () => {
  //   history.push(`/patient/outcome/${event.eventUUID}`);
  // };

  const getDate = (date) => moment(new Date(date)).format('dddd Do MMMM YYYY');

  return (
    <Card className={classes.root} elevation={2}>
      <CardActionArea>
        {/* <CardMedia
          className={classes.media}
          image="/images/static/covid_19.jpg"
          title="Session banner" /> */}
        <CardContent>
          <Typography gutterBottom variant="h5">{`${session.medication_type.treatment_type.name} - ${session.medication_type.name}`}</Typography>
          <Typography gutterBottom variant="h6">{session.clinic.name}</Typography>
          <Typography gutterBottom variant="body2">{session.name}</Typography>
          <Typography variant="body1" color="textSecondary">{getDate(session.start_date)} | {getDate(session.end_date)}</Typography>
          <div className={classes.address}>
            <Typography variant="body2" color="textSecondary">{session.clinic.address_line_1}</Typography>
            <Typography variant="body2" color="textSecondary">{session.clinic.address_line_2}</Typography>
            <Typography variant="body2" color="textSecondary">{session.clinic.address_city}</Typography>
            <Typography variant="body2" color="textSecondary">{session.clinic.address_postcode}</Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {/* <Button size="small" color="primary" onClick={handleOutcome}>View Outcome</Button> */}
      </CardActions>
    </Card>
  );
};

export default withRouter(withStyles(styles)(SessionCard));
