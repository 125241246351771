import React from 'react';
import { withRouter } from 'react-router';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { PatientLayout } from '../../../layouts';
import BodyText from '../../../components/BodyText';
import LottieAnimation from '../../../components/LottieAnimation';

import styles from './styles';

const Welcome = ({ classes, history }) => {
  const handleSignin = () => {
    history.replace('/sign-in');
  };

  const handleCreateAccount = () => {
    history.replace({
      pathname: '/patient/terms',
      state: {
        ...history.location.state,
      },
    });
  };

  return (
    <PatientLayout title="Welcome" noToolbar>
      <div className={classes.body}>
        <LottieAnimation file="calendar" />
        <BodyText>You have been invited to a NeedleSmart vaccination session. If you already have an account please sign in. Otherwise create an account.</BodyText>
      </div>
      <Button data-qa="signin-button" className={classes.button} variant="contained" color="primary" onClick={handleSignin}>Sign in</Button>
      <Button data-qa="createAccount-button" className={classes.button} variant="outlined" color="primary" onClick={handleCreateAccount}>Create account</Button>
    </PatientLayout>
  );
};

export default withRouter(withStyles(styles)(Welcome));
