import { WithStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState } from 'react';

import {
  Typography,
  withStyles, WithWidth,
  withWidth,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, WithWidth {
  title: string;
}

const TableTitle = ({ classes, width, title }: Props): ReactElement => {
  const [isMobile] = useState(['xs', 'sm', 'md'].includes(width));

  return (
    <Typography className={classes.root} variant={isMobile ? 'h4' : 'h3'}>{title}</Typography>
  );
};

export default withStyles(styles)(withWidth()(TableTitle));
