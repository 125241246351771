type HandlerFunction = (..._: unknown[]) => void;

class MedicNsProDeviceDataManager {
  private setNsProConnected: (_: boolean) => void;
  private setNsServerConnected: (_: boolean) => void;
  private started = false;
  private connected = false;
  private deviceID: string | null = null;

  constructor({ setNsProConnected, setNsServerConnected }: {
    setNsProConnected: (_: boolean) => void,
    setNsServerConnected: (_: boolean) => void
  }) {
    this.setNsProConnected = setNsProConnected;
    this.setNsServerConnected = setNsServerConnected;
    this.init();
  }

  init() {
    if (window.electron) {
      window.electron.receive('nsProConnection', (data: boolean) => {
        this.setNsProConnected(data);
      });

      window.electron.receive('isDeviceConnected', (data: boolean) => {
        this.setNsProConnected(data);
      });

      this.setNsServerConnected(true);
      this.checkIsDeviceConnected();
    }
  }

  hasSocket(socket: SocketIOClient.Socket | null): socket is SocketIOClient.Socket {
    return socket !== null;
  }

  checkIsDeviceConnected() {
    window.electron.send('isDeviceConnected');
  }

  readyToMelt(ready: HandlerFunction, response: HandlerFunction) {
    window.electron.receive('readyToMeltResponse', ready);
    window.electron.receive('meltResponse', response);
    window.electron.send('readyToMelt');
  }

  start() {
    this.started = true;
    this.init();
  }

  stop() {
    this.started = false;
    this.setNsProConnected(false);
    this.setNsServerConnected(false);
  }

  isStarted() {
    return this.started;
  }

  isConnected() {
    return this.connected;
  }
}

export default MedicNsProDeviceDataManager;
