import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '60px',
  },
  label: {
    marginTop: theme.spacing(2),
  },
});
export default styles;
