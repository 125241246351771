const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  sessions: {
    overflowY: 'auto',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
});

export default styles;
