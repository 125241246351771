import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Avatar,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import {
  AdminDetails,
} from './components';

import styles from './styles';

const ClientAdmin = ({ classes }) => {
  const { clientUUID, clientAdminUUID } = useParams();

  const [client, setClient] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [photo] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const crumbs = [
    { label: 'Clients', to: '/admin/clients/' },
    { label: client ? client.name : '', to: `/admin/clients/${clientUUID}` },
    { label: 'Admins', to: `/admin/clients/${clientUUID}` },
    { label: admin ? admin.full_name : '' },
  ];

  const q = {
    clients_by_pk: params(
      { id: rawString(clientUUID) },
      {
        name: types.string,
      },
    ),
    client_admins_by_pk: params(
      { id: rawString(clientAdminUUID)},
      {
        id: types.string,
        first_name: types.string,
        last_name: types.string,
        full_name: types.string,
        email: types.string,
      },
    ),
  };

  const { data } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setClient(data.clients_by_pk);
      setAdmin(data.client_admins_by_pk);
    }
    return () => { mounted = false; };
  }, [data]);

  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Dashboard title={`Client admin - ${admin ? admin.full_name : ''}`}>
      <NavigationBreadcrumbs crumbs={crumbs} />
      {admin && (
        <div className={classes.root}>
          <Paper className={classes.profile} elevation={1}>
            <div className={classes.avatarContainer}>
              <Avatar src={photo} className={classes.photo} />
              <Typography variant="h6">{admin.full_name}</Typography>
              <Typography variant="body1">{admin.email.toLowerCase()}</Typography>
            </div>
          </Paper>
          <Paper className={classes.actions} elevation={1}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabIndexChange}>
              <Tab label="Account" />
            </Tabs>
            <Divider />
            <div className={classes.tabContent}>
              {tabIndex === 0 && (
                <AdminDetails data={admin} />
              )}
            </div>
          </Paper>
        </div>
      )}
    </Dashboard>
  );
};

export default withStyles(styles)(ClientAdmin);
