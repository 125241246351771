const styles = (theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  date: {
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  clinic: {
    fontWeight: 700,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  accepting: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  pleaseWait: {
    marginTop: theme.spacing(2),
  },
});

export default styles;
