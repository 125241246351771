import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: 99,
    backgroundImage: 'url(/images/static/ns-background.gif)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%',
    color: theme.palette.common.white,
  },
  title: {
    marginLeft: theme.spacing(1),
    fontSize: '24px',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
  },
  menuButton: {
    marginLeft: '-4px',
    color: theme.palette.common.white,
  },
  notificationsButton: {
    marginLeft: 'auto',
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
  toolbarButton: {
    color: theme.palette.common.white,
  },
});

export default styles;
