const styles = (theme) => ({
  root: {
    display: 'none',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    fontWeight: 700,
    paddingBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    // padding: theme.spacing(3),
    paddingBottom: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    width: theme.spacing(48),
  },
  content: {
    padding: theme.spacing(3),
  },
  logo: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginBottom: theme.spacing(4),
    alignSelf: 'center',
  },
});

export default styles;
