import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const MedicationTypesTable = ({ classes, history, data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [medicationTypes, setMedicationTypes] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMedicationType = (medicationTypeUUID) => {
    history.push(`/client/medication-types/${medicationTypeUUID}`);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      setMedicationTypes(data);
    }
    return () => { mounted = false; };
  }, [data]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, medicationTypes.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table data-qa="medication-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Treatment type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {medicationTypes.length > 0 && (
              medicationTypes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((medicationType) => (
                <TableRow className={classes.tableRow} key={medicationType.id} hover onClick={() => handleMedicationType(medicationType.id)}>
                  <TableCell component="th" scope="row">{medicationType.name}</TableCell>
                  <TableCell>{medicationType.treatment_type.name}</TableCell>
                </TableRow>
              ))
            )}
            {medicationTypes.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
            {medicationTypes.length === 0 && (
              <TableRow>
                <TableCell className={classes.loader} colSpan={8}>
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={medicationTypes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage} />
    </Paper>
  );
};

export default withRouter(withStyles(styles)(MedicationTypesTable));
