const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    display: 'block',
    '& #NS_LOGO': {
      width: '8rem',
      height: '8rem',
      flex: '0 0 auto',
      padding: '0 3rem',
    },
  },
});

export default styles;
