import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';


import {
  Avatar,
  CircularProgress,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const AdminsTable = ({ classes, history, data = [] }) => {
  const { clientUUID } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [clientAdmins, setClientAdmins] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(false);

  const handleClientAdmin = (clientAdminUUID) => {
    history.push(`/admin/clients/${clientUUID}/admins/${clientAdminUUID}`);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setClientAdmins(data);
    }
    setTimeout(() => {
      if (mounted) {
        setFetchTimeout(true);
      }
    }, 3000);
    return () => { mounted = false; };
  }, [data]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, clientAdmins.length - page * rowsPerPage);

  return (
    <Paper className={classes.root}>
      <TableContainer>
        <Table data-qa="admin-table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientAdmins.length > 0 && (
              clientAdmins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((clientAdmin) => (
                <TableRow hover className={classes.tableRow} key={clientAdmin.id} onClick={() => handleClientAdmin(clientAdmin.id)}>
                  <TableCell component="th" scope="row">
                    <div className={classes.name}>
                      <Avatar className={classes.avatar}>{clientAdmin.full_name.split(' ').map(i => i[0]).join('')}</Avatar>
                      {clientAdmin.full_name}
                    </div>
                  </TableCell>
                  <TableCell>{clientAdmin.email}</TableCell>
                </TableRow>
              ))
            )}
            {clientAdmins.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
            {clientAdmins.length === 0 && (
              <TableRow>
                <TableCell className={classes.loader} colSpan={8}>
                  {fetchTimeout && ('No Data')}
                  {!fetchTimeout && (<CircularProgress />)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={clientAdmins.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage} />
    </Paper>
  );
};

export default withRouter(withStyles(styles)(AdminsTable));
