import React, { useState, useEffect, useCallback } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from '../../../../../../../components/DatePicker';

import styles from './styles';

const CreateSessionDialog = ({ classes, clientUUID, clinicUUID, teams, medicationTypes, handleClose, handleCreate }) => {
  const createDateString = (value) => {
    const d = new Date(value);
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`;
  };

  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(createDateString(new Date()));
  const [endDate, setEndDate] = useState(createDateString(new Date(new Date().setDate(new Date().getDate() + 1))));
  const [team, setTeam] = useState(null);
  const [medicationType, setMedicationType] = useState(null);
  const [errors, setErrors] = useState({});
  
  const mSession = gql`
    mutation addSession($object: sessions_insert_input!) {
      insert_sessions_one(object: $object) {
        id
        name
        clinic_id
        start_date
        end_date
      }
    }
  `;
  
  const mInvite = gql`
    mutation addInvite($object: session_invites_insert_input!) {
      insert_session_invites_one(object: $object) {
        id
        session_id
      }
    }
  `;

  const [addSession, { data, loading }] = useMutation(mSession, {
    update(cache, { data: { insert_sessions_one } }) {
      cache.modify({
        fields: {
          sessions(existing = []) {
            const n = cache.writeFragment({
              data: insert_sessions_one,
              fragment: gql`
                fragment NewSession on Session {
                  id
                  name
                  clinic_id
                  start_date
                  end_date
                }
              `
            });
            return [...existing, n];
          },
        }
      });
    }
  });

  const [addInvite, { data: inviteData }] = useMutation(mInvite);

  const handleCreateInvite = useCallback((session) => {
    addInvite({
      variables: {
        object: {
          client_id: clientUUID,
          clinic_id: clinicUUID,
          session_id: session.id,
          name: session.name,
        },
      },
    });
  }, [addInvite, clientUUID, clinicUUID]);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleCreateInvite(data.insert_sessions_one);
    }
    return () => { mounted = false; };
  }, [data, handleCreateInvite]);

  useEffect(() => {
    let mounted = true;
    if (mounted && inviteData) {
      handleCreate(inviteData.insert_session_invites_one.session_id);
    }
    return () => { mounted = false; };
  }, [inviteData, handleCreate]);

  const handleContinueCreate = () => {
    let error = false;
    setErrors({});
    if (!name) {
      setErrors((e) => ({ ...e, name: 'Please provide an event name' }));
      error = true;
    }
    if (new Date(endDate).getTime() < new Date(startDate).getTime()) {
      setErrors((e) => ({ ...e, endDate: 'Please provide an end date on or after the start date' }));
      error = true;
    }
    if (!team) {
      setErrors((e) => ({ ...e, team: 'Please provide a team' }));
      error = true;
    }
    if (!medicationType) {
      setErrors((e) => ({ ...e, medicationType: 'Please provide a medication type' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = () => {
    const { id: teamId } = team;
    const { id: medicationTypeId, treatment_type_id: treatmentTypeId } = medicationType;
    const sDate = new Date(startDate).setHours(0, 0, 0, 0);
    const eDate = new Date(endDate).setHours(23, 59, 59, 999);
    addSession({
      variables: {
        object: {
          name,
          client_id: clientUUID,
          clinic_id: clinicUUID,
          medication_type_id: medicationTypeId,
          treatment_type_id: treatmentTypeId,
          team_id: teamId,
          start_date: new Date(sDate).toISOString(),
          end_date: new Date(eDate).toISOString(),
        },
      },
    });
  };

  return (
    <Dialog data-qa="create-event-dialog" open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create session</DialogTitle>
      <DialogContent>
        <div autoComplete="off" className={classes.form}>
          <TextField data-qa="eventName-input" required label="Event name" name="name" error={errors.name} helperText={errors.name} value={name} autoComplete="off" onChange={(event) => setName(event.target.value || null)} />
          <DatePicker data-qa="startDate-input" date={new Date(startDate)} size={12} handleDateChange={(date) => setStartDate(date ? createDateString(date) : null)} label="Start date" yearsFromNow={10} />
          <DatePicker data-qa="endDate-input" date={new Date(endDate)} size={12} handleDateChange={(date) => setEndDate(date ? createDateString(date) : null)} label="End date" yearsFromNow={10} />
          <Autocomplete
            id="teamList"
            options={teams}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              if (newValue) {
                setTeam(newValue);
              } else {
                setTeam(null);
              }
            }}
            renderInput={(params) => <TextField data-qa="team-input" {...params} label="Team" name="team" error={errors.team} helperText={errors.team} />} />

          <Autocomplete
            id="medicationTypeList"
            options={medicationTypes}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              if (newValue) {
                setMedicationType(newValue);
              } else {
                setMedicationType(null);
              }
            }}
            renderInput={(params) => <TextField data-qa="medication-input" {...params} label="Medication type" name="medicationType" error={errors.medicationType} helperText={errors.medicationType} />} />
        </div>
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button data-qa="circular-progress-button" color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="cancel-button" onClick={handleClose} color="primary">Cancel</Button>
              <Button data-qa="create-button" onClick={handleContinueCreate} color="primary">Create</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CreateSessionDialog);
