const styles = (theme) => ({
  table: {
    padding: theme.spacing(4),
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      height: theme.spacing(5),
    },
  },
  tableTitle: {
    flex: '1 1 auto',
  },
  actions: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginRight: theme.spacing(2),
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
});

export default styles;
