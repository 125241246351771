import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: theme.spacing(40),
      height: theme.spacing(40),
    },
  },
});

export default styles;
