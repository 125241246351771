const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > form': {
      padding: '0 1.5rem  1.5rem  1.5rem',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  responseError: {
    marginBottom: theme.spacing(2),
    color: theme.palette.error.main,
  },
});

export default styles;
