const styles = (theme) => ({
  root: {
    display: 'flex',
    paddingBottom: 0,
  },
  dots: {
    backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.22) 50%, transparent 0%)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'repeat-x',
    backgroundSize: '6px 2px',
    height: 30,
    width: 16,
    marginLeft: 2,
    marginRight: theme.spacing(1),
    flex: '0 0 auto',
  },
  question: {
    flex: '1 1 auto',
    marginRight: theme.spacing(2),
  },
  onInvite: {
    width: 120,
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: 44,
    width: 30,
    minWidth: 30,
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
});

export default styles;
