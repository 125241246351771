import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { types, query, params, rawString } from 'typed-graphqlify';
import { useQuery, gql } from '@apollo/client';

import {
  Button,
  ButtonGroup,
  InputAdornment,
  Tooltip,
  TextField,
  withStyles,
} from '@material-ui/core';

import {
  ErrorOutline as RevokeIcon,
  FileCopy as CopyIcon,
} from '@material-ui/icons';

import Alert from '../../../components/Alert';
import Dashboard from '../../../layouts/Dashboard';
import { DataCard, DataCardContainer } from '../../../components/DataCard';
import TableTitle from '../../../components/TableTitle';
import NavigationBreadcrumbs from '../../../components/NavigationBreadcrumbs';

import StaffMembersTable from '../Clinic/components/StaffMembers/components/StaffMembersTable';

import {
  CancelSessionDialog,
  RevokeInviteDialog,
} from './components';

import styles from './styles';

const todayTimestamp = new Date(new Date().setHours(0, 0, 0, 0));

const Session = ({ classes }) => {
  const { sessionUUID } = useParams();

  const [session, setSession] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [sessionInvite, setSessionInvite] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);
  const [medicationType, setMedicationType] = useState(null);
  const [treatmentType, setTreatmentType] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const [isCancelSessionDialogOpen, setIsCancelSessionDialogOpen] = useState(false);
  const [isRevokeInviteDialogOpen, setIsRevokeInviteDialogOpen] = useState(false);

  const crumbs = [
    { label: clinic ? clinic.name : '', to: '/manager/clinic' },
    { label: 'Sessions', to: '/manager/clinic#sessions' },
    { label: session ? session.name : '' },
  ];

  const count = { aggregate: { count: types.number }};
  const q = {
    sessions_by_pk: params(
      { id: rawString(sessionUUID) },
      {
        id: types.string,
        name: types.string,
        active: types.boolean,
        start_date: types.string,
        end_date: types.string,
        clinic: {
          id: types.string,
          name: types.string,
        },
        treatment_type: {
          id: types.string,
          name: types.string,
        },
        medication_type: {
          id: types.string,
          name: types.string,
        },
        invite: params(
          {where: {active: {_eq: rawString(true)}}},
          {
            id: types.string,
            client_id : types.string,
            clinic_id: types.string,
            session_id: types.string,
            name: types.string,
          },
        ),
        team: {
          id: types.string,
          name: types.string,
          team_staff_assignments_aggregate: count,
          sessions_aggregate: count,
          team_staff_assignments: {
            client_staff: {
              id: types.string,
              full_name: types.string,
              email: types.string,
            },
          },
        },
      },
    ),
  };

  const { data, refetch } = useQuery(gql`${query(q)}`);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const ses = data.sessions_by_pk;
      if (ses) {
        setSession({
          ...ses,
          start_date: new Date(ses.start_date),
          end_date: new Date(ses.end_date),
        });
        setClinic(ses.clinic);
        setSessionInvite(ses.invite[0] || null);
        setMedicationType(ses.medication_type);
        setTreatmentType(ses.treatment_type);
        setStaffMembers(ses.team.team_staff_assignments.reduce((a, b) => [...a, b.client_staff], []));
      }
    }
    return () => { mounted = false; };
  }, [data]);

  const handleOpenCancelSession = () => {
    setIsCancelSessionDialogOpen(true);
  };

  const handleCloseCancelSession = () => {
    refetch();
    setIsCancelSessionDialogOpen(false);
  };

  const handleOpenRevokeInvite = () => {
    setIsRevokeInviteDialogOpen(true);
  };

  const handleCloseRevokeInvite = () => {
    refetch();
    setIsRevokeInviteDialogOpen(false);
  };

  const handleCopyInvite = async () => {
    const copyText = document.getElementById('invite-link');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setIsCopied(true);
  };

  const getDate = (date) => moment(new Date(date)).format('dddd Do MMMM YYYY');

  const getInviteLink = () => `${window.location.origin}/i/${sessionInvite.id}`;

  return (
    <>
      <Dashboard title={`Session - ${session ? session.name : ''}`}>
        <NavigationBreadcrumbs crumbs={crumbs} />
        <DataCardContainer>
          {session && session.active && (
            <DataCard value={session ? getDate(session.start_date) : null} label={session ? `${moment(session.start_date).fromNow()} for ${moment(session.end_date).from(session.start_date, true)}` : null} />
          )}
          {session && !session.active && (
            <DataCard label={session ? getDate(session.start_date) : null} value={'Canceled'} />
          )}
        </DataCardContainer>
        <DataCardContainer>
          <DataCard value={session ? session.name : null} label="Session name" />
          <DataCard value={clinic ? clinic.name : null} label="Clinic" />
          <DataCard value={treatmentType ? treatmentType.name : null} label="Treatment Type" />
          <DataCard value={medicationType ? medicationType.name : null} label="Medication Type" />
        </DataCardContainer>
        <div className={classes.actions}>
          {sessionInvite && session && session.end_date > todayTimestamp && session.active && (
            <ButtonGroup className={classes.buttonGroup} variant="outlined" color="primary">
              <TextField data-qa="revoke-link" className={classes.linkInput} id="invite-link" value={getInviteLink()} InputProps={{ startAdornment: <InputAdornment position="start">Session invite link:</InputAdornment> }} />
              <Tooltip title="Copy invite link">
                <Button data-qa="copy-button" onClick={handleCopyInvite}>
                  <CopyIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Revoke invite link">
                <Button data-qa="revoke-button" className={classes.revokeButton} onClick={handleOpenRevokeInvite}>
                  <RevokeIcon />
                </Button>
              </Tooltip>
              {session.start_date > todayTimestamp && (
                <Button data-qa="delete-button" className={classes.deleteButton} onClick={handleOpenCancelSession}>Cancel Session</Button>
              )}
            </ButtonGroup>
          )}
          {session && session.start_date > todayTimestamp && !session.active && (
            <Button variant="outlined" color="primary" data-qa="enable-button" onClick={handleOpenCancelSession}>Start Session</Button>
          )}
        </div>
        {session && (
          <div className={classes.table}>
            <TableTitle title={session.team.name} />
            <StaffMembersTable data={staffMembers} />
          </div>
        )}
        {isCopied && (
          <Alert data-qa="invite-link-alert" message="Invite link has been copied to the clipboard" />
        )}
      </Dashboard>
      {isCancelSessionDialogOpen && <CancelSessionDialog sessionId={sessionUUID} handleClose={handleCloseCancelSession} isCanceled={!session.active} label={session.active ? 'Cancel' : 'Start'} />}
      {isRevokeInviteDialogOpen && <RevokeInviteDialog sessionInvite={sessionInvite} handleClose={handleCloseRevokeInvite} />}
    </>
  );
};

export default withStyles(styles)(Session);
