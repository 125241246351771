import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from 'react-router-dom';

import {
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { CenteredLayout, PatientLayout } from '../../../layouts';

import ButtonContainer from '../../../components/ButtonContainer';
import BodyText from '../../../components/BodyText';
import ProgressIndicator from '../../../components/ProgressIndicator';
import NsLogo from '../../../components/Logos/NsLogo';
import { validatePassword, validateEmailAddress } from '../../../utils/validators';

import styles from './styles';

const ForgotPassword = ({ classes, history }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const query = new URLSearchParams(useLocation().search);
  const queryEmail = query.has('e') ? atob(query.get('e')) : '';

  const [emailAddress, setEmailAddress] = useState(queryEmail);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState({});
  const [reseting, setReseting] = useState(false);

  const handleSubmit = () => {
    let error = false;
    setErrors({});
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!verificationCode) {
      setErrors((e) => ({ ...e, verificationCode: 'Please provide an verification code' }));
      error = true;
    } else if (verificationCode.length !== 6) {
      setErrors((e) => ({ ...e, verificationCode: 'Please provide an verification code with 6 digits' }));
      error = true;
    }
    if (!password) {
      setErrors((e) => ({ ...e, password: 'Please provide a password' }));
      error = true;
    } else if (!validatePassword(password)) {
      setErrors((e) => ({ ...e, password: 'Passwords must be at least 8 characters long and contain at least 1 lower case letter, upper case letter and a number' }));
      error = true;
    }
    if (!passwordConfirm) {
      setErrors((e) => ({ ...e, passwordConfirm: 'Please provide a password' }));
      error = true;
    } else if (!validatePassword(passwordConfirm)) {
      setErrors((e) => ({ ...e, passwordConfirm: 'Passwords must be at least 8 characters long and contain at least 1 lower case letter, upper case letter and a number' }));
      error = true;
    }
    if (password !== passwordConfirm) {
      setErrors((e) => ({ ...e, password: 'Passwords must match', passwordConfirm: 'Passwords must match' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = async () => {
    setReseting(true);
    try {
      await Auth.forgotPasswordSubmit(emailAddress.toLowerCase(), verificationCode, password);
      setErrors((e) => ({ ...e, reset: 'Password has been reset. Please login again' }));
      setTimeout(() => {
        history.push('/sign-in');
      }, 1500);
    } catch (err) {
      setReseting(false);
      setErrors((e) => ({ ...e, reset: err.message | 'Unknown error' }));
    }
  };

  const getContent = () => (
    <>
      <div className={classes.form} autoComplete="off">
        <TextField
          data-qa="email-textField"
          required
          label="Email address"
          type="email"
          error={errors.emailAddress}
          helperText={errors.emailAddress}
          value={emailAddress}
          onChange={(event) => setEmailAddress(event.target.value || null)}
        />
        <TextField
          required
          label="Verification code"
          type="number"
          error={errors.verificationCode}
          helperText={errors.verificationCode}
          value={verificationCode}
          onChange={(event) => setVerificationCode(event.target.value || null)}
        />
        <TextField
          required
          label="New password"
          type="password"
          error={errors.password}
          helperText={errors.password}
          value={password}
          onChange={(event) => setPassword(event.target.value || null)}
        />
        <TextField
          required
          label="Confirm new password"
          type="password"
          error={errors.passwordConfirm}
          helperText={errors.passwordConfirm}
          value={passwordConfirm}
          onChange={(event) => setPasswordConfirm(event.target.value || null)}
        />
      </div>
      {errors.reset && (
        <BodyText>{errors.reset}</BodyText>
      )}
      <ButtonContainer>
        {!reseting && <Button data-qa="reset-password-button" variant="contained" color="primary" onClick={handleSubmit}>RESET PASSWORD</Button>}
        {reseting && <ProgressIndicator label="Resetting password" />}
      </ButtonContainer>
    </>
  );

  if (isMobile) {
    return (
      <PatientLayout toolbarTitle="Reset Password">
        <Typography className={classes.title} variant="body1">Check your email for a 6-digit NeedleSmart verification code</Typography>
        {getContent()}
      </PatientLayout>
    );
  }

  return (
    <CenteredLayout>
      <div className={classes.modal}>
        <div className={classes.logo}>
          <NsLogo />
        </div>
        <BodyText align="center">Please check your email for a NeedleSmart ID verification code</BodyText>
        <div className={classes.content}>
          {getContent()}
        </div>
      </div>
    </CenteredLayout>
  );
};

export default withStyles(styles)(ForgotPassword);
