import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Divider,
  TextField,
  withStyles,
} from '@material-ui/core';

import Alert from '../../../../../components/Alert';
import ConfirmDeleteStaffMember from './components/ConfirmDeleteStaffMember';
import styles from './styles';

const StaffMemberDetails = ({ classes, data }) => {
  const [staffMember] = useState(data);
  const [isChanged, setIsChanged] = useState(false);
  const [deleting] = useState(false);
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState(null);
  const [changes, setChanges] = useState({});
  const [errors] = useState({});
  const [helpers] = useState({});

  const m = gql`
    mutation updateStaffMember($pk_columns: client_staff_pk_columns_input!, $set: client_staff_set_input!) {
      update_client_staff_by_pk(pk_columns: $pk_columns, _set: $set) {
        id
      }
    }
  `;
  const [updateStaffMember, { data: updatedData, error: updateError, loading: saving }] = useMutation(m);

  useEffect(() => {
    let mounted = true;
    if (mounted && updatedData) {
      setChanges({});
      setAlert('Account details updated');
    }
    if (mounted && updateError) {
      setError(updateError.error.message);
    }
    return () => { mounted = false; };
  }, [updatedData, updateError]);

  const [isDeleteStaffMemberDialogOpen, setIsDeleteStaffMemberDialogOpen] = useState(false);

  const handleOpenDelete = () => {
    setIsDeleteStaffMemberDialogOpen(true);
  };

  const handleCloseDelete = () => {
    setIsDeleteStaffMemberDialogOpen(false);
  };

  // const handleSignOut = async () => {
  //   // TODO sign out staff member
  //   // const request = await dataManager.staffMembers.signOutStaffMember(staffMember);
  //   // if (request && request.error) {
  //   //   setError(request.message);
  //   // } else {
  //   //   setAlert('User signed out of all devices');
  //   // }
  // };

  const handleDelete = async () => {
    // TODO delete staff member
    // setDeleting(true);
    // await dataManager.staffMembers.deleteStaffMember(staffMember);
    // handleCloseDelete();
    // history.replace(`/client/locations/${locationUUID}#staff`);
  };

  const handleSave = useCallback(() => {
    updateStaffMember({
      variables: {
        pk_columns: {
          id: staffMember.id,
        },
        set: changes,
      }
    });
  }, [staffMember, changes, updateStaffMember]);

  const handleChangeValue = (property, value) => {
    const updates = { ...changes, [property]: value };
    setChanges(updates);
    const hasChanges = Object.keys(updates).some((key) => staffMember[key] !== updates[key]);
    setIsChanged(hasChanges);
  };

  const handleCloseAlert = () => {
    setAlert(null);
  };

  return (
    <>
      <div className={classes.root}>
        <div autoComplete="off" className={classes.form}>
          <TextField
            data-qa="firstName-textfield"
            required
            label="First name"
            name="firstName"
            error={errors.firstName}
            helperText={helpers.firstName}
            value={changes.first_name}
            defaultValue={data.first_name}
            autoComplete="off"
            onChange={(event) => handleChangeValue('first_name', event.target.value)}
          />
          <TextField
            data-qa="lastName-textField"
            required
            label="Last name"
            name="lastName"
            error={errors.lastName}
            helperText={helpers.lastName}
            value={changes.last_name}
            defaultValue={data.last_name}
            autoComplete="off"
            onChange={(event) => handleChangeValue('last_name', event.target.value)}
          />
        </div>
        {isChanged && (
          <div className={classes.actions}>
            {!saving && (
              <Button data-qa="save-changes-button" color="primary" variant="outlined" onClick={handleSave}>Save Changes</Button>
            )}
            {saving && (
              <CircularProgress />
            )}
          </div>
        )}
        <Divider />
        <div className={classes.actions}>
          {/* <Button data-qa="signOut-button" color="primary" variant="outlined" onClick={handleSignOut}>Sign out user</Button> */}
          <Button data-qa="delete-button" className={classes.deleteAction} variant="outlined" onClick={handleOpenDelete}>Delete User</Button>
        </div>
      </div>
      {error !== null && (
        <Alert data-qa="alert-error" message={error} severity="error" onClose={handleCloseAlert} />
      )}
      {alert !== null && (
        <Alert data-qa="alert" message={alert} onClose={handleCloseAlert} />
      )}
      {isDeleteStaffMemberDialogOpen && <ConfirmDeleteStaffMember handleCloseDelete={handleCloseDelete} handleDelete={handleDelete} deleting={deleting} />}
    </>
  );
};

export default withRouter(withStyles(styles)(StaffMemberDetails));
