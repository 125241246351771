import React, { useState } from 'react';

import {
  withStyles,
} from '@material-ui/core';

import ConsentDialog from '../ConsentDialog';
import PrivacyDialog from '../PrivacyDialog';

import styles from './styles';

const Terms = ({ classes }) => {
  const [isConsentDialogOpen, setIsConsentDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);

  const handleCloseConsent = () => {
    setIsConsentDialogOpen(false);
  };

  const handleClosePrivacy = () => {
    setIsPrivacyDialogOpen(false);
  };

  return (
    <>
      {/* <div>
        <h3>1. Who we are and what this agreement does</h3>
        <ul className={classes.list}>
          <li>
            The NeedleSmart App mobile applicable software (<strong>App</strong>) and any
            updates or supplements to it; <Button data-qa="consent-button" color="primary" className={classes.dialogLink} onClick={handleOpenConsent}>NeedleSmart Application consent statement</Button>
          </li>
          <li>
            (b) the related online documentation (<strong>Documentation</strong>); and
          </li>
          <li>
            (c) the service you connect to via the App and the content we provide to
            you through it (Service), including:
          </li>
          <ul className={classes.nestedList}>
            <li>
              booking an appointment for a medical test at a NeedleSmart testing event,
              including the provision of personal data necessary to undertake identity
              verification checks (<strong>Booking Services</strong>); and
            </li>
            <li>
              using the App in the course of providing services at a NeedleSmart testing
              event, including to verify the ID of an attendee at a NeedleSmart testing event,
              allocating a test kit to an attendee, monitoring the conduct of the test
              and recording the test result (<strong>Event Services</strong>);
            </li>
          </ul>
        </ul>
        <p>as permitted in these terms.</p>
        <p>b
          1.2 The App has dual functionality, as it may be used by individuals for
          either Booking Services or Event Services. These terms apply to all users of
          the App, regardless of which Service they are using the App to access.
        </p>
        <h3>2. Your privacy</h3>
        <p>
          2.1 Under data protection legislation, we are required to provide you with
          certain information about who we are, how we process your personal data and
          for what purposes and your rights in relation to your personal data and how
          to exercise them. This information is provided in <Button data-qa="privacy-button" color="primary" className={classes.dialogLink} onClick={handleOpenPrivacy}>App Privacy Notice</Button> and it
          is important that you read that information.
        </p>
        <p>
          2.2 Please be aware that internet transmissions are never completely privacy
          or secure and that any message or information you send using the App may be
          read or intercepted by others, even if there is a special notice that a
          particular transmission is encrypted.
        </p>
        <h3>3. Operating System Requirements</h3>
        <p>
          The App requires a smartphone device with a minimum of [amount] of memory
          and the [type] operating system as a minimum version of [version]. [Insert
          any other OS requirements]
        </p>
        <h3>4. Support for the App</h3>
        <p>
          4.1 Support: If you want to learn more about the App or the Service or have
          any problems using them please take a look at our support resources at
          [website link to support centre].
        </p>
        <p>
          4.2 Contacting us: If you think the App or the Services are faulty or
          mis-described or wish to contact us for any other reason, please email our
          customer service team at [email] or call them on [telephone number].
        </p>
        <p>
          4.3 How we will communicate with you: If we have to contact you we will do
          so by email using the contact details you have provided to us.
        </p>
        <h3 id="using-the-app">5. Using the App</h3>
        <p>5.1 In return for your agreeing to comply with these terms you may:</p>
        <ul className={classes.list}>
          <li>
            download or stream a copy of the App onto your smartphone device and view,
            use and display the App and the Service on such devices:
          </li>
          <ul className={classes.nestedList}>
            <li>
              where you are using the App to book a test at a NeedleSmart testing event, for
              your personal purposes only; or
            </li>
            <li>
              where you are using the App in the course of your role in connection
              with the operation of a testing event, for the purposes of fulfilling
              that role only.
            </li>
          </ul>
          <li>
            use any Documentation to support your permitted use of the App and the
            Service;
          </li>
          <li>
            provided you comply with the <a href="#license-restrictions">Licence Restrictions</a> below, make
            up to 1 copy of the App and the Documentation for back-up purposes; and
          </li>
          <li>
            receive and use any free supplementary code or update of the App
            incorporating &quot;patches&quot; and correction of errors as we may provide to you.
          </li>
        </ul>
        <h3>6. Age restrictions</h3>
        <p>You must be 18 years old or over to accept these terms and use the App.</p>
        <h3>7. Changes to these terms</h3>
        <p>
          7.1 We may need to change these terms to reflect changes in law or best
          practice or deal with additional features which we may introduce from time
          to time.
        </p>
        <p>
          7.2 We will give you at least 30 days&apos; notice of any change by notifying you
          of a change when you next open or start the App.
        </p>
        <p>
          7.3 If you do not accept the notified changes you may continue to use the
          App and the Service in accordance with the existing terms but certain new
          features may not be available to you.
        </p>
        <h3>8. Updates to the App</h3>
        <p>
          8.1 From time to time we may automatically update the App and change the
          Service to improve performance, enhance functionality, reflect changes to
          the operating system or address security issues. Alternatively, we may ask
          you to update the App for these reasons.
        </p>
        <p>
          8.2 If you choose not to install such updates or you opt out of automatic
          updates, you may not be able to continue using the App or access the
          Services.
        </p>
        <p>
          8.3 The App will always work with the current or previous version of the
          operating system (as it may be updated from time to time) and match the
          description of it provided to you when you downloaded it.
        </p>
        <h3>9. Ownership of device</h3>
        <p>
          If you download or stream the App onto any phone or other device not owned
          by you, you must have the owners permission to do so. You will be
          responsible for complying with these terms, whether or not you own the phone
          or other device.
        </p>
        <h3>10. Technical data</h3>
        <p>
          By using the App or any of the Services, you agree to us collecting and
          using technical information about the device you use the App on and related
          software, hardware and peripherals to improve our products and to provide
          any Services to you.
        </p>
        <h3>11. Location data</h3>
        <p>
          11.1 Certain Services will make use of location data sent from your device.
          You can turn off this functionality at any time by turning off the location
          services settings for the App on the device. If you use these Services, you
          consent to us and our affiliates&apos; and licensees&apos; transmission, collection,
          retention, maintenance, processing and use of your location data and queries
          to provide and improve location-based and road traffic-based products and
          services.
        </p>
        <p>
          11.2 You may stop us collecting such data at any time by turning off the
          location services settings on your device.
        </p>
        <h3>12. Third party links</h3>
        <p>
          12.1 The App or any Service may contain links to other independent websites
          which are not provided by us. Such independent sites are not under our
          control, and we are not responsible for and have not checked and approved
          their content or their privacy notices (if any).
        </p>
        <p>
          12.2 You will need to make your own independent judgement about whether to
          use any such independent sites, including whether to buy any products or
          services offered by them.
        </p>
        <h3 id="license-restrictions">13. License restrictions</h3>
        <p>13.1 You agree that you will:</p>
        <ul className={classes.list}>
          <li>
            not rent, lease, sub-license, loan, provide, or otherwise make available,
            the App or the Services in any form, in whole or in party to any person
            without prior written consent from us;
          </li>
          <li>
            not copy the App, Documentation or Services, except as part of the normal
            use of the App or where it is necessary for the purposes of back-up or
            operational security;
          </li>
          <li>
            not translate, merge, adapt, vary, alter or modify, the whole or any part
            of the App, Documentation or Services not permit the App or the Services
            or any part of them to be combined with, or become incorporated in, any
            other programs, except as necessary to use the App and the Services on
            devices as permitted in these terms;
          </li>
          <li>
            not disassemble, de-compile, reverse engineer or create derivative works
            based on the whole or any part of the App or the Services nor attempt to
            do any such things, except to the extent that (by virtue of section 50B
            and 296A of the Copyright, Designs and Patents Act 1988) such actions
            cannot be prohibited because they are necessary to decompile the App to
            obtain the information necessary to create an independent program that can
            be operated with the App or with another program (Permitted Objective),
            and provided that the information obtained by you during such activities:
          </li>
          <ul className={classes.nestedList}>
            <li>
              is not disclosed or communicated without our prior written consent to
              any third party to whom it is not necessary to disclose or communicate
              it in order to achieve the Permitted Objective;
            </li>
            <li>
              is not used to create any software that is substantially similar in its
              expression to the App;
            </li>
            <li>is kept secure; and</li>
            <li>is used only for the Permitted Objective;</li>
          </ul>
          <li>
            comply with all applicable technology control or export laws and
            regulations that apply to the technology used or supported by the App or
            any Service.
          </li>
        </ul>
        <h3>14. Acceptable use restrictions</h3>
        <p>14.1 You must</p>
        <ul className={classes.list}>
          <li>
            not use the App or any Service in any unlawful manner, for any unlawful
            purpose, or in any manner inconsistent with these terms, or act
            fraudulently or maliciously, for example, by hacking into or inserting
            malicious code, such as viruses, or harmful data, into the App, any
            Services or any operating system;
          </li>
          <li>
            not infringe our intellectual property rights or those of any third party
            in relation to your use of the App or any Service, including by the
            submission of any material (to the extent that such use is not licensed by
            these terms);
          </li>
          <li>
            not transmit any material that is defamatory, offensive, or otherwise
            objectionable in relation to your use of the App or any Service;
          </li>
          <li>
            not use the App or any Service in a way that could damage, disable,
            overburden, impair or compromise our systems or security or interfere with
            other users; and
          </li>
          <li>
            not collect or harvest any information or data from any Service or our
            systems or attempt to decipher any transmissions to or from the servers
            running any Service.
          </li>
        </ul>
        <h3>15. Intellectual property rights</h3>
        <p>
          All intellectual property rights in the App, the Documentation and the
          Services throughout the work belong to us or our licensors and the rights in
          the App and the Services are licensed (not sold) to you. You have no
          intellectual property rights in, or to, the App, the Documentation or the
          Services other than the right to use them in accordance with these terms.
        </p>
        <h3>16. Our responsibility to you</h3>
        <p>
          16.1 We are responsible to you for foreseeable loss and damage caused by us.
          If we fail to comply with these terms, we are responsible for loss or damage
          you suffer that is a foreseeable result of our breaking these terms or our
          failing to use reasonable care and skill, but we are not responsible for any
          loss or damage that is not foreseeable. Loss or damage is foreseeable if
          either it is obvious that it will happen or if, at the time you accepted
          these terms, both we and you knew it might happen.
        </p>
        <p>
          16.2 We do not exclude or limit in any way our liability to you where it
          would be unlawful to do so. This includes liability for death or personal
          injury caused by our negligence or the negligence of our employees, agents
          or subcontractors or for fraud or fraudulent misrepresentation.
        </p>
        <p>
          16.3 When we are liable for damage to your property. If defective digital
          content that we have supplied damages a device or digital content belonging
          to you, we will either repair the damage or pay you compensation. However,
          we will not be liable for damage you could have avoided by following our
          advice to apply an update offered to you free of charge or for damage that
          was caused by you failing to correctly follow installation instructions or
          to have in please the minimum operating system requirements advised by us.
        </p>
        <p>
          16.4 Limitations to the App and the Services. The App and the Services are
          provided for general information purposes only. They do not offer advice on
          which you should rely. You must obtain professional or specialist advice
          before taking, or refraining from, any action on the basis of information
          obtained from the App or the Service. Although we make reasonable efforts to
          update the information provided by the App or the Service, we make no
          representations, warranties or guarantees, whether express or implied, that
          such information is accurate, complete or up-to-date.
        </p>
        <p>
          16.5 Please back-up content and data used within the App. We recommend that
          you back up any content and data used in connection with the App, to protect
          yourself in case of problems with the App or the Service.
        </p>
        <p>
          16.6 Check that the App and the Services are suitable for you. The App and
          the Services have not been designed to meet your individual requirements.
          Please check that the facilities and functions of the App and the Services
          (as described in the Documentation) meet your requirements.
        </p>
        <p>
          16.7 We are not responsible for events outside our control. If our provision
          of the Services or support for the App or the Services is delayed by an
          event outside our control, then we will contact you as soon as possible to
          let you know and we will take steps to minimise the effect of the delay.
          Provided we do this we will not be liable for delays caused by the event.
        </p>
        <h3>17. Termination</h3>
        <p>
          17.1 We may end your rights to use the App and the Services at any time by
          contacting you if you have broken these terms in a serious way. If what you
          have done can be put right, we will give you a reasonable opportunity to do
          so.
        </p>
        <p>17.2 If we end your rights to use the App:</p>
        <ul className={classes.list}>
          <li>
            you must stop all activities authorised by these terms, including your use
            of the App and any Services;
          </li>
          <li>
            you must delete or remove the App from your device and immediately destroy
            all copies of the App which you have and confirm to use that you have done
            this; and
          </li>
          <li>
            we may remotely access your device and remove the App from it and cease
            providing you with access to the Services.
          </li>
        </ul>
        <h3>18. Transfers</h3>
        <p>
          18.1 We are giving you personally the right to use the App and the Service
          as set out in <a href="#using-the-app">Using the App</a> above. You may not transfer the App
          or the Service to someone else, whether for money, for anything else, or for
          free. If you see any device on which the App is installed, you must remove
          the App from it.
        </p>
        <p>
          18.2 We may transfer our rights and obligations under these terms to another
          organisation. We will ensure that any transfer will not affect your rights
          under these terms.
        </p>
        <p>
          18.3 You may only transfer your rights or your obligations under these terms
          to another person if we agree in writing.
        </p>
        <h3>19. General</h3>
        <p>
          19.1 This agreement does not give rise to any rights under the Contracts
          (Rights of Third Parties) Act 1999 to enforce any term of this agreement.
        </p>
        <p>
          19.2 Each of the paragraphs of these terms operates separately. If any court
          or relevant authority decides that any of them are unlawful, the remaining
          paragraphs will remain in full force and effect.
        </p>
        <p>
          19.3 Even if we delay in enforcing any of our rights under these terms, we
          can still enforce it later. If we do not insist immediately that you do
          anything you are required to do under these terms, or if we delay in taking
          steps against you in respect of your breaking this contract, that will not
          mean that you do not have to do those things and it will not prevent us
          taking steps against you at a later date.
        </p>
        <p>
          19.4 These terms are governed by English law and if you live in England or
          Wales, you can bring legal proceedings in respect of the App or the Services
          in the English or Welsh courts. If you live in Scotland, you can bring legal
          proceedings in respect of the App or the Services in either the Scottish or
          the English courts. If you live in Northern Ireland you can bring legal
          proceedings in respect of the App and the Services in either the Northern
          Irish or the English courts.
        </p>
      </div> */}
      <div>
        <p>
          DVS APP END USER TERMS
          PLEASE READ THESE TERMS CAREFULLY
          BY CLICKING ON THE &quot;I AGREE&quot; BUTTON YOU AGREE TO THESE TERMS WHICH WILL BIND YOU.
          IF YOU DO NOT AGREE TO THESE TERMS, DO NOT CLICK THE &quot;I AGREE&quot; BUTTON
        </p>
        <p>
          1 Who we are and what this agreement does
          1.1 We, NeedleSmart Limited and having our registered office at Suite 2B, Stanley , license you to use
          (a) the DVS App mobile applicable software (App) and any updates or supplements to it;
          (b) the related online documentation (Documentation); and
          (c) the service you connect to via the App and the content we provide to you through it (Service), including:
          (i) booking an appointment for a vaccination at a DVS testing event, including the provision of personal data necessary to undertake identity verification checks.
          (ii) using the App in the course of providing services at a DVS vaccination event, including to verify the ID of an attendee at a DVS vaccination event and monitoring the conduct of the vaccination and recording the result (Event Services);
          as permitted in these terms.
          1.2 The App has single function to register a user for a (Vaccination) Event Services. These terms apply to all users of the App, regardless of which Service they are using the App to access.
          2 Your Privacy
          2.1 Under data protection legislation, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes and your rights in relation to your personal data and how to exercise them. This information is provided in App Privacy Notice and it is important that you read that information.
          2.2 Please be aware that internet transmissions are never completely privacy or secure and that any message or information you send using the App may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.
          09757670
          Grange, Business Village, Ormskirk Rd, Knowsley, Prescot L34 4AT
        </p>
        <p>
          3 Operating System Requirements
          The App works on all good modern smartphone devices
          4 Support for the App
          4.1 Support: If you want to learn more about the App or the Service or have any problems using
          them please send your enquiry to enquiries@needlesmart.com.
          4.2 Contacting us: If you think the App or the Services are faulty or mis-described or wish to contact us for any other reason, please email our customer service team at enquiries@needlesmart.com.
          4.3 How we will communicate with you: If we have to contact you we will do so by email using the contact details you have provided to us.
          5 Using the App
          5.1 In return for your agreeing to comply with these terms you may:
          (a) download or stream a copy of the App onto your smartphone device and view, use and display the App and the Service on such devices:
          (i) where you are using the App to book a test at a DVS vaccination event, for your personal purposes only; or
          (ii) where you are using the App in the course of your role in connection with the operation of a testing event, for the purposes of fulfilling that role only.
          (b) use any Documentation to support your permitted use of the App and the Service;
          (c) provided you comply with the Consent Statement, make up to 1 copy of the App and the Documentation for back-up purposes; and
          (d) receive and use any free supplementary code or update of the App incorporating &quot;patches&quot; and correction of errors as we may provide to you.
          6 Age Restrictions
          You must be 18 years old or over to accept these terms and use the App.
          7 Changes to these Terms
          7.1 We may need to change these terms to reflect changes in law or best practice or deal with additional features which we may introduce from time to time.
          7.2 We will give you at least 30 days&apos; notice of any change by notifying you of a change when you next open or start the App.
          7.3 If you do not accept the notified changes you may continue to use the App and the Service in accordance with the existing terms but certain new features may not be available to you.
        </p>
        <p>
          8 Updates to the App
          8.1 From time to time we may automatically update the App and change the Service to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively, we may ask you to update the App for these reasons.
          8.2 If you choose not to install such updates or you opt out of automatic updates, you may not be able to continue using the App or access the Services.
          8.3 The App will always work with the current or previous version of the operating system (as it may be updated from time to time) and match the description of it provided to you when you downloaded it.
          9 Ownership of Device
          If you download or stream the App onto any phone or other device not owned by you, you must have the owner&apos;s permission to do so. You will be responsible for complying with these terms, whether or not you own the phone or other device.
          10 Technical Data
          By using the App or any of the Services, you agree to us collecting and using technical information about the device you use the App on and related software, hardware and peripherals to improve our products and to provide any Services to you.
          11 Location Data
          11.1 Certain Services will make use of location data sent from your device. You can turn off this functionality at any time by turning off the location services settings for the App on the device. If you use these Services, you consent to us and our affiliates&apos; and licensees&apos; transmission, collection, retention, maintenance, processing and use of your location data and queries to provide and improve location-based and road traffic-based products and services.
          11.2 You may stop us collecting such data at any time by turning off the location services settings on your device.
          12 Third Party Links
          12.1 The App or any Service may contain links to other independent websites which are not provided by us. Such independent sites are not under our control, and we are not responsible for and have not checked and approved their content or their privacy notices (if any).
          12.2 You will need to make your own independent judgement about whether to use any such independent sites, including whether to buy any products or services offered by them.
          13 Licence Restrictions
          13.1 You agree that you will:
          (a) not rent, lease, sub-license, loan, provide, or otherwise make available, the App or the Services in any form, in whole or in party to any person without prior written consent from us;
          (b) not copy the App, Documentation or Services, except as part of the normal use of the App or where it is necessary for the purposes of back-up or operational security;
        </p>
        <p>
          14
          14.1
          (c) not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, Documentation or Services not permit the App or the Services or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the App and the Services on devices as permitted in these terms;
          (d) not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App or the Services nor attempt to do any such things, except to the extent that (by virtue of section 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile the App to obtain the information necessary to create an independent program that can be operated with the App or with another program (Permitted Objective), and provided that the information obtained by you during such activities:
          (i) is not disclosed or communicated without our prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective;
          (ii) is not used to create any software that is substantially similar in its expression to the App;
          (iii) is kept secure; and
          (iv) is used only for the Permitted Objective;
          (e) comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App or any Service.
          Acceptable Use Restrictions
          You must
          (a) not use the App or any Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the App, any Services or any operating system;
          (b) not infringe our intellectual property rights or those of any third party in relation to your use of the App or any Service, including by the submission of any material (to the extent that such use is not licensed by these terms);
          (c) not transmit any material that is defamatory, offensive, or otherwise objectionable in relation to your use of the App or any Service;
          (d) not use the App or any Service in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and
          (e) not collect or harvest any information or data from any Service or our systems or attempt to decipher any transmissions to or from the servers running any Service.
          Intellectual Property Rights
          All intellectual property rights in the App, the Documentation and the Services throughout the work belong to us (or our licensors) and the rights in the App and the Services are licensed (not sold) to you. You have no intellectual property rights in, or to, the App, the
        </p>
        <p>
          15
          Documentation or the Services other than the right to use them in accordance with these terms.
          16 Our Responsibility to You
          16.1 We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these terms or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these terms, both we and you knew it might happen.
          16.2 We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors or for fraud or fraudulent misrepresentation.
          16.3 When we are liable for damage to your property. If defective digital content that we have supplied damages a device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we will not be liable for damage you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in please the minimum operating system requirements advised by us.
          16.4 Limitations to the App and the Services. The App and the Services are provided for general information purposes only. They do not offer advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of information obtained from the App or the Service. Although we make reasonable efforts to update the information provided by the App or the Service, we make no representations, warranties or guarantees, whether express or implied, that such information is accurate, complete or up-to-date.
          16.5 Please back-up content and data used within the App. We recommend that you back up any content and data used in connection with the App, to protect yourself in case of problems with the App or the Service.
          16.6 Check that the App and the Services are suitable for you. The App and the Services have not been designed to meet your individual requirements. Please check that the facilities and functions of the App and the Services (as described in the Documentation) meet your requirements.
          16.7 We are not responsible for events outside our control. If our provision of the Services or support for the App or the Services is delayed by an event outside our control, then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event.
          17 Termination
          17.1 We may end your rights to use the App and the Services at any time by contacting you if you have broken these terms in a serious way. If what you have done can be put right, we will give you a reasonable opportunity to do so.
          17.2 If we end your rights to use the App:
        </p>
        <p>
          (a) you must stop all activities authorised by these terms, including your use of the App and any Services;
          (b) you must delete or remove the App from your device and immediately destroy all copies of the App which you have and confirm to use that you have done this; and
          (c) we may remotely access your device and remove the App from it and cease providing you with access to the Services.
          18 Transfers
          18.1 We are giving you personally the right to use the App and the Service as set out above You may not transfer the App or the Service to someone else, whether for money, for anything else, or for free. If you see any device on which the App is installed, you must remove the App from it.
          18.2 We may transfer our rights and obligations under these terms to another organisation. We will ensure that any transfer will not affect your rights under these terms.
          18.3 You may only transfer your rights or your obligations under these terms to another person if we agree in writing.
          19 General
          19.1 This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.
          19.2 Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
          19.3 Even if we delay in enforcing any of our rights under these terms, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.
          19.4 These terms are governed by English law and if you live in England or Wales, you can bring legal proceedings in respect of the App or the Services in the English or Welsh courts. If you live in Scotland, you can bring legal proceedings in respect of the App or the Services in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the App and the Services in either the Northern Irish or the English courts.
        </p>
      </div>
      {isConsentDialogOpen && <ConsentDialog handleCloseConsent={handleCloseConsent} />}
      {isPrivacyDialogOpen && <PrivacyDialog handleClosePrivacy={handleClosePrivacy} />}
    </>
  );
};

export default withStyles(styles)(Terms);
