import React, { useEffect, useState } from 'react';

import { useMutation, gql } from '@apollo/client';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

const CreateClientDialog = ({ classes, handleClose }) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});

  const m = gql`
    mutation addClient($object: clients_insert_input!) {
      insert_clients_one(object: $object) {
        id
        name
      }
    }
  `;

  const [addClient, { data, loading }] = useMutation(m, {
    update(cache, { data: { insert_clients_one } }) {
      cache.modify({
        fields: {
          clients(existing = []) {
            const n = cache.writeFragment({
              data: insert_clients_one,
              fragment: gql`
                fragment NewClient on Client {
                  id
                  name
                }
              `
            });
            return [...existing, n];
          },
          clients_aggregate(existing = { aggregate: { count: 0 } }) {
            return { aggregate: { count: existing.aggregate.count + 1 } };
          }
        }
      });
    }
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      handleClose();
    }
    return () => { mounted = false; };
  }, [data, handleClose]);

  const handleContinueCreateClient = () => {
    let error = false;
    setErrors({});
    if (!name) {
      setErrors((e) => ({ ...e, name: 'Please provide a client name' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = () => {
    addClient({
      variables: {
        object: {
          name,
        },
      },
    });
  };

  const handleIsEnterPressed = (event) => {
    if (event.key === 'Enter') {
      handleContinueCreateClient();
      event.stopPropagation();
    }
  };

  return (
    <Dialog data-qa="create-client-dialog" open onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create New Client</DialogTitle>
      <DialogContent>
        <div autoComplete="off" className={classes.form}>
          <TextField data-qa="create-client-name-input" required label="Client name" name="name" error={errors.name} helperText={errors.name} value={name} autoComplete="off" autoFocus onKeyUp={handleIsEnterPressed} onChange={(event) => setName(event.target.value || null)} />
        </div>
      </DialogContent>
      <DialogActions>
        {loading
          ? <Button data-qa="circular-progress-button" color="primary" disabled><CircularProgress /></Button>
          : (
            <>
              <Button data-qa="cancel-button" onClick={handleClose} color="primary">Cancel</Button>
              <Button data-qa="create-button" onClick={handleContinueCreateClient} color="primary">Create</Button>
            </>
          )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CreateClientDialog);
