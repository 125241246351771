import React, { useState, useContext } from 'react';
import { useParams } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import { AppContext } from '../../../../../components/StateProvider';
import MedicLayout from '../../../../../layouts/Medic';
import ButtonContainer from '../../../components/ButtonContainer';
import LeftArm from '../../../components/Images/LeftArm';
import RightArm from '../../../components/Images/RightArm';

import styles from './styles';

const PatientArm = ({ history, classes }) => {
  const { sessionUUID } = useParams();
  const { nsProDevice } = useContext(AppContext);

  const [arm, setArm] = useState(null);

  const handleAbort = () => {
    nsProDevice.stop();
    history.replace(`/medic/session/${sessionUUID}`);
  };

  const handleContinue = () => {
    history.replace({
      pathname: `/medic/session/${sessionUUID}/needle-destruction`,
      state: {
        ...history.location.state,
        arm,
      },
    });
  };

  return (
    <MedicLayout title="Vaccine administered to arm">
      <div className={classes.imageContainer}>
        <div className={classes.images}>
          <div data-qa="right-arm-button" className={arm === 'right' ? classes.selectedArm : classes.image} onClick={() => setArm('right')} role="button" tabIndex={0}>
            <RightArm />
            <Typography varient="body1">Right arm</Typography>
          </div>
          <div className={arm === 'left' ? classes.selectedArm : classes.image} onClick={() => setArm('left')} role="button" tabIndex={0}>
            <LeftArm />
            <Typography varient="body1">Left arm</Typography>
          </div>
        </div>
      </div>
      <ButtonContainer split>
        <Button data-qa="abort-button" variant="contained" onClick={handleAbort}>Abort</Button>
        {arm && <Button data-qa="continue-button" variant="contained" color="primary" onClick={handleContinue}>Continue</Button>}
      </ButtonContainer>
    </MedicLayout>
  );
};

export default withRouter(withStyles(styles)(PatientArm));
