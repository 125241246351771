import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default styles;
