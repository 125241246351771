const styles = (theme) => ({
  imageContainer: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    height: '80%',
    '& > svg': {
      height: '100%',
    },
  },
});

export default styles;
